/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react-markdown/lib/react-markdown';
import { presets } from '@steadybit/ui-components-lib';

import { UseSelectedLogLevelResult } from './useSelectedLogLevel';

export default function EventLogLevelFilter({
	availableLogLevel,
	selectedLogLevel,
	setSelectedLogLevel,
}: UseSelectedLogLevelResult): ReactElement | null {
	if (availableLogLevel.length === 0) {
		return null;
	}

	return (
		<presets.dropdown.MultiChoiceButton
			items={availableLogLevel.map((level) => ({ id: level, label: level }))}
			placeholder="All"
			size="small"
			selectedIds={selectedLogLevel}
			onUncheck={(id) => setSelectedLogLevel(selectedLogLevel.filter((i) => i !== id))}
			onCheck={(id) => setSelectedLogLevel([...selectedLogLevel, id])}
			style={{ width: '200px' }}
		/>
	);
}
