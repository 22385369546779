/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useAttributeFilterTracking } from 'targets/Explore/hooks/useLandscapeExploreTracking';
import { Container, Pill, Snackbar, Stack, Text, Tooltip } from 'components';
import { LandscapeTargetAttribute } from 'targets/Explore/types';
import { Flex, TextInput } from '@steadybit/ui-components-lib';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useState } from 'react';

interface AttributesProps {
	attributes: LandscapeTargetAttribute[];
	attributeType: 'target_attributes' | 'target_group_shared_attributes';
}

export default function Attributes({ attributes, attributeType }: AttributesProps): ReactElement {
	const [attributeFilter, setAttributeFilter] = useState('');

	useAttributeFilterTracking(attributeFilter, attributeType);

	if (attributes.length === 0) {
		return <Text variant="small">There are no shared attributes</Text>;
	}
	return (
		<Flex spacing="small">
			{attributes.length > 1 && (
				<TextInput
					placeholder="Search..."
					value={attributeFilter}
					onChange={setAttributeFilter}
					withLeftIcon="search"
				/>
			)}
			<Flex direction="horizontal" wrap style={{ maxWidth: '100%' }}>
				{attributes
					.filter((attribute) => {
						if (!attributeFilter) {
							return true;
						}
						const searchTerm = attributeFilter.toLowerCase();
						return (
							attribute.key.toLowerCase().includes(searchTerm) || attribute.value.toLowerCase().includes(searchTerm)
						);
					})
					.map((attribute, i) => (
						<Attribute
							key={i}
							attribute={attribute}
							mb={attributes[i + 1] && attributes[i + 1].key === attribute.key ? 6 : 6}
						/>
					))}
			</Flex>
		</Flex>
	);
}

interface AttributeProps {
	attribute: LandscapeTargetAttribute;
	mb?: number;
}

function Attribute({ attribute, mb }: AttributeProps): ReactElement {
	return (
		<Stack size="xxSmall" direction="horizontal" alignItems="center" sx={{ mr: 8, mb: mb }}>
			<Tooltip content={attribute.key}>
				<Text
					variant="xSmall"
					sx={{
						...textEllipsis,
						cursor: 'pointer',
					}}
					color="neutral700"
					onClick={() => {
						navigator.clipboard.writeText(attribute.key).then(() => {
							Snackbar.dark('Key copied to your clipboard!', { toastId: 'attribute-key-copied' });
						});
					}}
				>
					{attribute.key}:
				</Text>
			</Tooltip>
			<Tooltip content={attribute.value}>
				<Container>
					<Pill
						color="neutral800"
						backgroundColor="neutral200"
						backgroundColorOnHover="neutral300"
						sx={{
							borderRadius: 4,
							px: 4,
							py: 1,
							cursor: 'pointer',
						}}
						onClick={() => {
							navigator.clipboard.writeText(attribute.value).then(() => {
								Snackbar.dark('Value copied to your clipboard!', { toastId: 'attribute-value-copied' });
							});
						}}
					>
						<Text variant="xSmall" sx={{ ...textEllipsis }} fontWeight={500}>
							{attribute.value}
						</Text>
					</Pill>
				</Container>
			</Tooltip>
		</Stack>
	);
}
