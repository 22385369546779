/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, Dropdown, Flex, presets } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

import TextFieldWithVariableHighlighting from './TextFieldWithVariableHighlighting';
import VariablesAndPlaceholders from './VariablesAndPlaceholders';
import FocussableWrapper from './FocussableWrapper';
import Chunks from './Chunks';

interface OptionType {
	label: string;
	value: string;
}

interface StringOptionsProps {
	optionsOnly?: boolean;
	options: OptionType[];
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function StringOptions({
	optionsOnly = false,
	hasErrors,
	disabled,
	options,
	value,
	setValue,
}: StringOptionsProps): ReactElement {
	const resolvedOption = options.find((option) => option.value === value);

	if (optionsOnly) {
		return (
			<Dropdown<HTMLButtonElement>
				renderDropdownContent={({ width, close }) => (
					<DropDownContent
						resolvedOption={resolvedOption}
						options={options}
						width={width}
						selectItem={(value) => {
							setValue(value);
							close();
						}}
					/>
				)}
			>
				{({ setRefElement, isOpen, setOpen }) => {
					return (
						<Button
							ref={setRefElement}
							withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
							disabled={disabled}
							type="secondary"
							style={{
								width: '100%',
								outline: isOpen ? '2px solid ' + Colors.slate : undefined,
								color: value ? Colors.slate : undefined,
								onHover: {
									color: Colors.neutral000,
								},
							}}
							onClick={() => setOpen(!isOpen)}
							data-cy="text-field-with-highlighting"
						>
							{resolvedOption ? (
								resolvedOption.label
							) : (
								<Flex
									direction="horizontal"
									style={{
										flexGrow: 1,
										pointerEvents: 'none',
										overflow: 'hidden',
										maxWidth: '100%',
									}}
								>
									<Chunks value={value} />
								</Flex>
							)}
						</Button>
					);
				}}
			</Dropdown>
		);
	}

	return (
		<Dropdown<HTMLDivElement>
			renderDropdownContent={({ width, close }) => (
				<DropDownContent
					resolvedOption={resolvedOption}
					options={options}
					width={width}
					selectItem={(value) => {
						setValue(value);
						close();
					}}
				/>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<FocussableWrapper ref={setRefElement} onFocus={() => setOpen(true)} onBlur={() => {}} disabled={disabled}>
						<TextFieldWithVariableHighlighting
							value={String(value)}
							hasErrors={hasErrors}
							isEditMode={isOpen}
							disabled={disabled}
							onClick={() => setOpen(true)}
							onChange={setValue}
						/>
					</FocussableWrapper>
				);
			}}
		</Dropdown>
	);
}

interface DropDownContentProps {
	resolvedOption?: OptionType | undefined;
	options: OptionType[];
	width: string;
	selectItem: (value: string) => void;
}

function DropDownContent({ width, resolvedOption, options, selectItem }: DropDownContentProps): ReactElement {
	return (
		<presets.dropdown.DropdownContentFrame width={width}>
			<presets.dropdown.SingleChoiceList
				items={options.map(({ label, value }) => ({
					id: value,
					label,
					isSelected: value === resolvedOption?.value,
				}))}
				withKeyboardNavigation
				onSelect={selectItem}
			/>
			<VariablesAndPlaceholders width={width} selectItem={selectItem} />
		</presets.dropdown.DropdownContentFrame>
	);
}
