/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	CartesianGrid,
	Cell,
	Line,
	LineChart,
	Pie,
	PieChart,
	ReferenceDot,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip as TooltipRecharts,
	XAxis,
	YAxis,
} from 'recharts';
import ExperimentRunCard from 'pages/experiments/components/ExperimentRunCard';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { presets } from '@steadybit/ui-components-lib';
import { countBy, range } from 'lodash';

import {
	MetricVO,
	useExecutionMetricsDistinctValues,
	useFilteredExecutionMetrics,
} from '../../../../../services/executionMetricsApi';
import {
	ExperimentExecutionVO,
	GroupMatcherKeyEqualsValue,
	GroupMatcherNotEmpty,
	LineChartWidgetVO,
} from '../../../../../ui-api';
import { Checkbox, Container, Label, Stack, Text, Tooltip } from '../../../../../components';
import { ExperimentPlayerTimeStamp } from '../../../../../components/ExperimentPlayer/types';
import textEllipsis from '../../../../../utils/styleSnippets/textEllipsis';
import { formatTime } from '../../../../../utils/dateFns';
import LoadingContent from '../../metrics/LoadingContent';
import { theme } from '../../../../../styles.v2/theme';
import EmptyContent from '../../metrics/EmptyContent';
import { WidgetProps } from '../types';

export default function LineChartWidget({
	experimentExecution,
	widget,
	position,
	onPositionSelect,
	duration,
	start,
}: WidgetProps): ReactElement {
	const lineChartWidget = widget as LineChartWidgetVO;
	const liveUpdate = !experimentExecution.ended;

	const [selectedValue, setSelectedValue] = useState<string | null>(null);

	const identityValues = useExecutionMetricsDistinctValues(
		experimentExecution.id,
		lineChartWidget.identity.metricName,
		lineChartWidget.identity.from,
		liveUpdate,
	);

	useEffect(() => {
		if (selectedValue == null && identityValues.value && identityValues.value.length > 0) {
			setSelectedValue(identityValues.value[0]);
		}
	}, [selectedValue, setSelectedValue, identityValues.value]);

	if (lineChartWidget.identity.mode === 'com.steadybit.widget.line_chart.identity_mode.select') {
		return (
			<>
				<ExperimentRunCard
					title={lineChartWidget.title}
					additionalHeaderItems={
						<Switcher values={identityValues.value} selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
					}
				>
					{!identityValues.value?.length || !selectedValue ? (
						<Stack size={0} sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
							{liveUpdate || identityValues.loading ? (
								<LoadingContent loading={identityValues.loading}>Waiting for metrics...</LoadingContent>
							) : (
								<EmptyContent>No metrics recorded.</EmptyContent>
							)}
						</Stack>
					) : (
						<WidgetContent
							experimentExecution={experimentExecution}
							value={selectedValue}
							widget={lineChartWidget}
							position={position}
							onPositionSelect={onPositionSelect}
							start={start}
							duration={duration}
						/>
					)}
				</ExperimentRunCard>
			</>
		);
	} else if (lineChartWidget.identity.mode === 'com.steadybit.widget.line_chart.identity_mode.widget-per-value') {
		return (
			<>
				{identityValues.value?.map((value) => (
					<ExperimentRunCard key={value} title={lineChartWidget.title + ' for ' + value}>
						<WidgetContent
							experimentExecution={experimentExecution}
							value={value}
							widget={lineChartWidget}
							position={position}
							onPositionSelect={onPositionSelect}
							start={start}
							duration={duration}
						/>
					</ExperimentRunCard>
				))}
			</>
		);
	} else {
		return <ExperimentRunCard title={lineChartWidget.title}>LineChartWidget: Unknown identity.mode</ExperimentRunCard>;
	}
}

interface SwitcherProps {
	values: string[] | undefined;
	selectedValue: string | null;
	setSelectedValue: (target: string | null) => void;
}

function Switcher({ selectedValue, values, setSelectedValue }: SwitcherProps): ReactElement {
	if (!values || values.length <= 1) {
		return <></>;
	}

	return (
		<presets.dropdown.SingleChoiceButton
			selectedId={selectedValue || undefined}
			items={values.map((id) => ({ id, label: id }))}
			size="small"
			placement="bottom-end"
			maxContentHeight="300px"
			onSelect={setSelectedValue}
			style={{
				maxWidth: '220px',
			}}
		>
			{selectedValue || 'Select'}
		</presets.dropdown.SingleChoiceButton>
	);
}

const COLORS: Record<string, string> = {
	['danger']: theme.colors.feedbackErrorDark,
	['warn']: theme.colors.experimentWarning,
	['info']: 'neutral400',
	['success']: theme.colors.cyanDark,
};

function getColor(colorName?: string): string {
	if (colorName) {
		return COLORS[colorName] || theme.colors.neutral400;
	}
	return theme.colors.neutral400;
}

function WidgetContent({
	experimentExecution,
	value,
	widget,
	position,
	onPositionSelect,
	start,
	duration,
}: {
	experimentExecution: ExperimentExecutionVO;
	value: string;
	widget: LineChartWidgetVO;
	position: ExperimentPlayerTimeStamp | null;
	onPositionSelect: (position: ExperimentPlayerTimeStamp | null) => void;
	start: number;
	duration: number;
}): ReactElement {
	const liveUpdate = !experimentExecution.ended;
	const ticks = useMemo(() => {
		const tickCount = Math.min(8, duration / 1000);
		return range(start, start + duration + 1, duration / tickCount);
	}, [start, duration]);

	const metrics = useFilteredExecutionMetrics(
		experimentExecution.id,
		widget.identity.metricName,
		widget.identity.from,
		value,
		liveUpdate,
	);

	const data = useMemo(() => {
		const bars: Stat[] = metrics.value?.map((m) => metricToStat(m, widget)) || [];
		const results = Object.entries(countBy(bars, (metric) => metric.group)).map(([group, value]) => ({
			group: group,
			count: value,
			color: getColor(widget.grouping?.groups?.find((g) => g.title === group)?.color),
		}));
		return { bars, results };
	}, [metrics.value]);
	const [hidden, setHidden] = useState<Record<string, boolean>>({});
	const filteredData = useMemo(() => data.bars.filter((entry) => !hidden[entry.group]), [data.bars, hidden]);

	const showSummary = widget.grouping?.showSummary && widget.grouping?.groups?.length > 0;

	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: showSummary ? '1fr 160px' : '1fr',
				height: '300px',
			}}
		>
			<Container sx={{ zIndex: 1 }}>
				<ResponsiveContainer key={'line-chart-container' + value}>
					<LineChart
						key={'line-chart' + value}
						data={filteredData}
						margin={{ left: 0, top: 0, right: 0, bottom: 0 }}
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						onClick={(event: any) => onPositionSelect(event?.activePayload?.[0]?.payload?.ts)}
					>
						<CartesianGrid {...theme.charts.grid} />
						<TooltipRecharts content={renderHttpStatusTooltip} />
						<XAxis
							{...theme.charts.axes}
							key={'line-chart-x-axis' + value + '_' + start + duration}
							dataKey="ts"
							type="number"
							domain={[start, start + duration]}
							ticks={ticks}
							scale={'time'}
							tickFormatter={(t) => formatTime(new Date(t))}
						/>
						<YAxis {...theme.charts.axes} type={'number'} unit={widget.tooltip?.metricValueUnit || ''} />
						{filteredData.map((entry, i) => (
							<ReferenceDot
								key={'line-chart-' + value + '_' + i}
								x={entry.ts}
								y={entry.metricValue}
								fill={entry.groupColor}
								r={5}
							/>
						))}
						<Line
							type="monotone"
							dataKey="metricValue"
							stroke={theme.colors.neutral300}
							dot={false}
							isAnimationActive={false}
						/>
						{position && <ReferenceLine x={position} stroke={theme.colors.slate} strokeWidth={2} />}
					</LineChart>
				</ResponsiveContainer>
			</Container>
			{showSummary && (
				<Stack size="xSmall" alignItems="center" sx={{ zIndex: 0 }}>
					<ResponsiveContainer height="50%">
						<PieChart>
							<Pie data={data.results} dataKey={'count'} fill="#82ca9d">
								{data.results.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={entry.color} />
								))}
							</Pie>
							<TooltipRecharts content={renderPieChartTooltip} />
						</PieChart>
					</ResponsiveContainer>
					<Stack size="xxSmall" overflowY="auto" height={100} width={150}>
						{data.results.map((result) => (
							<Container key={result.group} sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
								<Checkbox
									id={result.group}
									checked={!hidden[result.group]}
									color={result.color}
									onChange={(e) => setHidden((c) => ({ ...c, [result.group]: !e.target.checked }))}
								/>
								<Label htmlFor={result.group}>
									<Tooltip content={result.group} onlyShowOnEllipsis>
										<Container
											sx={{
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												overflow: 'hidden',
											}}
										>
											<Text as="span" variant="small" color="neutral400">
												{result.count}x
											</Text>
											<Text as="span" variant="small" color="neutral600">
												{result.group}
											</Text>
										</Container>
									</Tooltip>
								</Label>
							</Container>
						))}
					</Stack>
				</Stack>
			)}
		</Container>
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderHttpStatusTooltip = (props: { active?: boolean; payload?: Array<any> }): ReactElement | null => {
	if (props.active && props.payload && props.payload.length) {
		const stat = props.payload[0].payload as Stat;
		return (
			<Container
				sx={{
					py: 'xSmall',
					px: 'small',
					backgroundColor: 'neutral000',
					boxShadow: 'applicationMedium',
					border: '1px solid ' + theme.colors.neutral300,
					borderRadius: 4,
				}}
			>
				<Text color={stat.groupColor ?? theme.colors.neutral400} variant={'smallMedium'} mb={'xSmall'}>
					{stat.group}
				</Text>

				<Stack size="xxSmall">
					<KeyValue k="Timestamp" v={formatTime(new Date(stat.ts))} />
					{stat.tooltipValues?.map((content) => (
						<KeyValue key={content.k} k={content.k} v={content.v + (content.unit ? ' ' + content.unit : '')} />
					))}
				</Stack>
			</Container>
		);
	}

	return null;
};

interface KeyValueProps {
	k: string;
	v: string;
}

function KeyValue({ k, v }: KeyValueProps): ReactElement {
	return (
		<Stack size="none">
			<Text variant="small" color="neutral600">
				{k}:
			</Text>
			<Text variant="smallStrong" color="neutral700" sx={{ ...textEllipsis }}>
				{v}
			</Text>
		</Stack>
	);
}

type TooltipValue = {
	k: string;
	v: string;
	unit?: string;
};

type Stat = {
	ts: number;
	group: string;
	groupColor: string;
	metricValue: number;
	tooltipValues?: TooltipValue[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderPieChartTooltip = (props: { active?: boolean; payload?: Array<any> }): ReactElement | null => {
	if (props.active && props.payload && props.payload.length) {
		return (
			<Container
				backgroundColor={'neutral000'}
				p={'xSmall'}
				sx={{
					boxShadow: 'applicationMedium',
					border: '1px solid',
					borderColor: 'neutral300',
					borderRadius: 4,
				}}
			>
				<Text color={props.payload[0].payload.color ?? theme.colors.neutral400} variant={'smallMedium'} mb={'Small'}>
					{props.payload[0].payload.group}
				</Text>
				<Text color={'neutral600'} variant={'smallMedium'} as={'span'}>
					{props.payload[0].payload.count}
				</Text>
			</Container>
		);
	}

	return null;
};

function metricToStat(metric: MetricVO, widget: LineChartWidgetVO): Stat {
	let group = '';
	let groupColor = undefined;

	const fallbackGroup = widget.grouping?.groups?.find(
		(g) => g.matcher?.type === 'com.steadybit.widget.line_chart.group_matcher_fallback',
	);
	const otherGroups = widget.grouping?.groups?.filter(
		(g) => g.matcher.type !== 'com.steadybit.widget.line_chart.group_matcher_fallback',
	);

	for (const g of otherGroups || []) {
		if (g.matcher.type === 'com.steadybit.widget.line_chart.group_matcher_not_empty') {
			if (metric.labels[(g.matcher as GroupMatcherNotEmpty).key]) {
				group = g.title;
				groupColor = g.color;
				break;
			}
		} else if (g.matcher.type === 'com.steadybit.widget.line_chart.group_matcher_key_equals_value') {
			const keyEqualsValueMatcher = g.matcher as GroupMatcherKeyEqualsValue;
			if (metric.labels[keyEqualsValueMatcher.key] === keyEqualsValueMatcher.value) {
				group = g.title;
				groupColor = g.color;
				break;
			}
		}
	}
	if (fallbackGroup && group === '') {
		group = fallbackGroup.title;
		groupColor = fallbackGroup.color;
	}
	const tooltipValues: TooltipValue[] =
		(widget.tooltip?.additionalContent
			?.map((tooltip) => {
				const value = metric.labels[tooltip.from];
				if (value !== undefined) {
					return {
						k: tooltip.title,
						v: value,
						unit: tooltip.unit,
					};
				}
				return undefined;
			})
			.filter((v) => v !== undefined) as TooltipValue[]) || [];

	tooltipValues.unshift({
		k: widget.tooltip?.metricValueTitle || 'Value',
		v: metric.value.toString(),
		unit: widget.tooltip?.metricValueUnit,
	});

	return {
		ts: metric.ts,
		group,
		groupColor: getColor(groupColor),
		metricValue: metric.value,
		tooltipValues,
	};
}
