/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Container, FormikTextField, ModalContentV2, ModalHeaderV2, ModalV2, Stack } from 'components';
import { ExperimentFormActions, ExperimentFormValues } from 'pages/experimentsV2/types';
import { FieldVO, MetricQueryVO, TemplatePlaceholderVO, VariableVO } from 'ui-api';
import Fields from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Fields';
import { TemplateFormValues } from 'pages/templates/TemplateEditor/types';
import Collapsible from 'components/Collapsible.v2/Collapsible.v2';
import { Form, Formik, useFormikContext } from 'formik';
import { debounce, partition, sortBy } from 'lodash';
import { IconSaveDisc } from 'components/icons';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';

export interface MetricQueryEditorFormValues {
	placeholders: TemplatePlaceholderVO[];
	experimentVariables: VariableVO[];
	actions: ExperimentFormActions[];
	variables: VariableVO[];
	query: MetricQueryVO;
}

interface MetricQueryEditorProps {
	metricQueryParameterDefinitions: FieldVO[];
	query: MetricQueryVO;
	save: (values: MetricQueryEditorFormValues) => void;
	close: () => void;
}

export default function MetricQueryEditor({
	metricQueryParameterDefinitions,
	query,
	close,
	save,
}: MetricQueryEditorProps): ReactElement {
	const experimentFormik = useFormikContext<ExperimentFormValues>();
	const templateFormik = useFormikContext<TemplateFormValues>();

	const debouncedValidate = debounce(Services.experiments.validateMetricQuery, 500, { leading: true });
	const [initialValues] = useState<MetricQueryEditorFormValues>({
		query,
		actions: experimentFormik.values.actions || [],
		variables: experimentFormik.values.variables || [],
		placeholders: templateFormik.values.placeholders || [],
		experimentVariables: experimentFormik.values.experimentVariables || [],
	});

	const [normalFields, advancedFields] = orderAndPartitionFields(metricQueryParameterDefinitions);

	return (
		<Formik<MetricQueryEditorFormValues>
			initialValues={initialValues}
			onSubmit={(values: MetricQueryEditorFormValues) => save(values)}
			validate={async (values: MetricQueryEditorFormValues) => {
				const violations = await debouncedValidate(values.query);
				if (violations.length === 0) {
					return {};
				}
				const errors: Record<string, string> = {};
				for (let i = 0; i < violations.length; i++) {
					const { field, message } = violations[i];
					errors[field] = message;
				}
				return { query: errors };
			}}
			validateOnChange
			validateOnBlur={false}
		>
			{({ isSubmitting, isValid, submitForm }) => (
				<Form>
					<ModalV2 width={860}>
						<ModalHeaderV2 title="Define Metric Query" onClose={close} />
						<ModalContentV2>
							<Stack
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										submitForm();
									}
								}}
							>
								<FormikTextField
									size="small"
									name="query.label"
									label={'Label'}
									placeholder="Descriptive label to identify this query"
								/>
								<Stack size="small" width="100%" pt="6px">
									<Fields fields={normalFields} stepPath="query" />
									{advancedFields.length > 0 && (
										<Collapsible title="Additional Settings" contentPadding="small">
											<Stack size="small" width="100%" pt="6px" px="small">
												<Fields fields={advancedFields} stepPath="query" />
											</Stack>
										</Collapsible>
									)}
								</Stack>
								<Container display="flex" justifyContent="flex-end" pt="small">
									<Button variant="secondary" onClick={close} mr="small">
										Cancel
									</Button>
									<Button id="save" onClick={submitForm} loading={isSubmitting} disabled={!isValid}>
										<IconSaveDisc variant="small" mr="xSmall" />
										Save Metric
									</Button>
								</Container>
							</Stack>
						</ModalContentV2>
					</ModalV2>
				</Form>
			)}
		</Formik>
	);
}

function orderAndPartitionFields(fields: FieldVO[]): [FieldVO[], FieldVO[]] {
	const ordered = sortBy(
		fields.filter((f) => !f.hidden),
		['order'],
	);
	return partition(ordered, (f) => !f.advanced);
}
