/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, useEffect, useState } from 'react';
import { IconRoundSpinnerWhite } from 'components/icons';
import { Container, Image, Stack } from 'components';
import { Services } from 'services/services';
import { motion } from 'framer-motion';
import { seconds } from 'utils/time';

import imageSrc from './images/Spaceship.png';

export default function Ops(): ReactElement {
	const [retryCounter, setRetryCounter] = useState(0);

	useEffect(() => {
		const timeout = setTimeout(async () => {
			try {
				const isAvailable = await Services.healthApi.isAvailable();
				if (isAvailable) {
					window.location.reload();
				} else {
					setRetryCounter(retryCounter + 1);
				}
			} catch {
				setRetryCounter(retryCounter + 1);
			}
		}, seconds(10).getMillis());
		return () => clearTimeout(timeout);
	}, [retryCounter]);

	return (
		<Container
			sx={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Image
				sx={{
					width: '500px',
					marginTop: '50px',
					marginLeft: '-100px',
				}}
				src={imageSrc}
			/>
			<Container
				sx={{
					position: 'absolute',
					top: '380px',
					left: 'calc(50% - 50px)',

					transform: 'rotateZ(-15deg)',
					fontSize: '70px',
					color: '#7b6ade',
				}}
			>
				<Container sx={{ position: 'absolute', top: '-166px', left: '-87px', fontSize: '73px' }}>
					<Letter transform="rotateZ(19deg)" offset={5} duration={3}>
						O
					</Letter>
				</Container>
				<Container sx={{ position: 'absolute', top: '-106px', left: '-17px', fontSize: '73px' }}>
					<Letter transform="rotateZ(-29deg)" offset={5} duration={3}>
						O
					</Letter>
				</Container>
				<Container
					sx={{
						position: 'absolute',
						top: '-135px',
						left: '47px',
						transform: 'rotateZ(8deg)',
						fontSize: '56px',
					}}
				>
					<Letter transform="rotateZ(8deg)" offset={3} duration={2}>
						P
					</Letter>
				</Container>
				<Container
					sx={{ position: 'absolute', top: '-32px', left: '80px', transform: 'rotateZ(32deg)', fontSize: '89px' }}
				>
					<Letter transform="rotateZ(32deg)" offset={7} duration={4}>
						S
					</Letter>
				</Container>
			</Container>
			<Stack sx={{ fontSize: '16px', color: '#8f9dad', my: '40px', alignItems: 'center' }}>
				Our service is currently unavailable. Please wait until systems are operating again.
				<IconRoundSpinnerWhite variant="large" sx={{ mt: 'small', filter: 'invert(0.3)' }} />
			</Stack>
		</Container>
	);
}

interface LetterProps {
	children: string;
	transform: string;
	offset: number;
	duration: number;
}

function Letter({ children, transform, offset, duration }: LetterProps): ReactElement {
	return (
		<motion.div
			animate={{ marginTop: [`-${offset / 2}px`, `${offset / 2}px`, `-${offset / 2}px`] }}
			transition={{ duration, ease: 'easeInOut', repeat: Infinity }}
		>
			<Container sx={{ transform }}>{children}</Container>
		</motion.div>
	);
}
