/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	Pagination,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	Text,
} from 'components';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { GeneralAdvice } from 'components/advice/AdviceBadges';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { useDimensions } from 'utils/hooks/useDimensions';
import { TextInput } from '@steadybit/ui-components-lib';
import { LandscapeConfig } from 'targets/Explore/types';
import { hasAdvice } from 'targets/Explore/utils';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { debounce } from 'lodash';

import { LayoutedGroup } from '../types';

interface GroupTableProps {
	onGroupSelected: (group: LayoutedGroup) => void;
	groups: LayoutedGroup[];
	expanded: boolean;
}

export default function GroupTable({ groups, expanded, onGroupSelected }: GroupTableProps): ReactElement {
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [searchFilterQuery, setFilterSearchQueryInternal] = useState<string>('');
	const setFilterSearchQuery = debounce(setFilterSearchQueryInternal, 200);

	groups = useMemo(() => {
		if (searchFilterQuery) {
			return groups.filter((g) => g.group.label.toLowerCase().includes(searchFilterQuery.toLowerCase()));
		}
		return groups;
	}, [searchFilterQuery, groups]);

	const [divRef, [wrapperWidth]] = useDimensions<HTMLDivElement>();
	const { values } = useFormikContext<LandscapeConfig>();

	// filter
	const filteredGroups = groups.slice().sort((a, b) => a.group.label.localeCompare(b.group.label));

	const pageSize = 30;
	const [page, setPage] = useState(0);
	useEffect(() => {
		setPage(0);
	}, [groups]);

	// paginate
	const pagedGroups = filteredGroups.slice(page * pageSize, (page + 1) * pageSize);
	const totalPages = Math.ceil(filteredGroups.length / pageSize);

	const showAdvice = values.showAdvice;

	return (
		<Stack>
			<TextInput
				placeholder="Search Groups"
				withLeftIcon="search"
				value={searchQuery}
				onChange={(v) => {
					setSearchQuery(v);
					setFilterSearchQuery(v);
				}}
			/>

			<Container width="100%" maxWidth="100%" ref={divRef}>
				<Table>
					<TableHead>
						<TableRow>
							<TableHeadCell
								width={expanded ? wrapperWidth - 180 : wrapperWidth - (showAdvice ? 70 : 0)}
								sx={{ minHeight: '32px !important' }}
							>
								Group Name
							</TableHeadCell>
							{expanded && (
								<TableHeadCell width={180} sx={{ minHeight: '32px !important' }}>
									{groups[0]?.layoutedTargets ? 'Number of Targets' : 'Number of Groups'}
								</TableHeadCell>
							)}
							{showAdvice && (
								<TableHeadCell width={70} sx={{ justifyContent: 'center', minHeight: '32px !important' }}>
									Advice
								</TableHeadCell>
							)}
						</TableRow>
					</TableHead>

					<TableBody>
						{pagedGroups.map((g, i) => (
							<TableRow
								key={g.group.id}
								hoverable
								onClick={() => onGroupSelected(g)}
								sx={{
									bg: i % 2 === 0 ? 'neutral000' : 'neutral100',
									cursor: 'pointer',
									'&:hover': {
										bg: 'neutral200',

										'& > td > div > div.text': {
											color: 'slate',
											fontWeight: 'strong',
										},
									},
								}}
							>
								<TableDataCell
									maxWidth={expanded ? wrapperWidth - 180 : wrapperWidth - (showAdvice ? 70 : 0)}
									sx={{ minHeight: '28px !important' }}
									style={{ borderBottom: 0 }}
								>
									<Text variant="small" className="text" sx={{ ...textEllipsis }}>
										{g.group.label}
									</Text>
								</TableDataCell>

								{expanded && (
									<TableDataCell sx={{ minHeight: '28px !important' }} style={{ borderBottom: 0 }}>
										<Text variant="small" fontFamily="monospace" className="text" sx={{ ...textEllipsis }}>
											{g.group.targets ? g.group.targets.length : g.group.groups.length}
										</Text>
									</TableDataCell>
								)}

								{showAdvice && (
									<TableDataCell
										sx={{ justifyContent: 'center', minHeight: '28px !important' }}
										style={{ borderBottom: 0 }}
									>
										{hasAdvice(g.group) && <GeneralAdvice />}
									</TableDataCell>
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>

				{totalPages > 1 && (
					<Container
						sx={{
							borderTop: '1px solid ' + theme.colors.neutral200,
							px: 'medium',
							py: 'small',
						}}
					>
						<Pagination size="small" activePage={page} totalPages={totalPages} onClick={setPage} />
					</Container>
				)}
			</Container>
		</Stack>
	);
}
