/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { presets } from '@steadybit/ui-components-lib';
import { EnvironmentSummaryVO } from 'ui-api';
import { ReactElement } from 'react';

interface EnvironmentsProps {
	environments: EnvironmentSummaryVO[];
	selectedEnvironmentIds: string[];
	selectIds: (envIds: string[]) => void;
}

export default function Environments({
	selectedEnvironmentIds,
	environments,
	selectIds,
}: EnvironmentsProps): ReactElement {
	return (
		<presets.dropdown.MultiChoiceButton
			items={environments.map((env) => ({
				id: env.id,
				icon: env.global ? 'environment-global' : 'environment',
				label: env.name,
			}))}
			maxContentHeight="400px"
			maxCotentWidth="0"
			selectedIds={selectedEnvironmentIds}
			onUncheck={(id) => selectIds(selectedEnvironmentIds.filter((i) => i !== id))}
			onCheck={(id) => selectIds([...selectedEnvironmentIds, id])}
			placeholder="Any environment"
		/>
	);
}
