/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	isPredicateEditableWithTheQueryBuilder,
	isQueryLanguagePredicateVO,
	isTargetAttributeKeyValuePredicateVO,
} from 'components/PredicateEditor/utils';
import VariablesAndPlaceholders from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Controls/VariablesAndPlaceholders';
import { Colors, Dropdown, Grid, presets, SingleChoiceListItem, TextInput } from '@steadybit/ui-components-lib';
import { KeyError, ValuesError } from 'components/PredicateEditor/PredicateEditor';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { toTargetPredicate } from 'queryLanguage/parser/parser';
import { ExperimentError } from 'pages/experimentsV2/types';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { getCategoryLabel } from 'services/targetsApi';
import { QueryLanguagePredicateVO } from 'ui-api';
import { quoteForVariables } from 'utils/envVars';
import { includes } from 'utils/string';
import { groupBy } from 'lodash';

import { ButtonIcon, LoadingIndicator, smellsLikeTemplatePlaceholder, Stack } from '..';
import { OptionTypeBase, SelectOptionGroup } from '../Select/SelectOptionTypes';
import DropdownInputFilterable from './DropdownInputFilterable';
import * as Types from '../Select/SelectOptionTypes';
import { AndDivider, OrDivider } from './Divider';
import { find } from '../Select/utils';
import { IconDelete } from '../icons';
import { Divider } from '../Divider';

interface CompositePredicate {
	operator?: string;
	predicates?: AttributePredicate[];
}

interface AttributePredicate {
	key: string;
	operator: string;
	values?: string[];
}

type ValueValidator = (
	value: string,
	operator: string,
	options: OptionTypeBase[] | undefined,
) => [boolean, ExperimentError | undefined];

interface PredicateBuilderProps {
	partErrors?: Array<KeyError | ValuesError>;
	attributeKeysLoading: boolean;
	value?: CompositePredicate;
	attributeKeys?: string[];
	operators?: string[];
	autoFocus?: boolean;
	disabled?: boolean;
	onAttributeKeyChange?: (key: string, change: 'added' | 'removed' | 'replaced') => void;
	onChange?: (value: CompositePredicate | undefined) => void;
	fetchAttributeValues?: (key: string) => Promise<string[]>;
	validateValue?: ValueValidator;
}

const emptyPredicate: CompositePredicate = { predicates: [], operator: 'AND' };
const operators = ['EQUALS', 'CONTAINS', 'EQUALS_IGNORE_CASE', 'CONTAINS_IGNORE_CASE'];

export default function PredicateBuilder({
	value = emptyPredicate,
	attributeKeysLoading,
	partErrors = [],
	attributeKeys,
	autoFocus,
	disabled,
	onAttributeKeyChange,
	fetchAttributeValues,
	validateValue,
	onChange,
}: PredicateBuilderProps): ReactElement {
	value = correctPredicate(value);

	const [newPredicate, setNewPredicate] = useState<AttributePredicate>({
		key: '',
		operator: 'EQUALS',
		values: [],
	});
	const predicates = value.predicates || [];
	const predicateList = [...predicates, ...(disabled ? [] : [newPredicate])];

	if (disabled && predicateList.length === 0) {
		return <presets.pill.Tag>matches any target</presets.pill.Tag>;
	}

	return (
		<Stack size="xSmall">
			{predicateList.map((v, i) => {
				const isNewPredicate = i >= (value.predicates?.length ?? 0);
				const handlers = isNewPredicate
					? {
							onChange: setNewPredicate,
							onDelete: undefined,
							onComplete: (predicate: AttributePredicate) => {
								onAttributeKeyChange?.(predicate.key, 'added');
								onChange?.({ ...value, predicates: [...predicates, predicate] });
								setNewPredicate({
									key: '',
									operator: 'EQUALS',
									values: [],
								});
							},
						}
					: {
							onChange: (predicate: AttributePredicate) => {
								onAttributeKeyChange?.(predicate.key, 'replaced');
								onChange?.({
									...value,
									predicates: replaceAt(predicates, i, predicate),
								});
							},
							onDelete: () => {
								onAttributeKeyChange?.(predicates[i].key, 'removed');
								const newPredicates = removeAt(predicates, i);
								if (newPredicates.length) {
									return onChange?.({
										...value,
										predicates: newPredicates,
									});
								} else {
									return onChange?.(undefined);
								}
							},
							onComplete: undefined,
						};

				const error: KeyError | ValuesError | undefined = partErrors[i];

				return (
					<React.Fragment key={i}>
						{i > 0 && <AndDivider />}
						<SinglePredicateBuilder
							id={`predicate-${i}`}
							attributeKeysLoading={attributeKeysLoading}
							autoFocus={autoFocus && !v.key && i === 0}
							attributeKeys={attributeKeys}
							operators={operators}
							disabled={disabled}
							error={error}
							value={v}
							fetchAttributeValues={fetchAttributeValues}
							validateValue={validateValue}
							{...handlers}
						/>
					</React.Fragment>
				);
			})}
		</Stack>
	);
}

function replaceAt<T>(array: T[], index: number, item: T): T[] {
	const newArray = [...array];
	newArray[index] = item;
	return newArray;
}

function removeAt<T>(array: T[], index: number): T[] {
	const newArray = [...array];
	newArray.splice(index, 1);
	return newArray;
}

function getCategory(key: string): string {
	if (key.startsWith('k8s.pod.label')) {
		return 'k8s.pod.label';
	}
	const [category] = key.split('.', 1);
	return category ?? key;
}

type AttributeKeyOption = OptionTypeBase;
type AttributeKeyGroup = SelectOptionGroup<AttributeKeyOption>;
type AttributeValueOption = OptionTypeBase;

function operatorsToOptions(operators: string[]): SingleChoiceListItem[] {
	return operators.map((operator) => ({
		label: operator.toLowerCase().replace(/_/g, ' '),
		id: operator,
	}));
}

function attributesKeyToOptions(attributeKeys: string[]): AttributeKeyGroup[] {
	const byCategory = Object.entries(groupBy(attributeKeys, getCategory));
	return byCategory.map(([category, keys]) => ({
		label: getCategoryLabel(category),
		options: keys.map((key) => ({
			label: key,
			value: key,
		})),
	}));
}

function attributesToValueOptions(values: string[]): AttributeValueOption[] {
	return values.map((value) => {
		const quoted = quoteForVariables(value);
		return { label: quoted, value: quoted };
	});
}

interface SinglePredicateBuilderProps {
	error: KeyError | ValuesError | undefined;
	attributeKeysLoading: boolean;
	value: AttributePredicate;
	attributeKeys?: string[];
	autoFocus?: boolean;
	operators?: string[];
	disabled?: boolean;
	id?: string;
	fetchAttributeValues?: (key: string) => Promise<string[]>;
	onComplete?: (value: AttributePredicate) => void;
	onChange: (value: AttributePredicate) => void;
	validateValue?: ValueValidator;
	onDelete?: () => void;
}

const SinglePredicateBuilder: React.VFC<SinglePredicateBuilderProps> = ({
	attributeKeysLoading,
	attributeKeys = [],
	id = 'predicate',
	operators = [],
	autoFocus,
	disabled,
	error,
	value,
	fetchAttributeValues = () => [],
	validateValue,
	onComplete,
	onChange,
	onDelete,
}) => {
	const keyOptions = useMemo(() => attributesKeyToOptions(attributeKeys), [attributeKeys]);
	const selectedKey = useMemo(
		() => find(keyOptions, value.key ? (o) => o.value === value.key : undefined) ?? null,
		[keyOptions, value.key],
	);

	const [valueOptions] = useAsyncState(
		async () => (value.key ? attributesToValueOptions(await fetchAttributeValues(value.key)) : []),
		[fetchAttributeValues, value.key],
	);

	const filledValues = value.values?.filter(Boolean) ?? [];
	const values = React.useMemo(() => {
		const allSelectionsAreValid = filledValues?.length === 0 || filledValues.filter((v) => v != null).length > 0;
		return disabled || !allSelectionsAreValid
			? filledValues
			: [...filledValues, { key: '', operator: 'EQUALS', values: [] }];
	}, [valueOptions.value, filledValues.join(','), disabled]);

	const emitChange = (predicate: AttributePredicate): void => {
		onChange(predicate);
		onComplete?.(predicate);
	};

	const validationError: ExperimentError | undefined =
		!smellsLikeTemplatePlaceholder(value.key) && error && 'key' in error ? error.key : undefined;
	const hasError: boolean = !!validationError;

	const operatorOptions = useMemo(() => operatorsToOptions(operators), [operators]);

	const isEmpty = !value.key && value.operator === 'EQUALS' && value.values?.length === 0;

	return (
		<Grid cols="1fr 120px 1fr auto" spacing="xSmall" align="center">
			<DropdownInputFilterable
				attributeKeysLoading={attributeKeysLoading}
				attributeKeys={attributeKeys}
				autoFocus={autoFocus}
				disabled={!!disabled}
				hasError={hasError}
				value={value.key}
				small
				onValueChanged={(_v) => emitChange({ ...value, key: _v, values: [] })}
				onItemSelected={() => {
					const firstValueInput = document.getElementById(`${id}-value-0-input`);
					if (firstValueInput) {
						firstValueInput.focus();
					}
				}}
			/>

			<presets.dropdown.SingleChoiceButton
				id={`${id}-operator`}
				items={operatorOptions.map((o) => ({ ...o, isSelected: o.id === value.operator }))}
				disabled={!!disabled || isEmpty}
				selectedId={value.operator}
				withKeyboardNavigation
				size="small"
				onSelect={(id) => emitChange({ ...value, operator: id, values: [] })}
			>
				{operatorOptions.find((option) => option.id === value.operator)?.label || ''}
			</presets.dropdown.SingleChoiceButton>

			{values.map((_, i) => {
				const hasValue = value.values?.[i] !== undefined;
				let canDelete: boolean = false;
				if (i > 0) {
					canDelete = hasValue;
				} else if (i === 0) {
					canDelete = hasValue || Boolean(value.key) || value.operator !== 'EQUALS';

					// canDeleteOnEmpty
					if (!hasValue && !value.key && value.operator === 'EQUALS') {
						canDelete = true;
					}
				}

				return (
					<Fragment key={i}>
						<ValueInput
							id={`${id}-value-${i}`}
							options={valueOptions?.value ?? []}
							value={value.values?.[i] ?? ''}
							loading={valueOptions.loading}
							operator={value.operator}
							disabled={disabled}
							validateValue={(_value) => {
								if (error && 'values' in error && error.values[i]) {
									return [false, error.values[i]];
								}
								if (!validateValue) {
									return [true, undefined];
								}
								return validateValue(_value, value.operator, valueOptions.value);
							}}
							isFirst={i === 0}
							onChange={(changed) => {
								const values = replaceAt(value.values ?? [], i, changed ?? '');
								if (values.lastIndexOf('') === values.length - 1) {
									values.pop();
								}

								// if nothing has changed in the value, we don't need to emit a change
								if (!changed && values.length === 0) {
									return;
								}
								emitChange({
									...value,
									values,
								});
							}}
						/>
						<ButtonIcon
							id={`${id}-delete`}
							variant="small"
							color="neutral600"
							tooltip="Delete"
							disabled={!canDelete || disabled || !onDelete}
							onClick={() => {
								const values = value.values ?? [];
								if (values.length <= 1) {
									onDelete?.();
									return;
								}

								const isNewElement = i >= (values.length ?? 0);
								if (isNewElement) {
									emitChange({ ...value, values: removeAt(values, i) });
								} else if (!isNewElement) {
									if (values.length === 1) {
										onDelete?.();
									} else {
										emitChange({ ...value, values: removeAt(values, i) });
									}
								} else if (selectedKey && values.length === 0) {
									emitChange({ ...value, key: '', operator: 'EQUALS' });
								} else {
									onDelete?.();
								}
							}}
						>
							<IconDelete />
						</ButtonIcon>
					</Fragment>
				);
			})}
		</Grid>
	);
};

type ValueInputProps = {
	options: Types.SelectOptions<Types.OptionTypeBase>[];
	disabled?: boolean;
	loading?: boolean;
	isFirst: boolean;
	operator: string;
	value?: string;
	id: string;
	onChange: (value: string) => void;
	validateValue: ValueValidator;
};

const ValueInput: React.VFC<ValueInputProps> = ({
	operator,
	isFirst,
	options,
	loading,
	value,
	id,
	validateValue,
	onChange,
	...props
}) => {
	const [temporaryValue, setTemporaryValue] = useState(value || '');
	useEffect(() => {
		setTemporaryValue(value || '');
	}, [value]);

	const [hasValidValue, error] = validateValue(value || '', operator, undefined);
	const isInfoError = error?.level === 'info';

	return (
		<>
			{!isFirst && (
				<>
					<div />
					<OrDivider />
				</>
			)}

			{operator === 'EQUALS' || operator === 'EQUALS_IGNORE_CASE' ? (
				<Dropdown<HTMLInputElement>
					placement="bottom-end"
					renderDropdownContent={({ close }) => {
						if (loading) {
							return (
								<presets.dropdown.DropdownContentFrame maxHeight="200px">
									<LoadingIndicator variant="medium" color="slate" sx={{ ml: 'xSmall', my: 'xxSmall' }} />
								</presets.dropdown.DropdownContentFrame>
							);
						}
						return (
							<presets.dropdown.DropdownContentFrame maxHeight="240px" maxWidth="420px">
								<presets.dropdown.SingleChoiceList
									withKeyboardNavigation
									batchSize={50}
									items={options
										.filter((o) => includes(o.label, temporaryValue))
										.map((o) => ({
											id: o.label,
											label: o.label,
										}))}
									onSelect={(id) => {
										setTemporaryValue(id);
										onChange(id);
										close();
									}}
								/>
								<Divider />
								<VariablesAndPlaceholders
									width={340}
									selectItem={(_v) => {
										onChange(_v);
										close();
									}}
								/>
							</presets.dropdown.DropdownContentFrame>
						);
					}}
				>
					{({ setRefElement, setOpen }) => {
						return (
							<TextInput
								ref={setRefElement}
								withRightIconTooltip={isInfoError ? 'The value does not exist for this key.' : undefined}
								withRightIcon={isInfoError ? 'info' : undefined}
								disabled={props.disabled}
								value={temporaryValue}
								placeholder="Value"
								autoComplete="off"
								id={`${id}-input`}
								size="small"
								errored={!hasValidValue && !isInfoError}
								style={
									isInfoError
										? {
												border: '1px solid ' + Colors.experimentOther,
												outline: '1px solid ' + Colors.experimentOther,
											}
										: {}
								}
								onClick={() => setOpen(true)}
								onFocus={() => setOpen(true)}
								onChange={setTemporaryValue}
								onChangeOnBlur={onChange}
								onKeyDown={(e) => {
									if (e.key === 'Tab') {
										setOpen(false);
									}
								}}
								data-textfield-input="true"
							/>
						);
					}}
				</Dropdown>
			) : (
				<TextInput
					disabled={props.disabled}
					value={temporaryValue}
					placeholder="Value"
					size="small"
					onChangeOnBlur={onChange}
				/>
			)}
		</>
	);
};

function correctPredicate(value: CompositePredicate): CompositePredicate {
	if (value && isQueryLanguagePredicateVO(value) && isPredicateEditableWithTheQueryBuilder(value)) {
		const predicate = toTargetPredicate((value as QueryLanguagePredicateVO).query);
		if (isTargetAttributeKeyValuePredicateVO(predicate)) {
			return {
				predicates: [predicate],
				operator: 'AND',
			};
		} else {
			return predicate as CompositePredicate;
		}
	}
	if (value && isTargetAttributeKeyValuePredicateVO(value)) {
		return {
			predicates: [value],
			operator: 'AND',
		};
	}
	return value;
}
