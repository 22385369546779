/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex, presets } from '@steadybit/ui-components-lib';
import { Li, Skeletons, Stack, Text, Ul } from 'components';
import { ReactElement, useEffect, useState } from 'react';
import { localeCompareIgnoreCase } from 'utils/string';
import { useUrlState } from 'url/useUrlState';
import { AdviceListItemVO } from 'ui-api';
import { theme } from 'styles.v2/theme';

import { selectedAdviceIdParam, UrlState } from '../urlParams';
import AdviceListItem from './AdviceListItem';
import { ampli } from '../../../ampli';

interface AdviceListProps {
	adviceList: AdviceListItemVO[];
	selectedAdviceId: string | null;
}

export default function AdviceList({ adviceList, selectedAdviceId }: AdviceListProps): ReactElement {
	const [, , updateUrlState] = useUrlState<UrlState>([selectedAdviceIdParam]);

	// if nothing is selected, select the first advice
	useEffect(() => {
		if (selectedAdviceId || adviceList.length === 0) {
			return;
		}

		updateUrlState({ selectedAdviceId: adviceList.sort((a, b) => a.label.localeCompare(b.label))[0].type });
	}, [selectedAdviceId, adviceList]);
	const [selectedTags, setSelectedTags] = useState<string[]>([]);

	useEffect(() => {
		ampli.explorerAdviceListViewed({
			advice_ids_listed: adviceList.map((advice) => advice.type),
			advice_id_shown: selectedAdviceId ?? undefined,
			filter_tag: selectedTags,
			advice_context: 'explorer_advice',
		});
	}, [adviceList, selectedAdviceId, selectedTags]);

	const tags = adviceList
		.reduce((acc, advice) => {
			(advice.tags || []).forEach((tag) => {
				if (acc.indexOf(tag) === -1) {
					acc.push(tag);
				}
			});
			return acc;
		}, [] as string[])
		.sort(localeCompareIgnoreCase)
		.map((tag) => ({ value: tag, label: tag }));

	let filteredAdviceList = adviceList.sort((a, b) => a.label.localeCompare(b.label));
	if (selectedTags.length > 0) {
		filteredAdviceList = filteredAdviceList.filter((advice) =>
			selectedTags.find((tag) => advice.tags.indexOf(tag) !== -1),
		);
	}

	return (
		<Stack
			size="none"
			sx={{
				borderRight: '1px solid ' + theme.colors.neutral300,
				overflowY: 'auto',
			}}
		>
			{tags.length > 0 && (
				<Flex spacing="xSmall" style={{ p: 'small', backgroundColor: theme.colors.neutral100 }}>
					<Text variant="smallStrong">Filter by:</Text>
					<presets.dropdown.MultiChoiceButton
						items={tags.map((tag) => ({ id: tag.value, label: tag.label }))}
						placeholder="Show everything"
						selectedIds={selectedTags}
						maxContentHeight="300px"
						onUncheck={(id) => setSelectedTags(selectedTags.filter((i) => i !== id))}
						onCheck={(id) => setSelectedTags([...selectedTags, id])}
						style={{ width: '100%' }}
					/>
				</Flex>
			)}

			<Ul
				sx={{
					p: 'small',
					height: '100%',
				}}
			>
				{filteredAdviceList.map((advice) => {
					const isSelected = selectedAdviceId === advice.type;
					return <AdviceListItem key={advice.type} advice={advice} isSelected={isSelected} />;
				})}
			</Ul>
		</Stack>
	);
}

export function LoadingList(): ReactElement {
	return (
		<Ul
			sx={{
				p: 'small',
				borderRight: '1px solid ' + theme.colors.neutral300,
				height: '100%',
				overflowY: 'auto',
			}}
		>
			<Li mb="small">
				<Skeletons height={56} widths={[390]} pb="xxSmall" mt={1} />
			</Li>
			<Li mb="small">
				<Skeletons height={56} widths={[390]} pb="xxSmall" mt={1} />
			</Li>
			<Li mb="small">
				<Skeletons height={56} widths={[390]} pb="xxSmall" mt={1} />
			</Li>
		</Ul>
	);
}
