/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { ActionVO, ExperimentStepExecutionActionTargetVO } from 'ui-api';
import { useTargetDefinition } from 'targets/useTargetDefinition';
import { toParameterLabelValues } from 'services/actionsApi';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import useActions from 'pages/experimentsV2/useActions';
import { getLabel as getI18nLabel } from 'i18n/label';
import { Services } from 'services/services';
import { startCase, toLower } from 'lodash';
import { Stack, Text } from 'components';
import React from 'react';

import { usePromise } from '../utils/hooks/usePromise';

type ActionSpec = {
	parameters: Record<string, string>;
	totalTargetCount?: number;
	targetExecutions?: ExperimentStepExecutionActionTargetVO[];
	blastRadius?: { percentage?: number };
};

type ActionInfoProps = ActionSpec & {
	actionId: string;
	customLabel?: string;
};

export const ActionInfoBox: React.VFC<{ value: ActionInfoProps }> = ({ value }) => {
	const { customLabel, actionId } = value;
	const action = useActions().actions.find((action) => action.id === actionId);

	const [actionName] = useAsyncState<string>(
		async () => await Services.actions.findActionNameWithTargetTypeIfNotUnique(actionId),
		[actionId],
	);
	if (!action) {
		if (customLabel) {
			return <Text variant="smallStrong">{value.customLabel}</Text>;
		}
		return null;
	}
	return <ActionInfoBoxContent action={action} actionName={actionName.value} value={value} />;
};

const ActionInfoBoxContent: React.VFC<{
	value: ActionInfoProps;
	actionName?: string;
	action: ActionVO;
}> = ({ action, actionName, value }) => {
	const { mode } = useEditorSettings();
	const targetDefinition = useTargetDefinition(action.target.type);
	const parameters = usePromise(
		async () => (action.id ? await Services.experiments.fetchActionParameters(action.id) : []),
		[action.id],
	);
	if (!parameters.value) {
		return null;
	}
	const targetCount = value.targetExecutions?.length;
	const totalTargetCount = value.totalTargetCount;
	const radius = value.blastRadius?.percentage !== undefined ? ` (Radius: ${value.blastRadius?.percentage}%)` : '';
	const singleTargetOnly = targetCount === 1 && value.totalTargetCount === 1;
	let targets = '';
	if (targetCount != null && targetDefinition.value && !singleTargetOnly) {
		targets = `${targetCount} of ${value.totalTargetCount} ${getI18nLabel(
			targetDefinition.value.label,
			targetCount,
		)} ${radius}`;
	}

	return (
		<>
			<Text variant={'smallStrong'} mb={'xxSmall'}>
				{value.customLabel ?? actionName ?? action.name}{' '}
				{action.kind !== 'OTHER' ? startCase(toLower(action.kind)) : ''}
			</Text>
			<Stack size={2}>
				{value.customLabel && !value.customLabel.includes(actionName || action.name) && (
					<Text variant={'smallMedium'}>Action: {actionName ?? action.name}</Text>
				)}
				{mode !== 'templateUsage' && (
					<>
						{targets && <Text variant={'small'}>{targets}</Text>}
						{toParameterLabelValues(value.parameters, parameters.value).map(
							({ name, label, value }) =>
								value !== undefined &&
								value !== '' &&
								value !== null && (
									<Text variant={'small'} key={name} sx={{ ...textEllipsis }}>{`${label}: ${value}`}</Text>
								),
						)}
						{action.kind === 'ATTACK' && targetCount && totalTargetCount && targetDefinition.value ? (
							<Stack size="xxSmall" pt="small">
								<Text variant="small" color="neutral600">
									Targets:
								</Text>
								<Text variant="smallStrong">
									{`${targetCount} of ${totalTargetCount}${
										totalTargetCount > 0
											? ` (Ratio: ${Math.round(100_000 * (targetCount / totalTargetCount)) / 1_000}%)`
											: ''
									}`}
								</Text>
							</Stack>
						) : null}
					</>
				)}
			</Stack>
		</>
	);
};
