/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Dropdown, presets } from '@steadybit/ui-components-lib';
import { theme } from 'styles.v2/theme';
import { Container } from 'components';
import { ReactElement } from 'react';

import VariableHighlightingOrElse from './VariableHighlightingOrElse';
import VariablesAndPlaceholders from './VariablesAndPlaceholders';
import FocussableWrapper from './FocussableWrapper';

interface IntegerProps {
	hasErrors?: boolean;
	disabled: boolean;
	min?: number;
	max?: number;
	value: string | number;
	setValue: (value: string | number) => void;
}

export default function Integer({ value, min, max, hasErrors, disabled, setValue }: IntegerProps): ReactElement {
	return (
		<Input
			value={value}
			onChange={(_v) => {
				if (typeof _v === 'string') {
					setValue(_v);
					return;
				}
				setValue(_v >= 0 ? _v : 0);
			}}
			hasErrors={hasErrors}
			disabled={disabled}
			min={min}
			max={max}
		/>
	);
}

interface InputProps {
	onChange: (value: number | string) => void;
	value: number | string;
	hasErrors?: boolean;
	withUnit?: boolean;
	disabled: boolean;
	min?: number;
	max?: number;
}

export function Input({
	max = Number.MAX_SAFE_INTEGER,
	min = 0,
	hasErrors,
	disabled,
	withUnit,
	value,
	onChange,
}: InputProps): ReactElement {
	return (
		<Dropdown<HTMLDivElement>
			renderDropdownContent={({ width, close }) => (
				<presets.dropdown.DropdownContentFrame width={width}>
					<VariablesAndPlaceholders
						width={width}
						selectItem={(v) => {
							onChange(v);
							close();
						}}
					/>
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				let valueAsNumber = Number(value);
				if (isNaN(valueAsNumber)) {
					valueAsNumber = 0;
				}
				const hasError = hasErrors || valueAsNumber < min || valueAsNumber > max;

				return (
					<FocussableWrapper ref={setRefElement} onFocus={() => setOpen(true)} onBlur={() => {}} disabled={disabled}>
						<VariableHighlightingOrElse
							hasErrors={hasErrors}
							value={String(value)}
							isEditMode={isOpen}
							disabled={disabled}
							withUnit={withUnit}
							onClick={() => setOpen(true)}
						>
							<Container
								ref={setRefElement}
								as="input"
								type="number"
								min={min}
								max={max}
								// removes leading zeros e.g. 0001 -> 1
								value={valueAsNumber.toString()}
								onChange={(e) => {
									let value = e.target.valueAsNumber;
									// e.target.valueAsNumber is NaN if the input is empty
									if (isNaN(value)) {
										value = 0;
									}
									onChange(value);
								}}
								disabled={disabled}
								sx={{
									height: '40px',
									px: 'xSmall',
									py: 'xSmall',
									color: 'neutral800',
									fontSize: '15px',
									borderRadius: withUnit ? '4px 0 0 4px' : '4px',
									border: '1px solid',
									borderRight: withUnit ? 'none' : '1px solid',
									borderColor: hasError ? 'coral' : 'neutral300',
									width: '100%',

									':focus': {
										outline: 'none',
										borderColor: hasError ? 'coral' : 'slate',
										boxShadow: `inset 0 0 0 1px ${hasError ? theme.colors.coral : theme.colors.slate}`,
									},
									':disabled': {
										bg: 'neutral100',
										borderColor: hasError ? 'coral' : 'neutral200',
									},
								}}
							/>
						</VariableHighlightingOrElse>
					</FocussableWrapper>
				);
			}}
		</Dropdown>
	);
}
