/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, Li, Link, ModalContentV2, ModalHeaderV2, ModalV2, Text, Ul } from 'components';
import { IconTemplate } from 'components/icons';
import { ReactElement } from 'react';

interface UnknownActionsTemplateModalProps {
	unknownActions: string[];
	onClose: () => void;
}

export default function UnknownActionsTemplateModal({
	unknownActions,
	onClose,
}: UnknownActionsTemplateModalProps): ReactElement {
	return (
		<ModalV2 slick withFooter width="90vw" maxWidth="1650px" minHeight="500px" centered>
			<ModalHeaderV2 title="" onClose={onClose} />
			<ModalContentV2>
				<Container
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						height: '400px',
					}}
				>
					<Container
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '16px',
							width: '100%',
							color: 'neutral700',
						}}
					>
						<IconTemplate variant="xxxLarge" color="slate" />
						<Text variant="xLargeStrong">Incompatible Template</Text>
						<Text
							variant="medium"
							color="neutral600"
							maxWidth={750}
							sx={{
								textAlign: 'center',
							}}
						>
							The template you are trying to use contains steps that are not supported in this tenant. Please contact
							your admin and ask them{' '}
							<Link
								sx={{ fontWeight: '500' }}
								href="https://docs.steadybit.com/integrate-with-steadybit/extensions/extension-installation"
								external
							>
								to install
							</Link>{' '}
							extensions providing the following missing actions:
						</Text>

						<Ul>
							{unknownActions
								.slice()
								.sort()
								.map((action) => (
									<Li key={action} sx={{ mb: 'xSmall' }}>
										-{' '}
										{action.startsWith('com.steadybit.') ? (
											<Link
												href={`https://hub.steadybit.com/action/${action}`}
												external
												sx={{
													'&:hover': {
														textDecoration: 'underline',
													},
												}}
											>
												<Text as="span" variant="mediumMedium">
													{action}
												</Text>
											</Link>
										) : (
											<Text as="span" variant="mediumMedium" color="neutral800">
												{action}
											</Text>
										)}
									</Li>
								))}
						</Ul>
					</Container>
				</Container>
			</ModalContentV2>
		</ModalV2>
	);
}
