/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconArrowRight, IconAttackTime, IconDeployment, IconWarning } from 'components/icons';
import StripChart, { colors, DataPoint, StripType } from 'components/StripChart/StripChart';
import { useGroupedExecutionMetrics } from 'services/executionMetricsApi';
import { Container, Pill, Stack, Text } from 'components';
import { ReactElement, useMemo, useState } from 'react';
import { presets } from '@steadybit/ui-components-lib';
import { theme } from 'styles.v2/theme';
import { orderBy } from 'lodash';

import { ExperimentRunLogsAndMetricsProps } from '../experimentExecutionLogsAndMetrics';
import ChartWithTimeAxis from './ChartWithTimeAxis';
import LoadingContent from './LoadingContent';
import EmptyContent from './EmptyContent';

const REPLICA_METRIC_NAMES = ['replicas_desired_count', 'replicas_ready_count', 'replicas_current_count'];
const REPLICA_METRIC_GROUPS = ['k8s.cluster-name', 'k8s.namespace', 'k8s.deployment'];

type GroupStats = { deviations: number; lastValues: Record<string, number>; color: string; key: string };

type DeploymentReplicaCountMetricsProps = Pick<
	ExperimentRunLogsAndMetricsProps,
	'duration' | 'start' | 'position' | 'experimentExecution' | 'progress'
>;

export default function DeploymentReplicaCountMetrics({
	experimentExecution,
	progress,
	start,
	position,
	duration,
}: DeploymentReplicaCountMetricsProps): ReactElement {
	const groupLimit = 5;
	const liveUpdate = !experimentExecution.ended;
	const metrics = useGroupedExecutionMetrics(
		experimentExecution.id,
		REPLICA_METRIC_NAMES,
		REPLICA_METRIC_GROUPS,
		liveUpdate,
	);
	const [groups, noDeviations] = useMemo(() => {
		const groups = new Map<string[], GroupStats>();
		let noDeviations = true;
		metrics.value?.forEach((metric) => {
			const { group, values } = metric;
			let groupStat = groups.get(group);
			if (!groupStat) {
				groupStat = {
					deviations: 0,
					lastValues: values,
					color: theme.charts.colors[groups.size + (1 % theme.charts.colors.length)],
					key: group.join('-'),
				};
				groups.set(group, groupStat);
			} else {
				groupStat.lastValues = values;
			}
			if (
				metric.values.replicas_ready_count < metric.values.replicas_desired_count ||
				metric.values.replicas_current_count > metric.values.replicas_desired_count
			) {
				groupStat.deviations++;
				noDeviations = false;
			}
		});
		const orderedGroups = orderBy(Array.from(groups.entries()), ([, stats]) => stats.deviations, ['desc']);
		return [orderedGroups, noDeviations];
	}, [metrics.value]);
	const metricsWithEnd = useMemo(() => {
		//for the lines drawn till progress/end we need to duplicate a the last value with the timestamp
		if (!metrics.value) {
			return [];
		}
		const ts = progress ?? experimentExecution.ended ?? start + duration;
		return [...metrics.value, ...groups.map(([group, stats]) => ({ ts, group, values: stats.lastValues }))];
	}, [metrics, start, duration, progress, experimentExecution.ended, groups]);

	const [checked, setChecked] = useState<Record<string, boolean>>({});
	const isChecked = (stats: GroupStats): boolean => {
		if (checked[stats.key]) {
			return true;
		}
		if (Object.keys(checked).length > 0) {
			return false;
		}
		return noDeviations || Boolean(stats.deviations);
	};

	if (!metrics.value?.length) {
		return (
			<Stack size={0} sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
				{liveUpdate || metrics.loading ? (
					<LoadingContent loading={metrics.loading}>Waiting for metrics...</LoadingContent>
				) : (
					<EmptyContent>No metrics recorded.</EmptyContent>
				)}
			</Stack>
		);
	}

	const groupsWithDeviations = groups.filter(([, stats]) => Boolean(stats.deviations));
	if (groupsWithDeviations.length === 0) {
		return (
			<EmptyContent>
				<Text muted>All {groups.length} deployments have as many pods ready as desired.</Text>
			</EmptyContent>
		);
	}

	const groupsAsValues = groupsWithDeviations.map(([g]) => {
		return {
			label: g[2],
			value: g.join('-'),
		};
	});
	const renderedGroups = groupsWithDeviations.filter(([, stats]) => isChecked(stats)).slice(0, groupLimit);
	const renderedGroupsAsValues = renderedGroups.map(([g]) => {
		return {
			label: g[2],
			value: g.join('-'),
		};
	});

	const metricsTo = progress ?? experimentExecution.ended ?? start + duration;
	const metricsToNumber = typeof metricsTo === 'number' ? metricsTo : new Date(metricsTo).getTime();
	let strips: StripType[] = renderedGroups.map(([group, stats]) => {
		const [gc, gn, gd] = group;
		let dataPoints = metricsWithEnd
			.filter((m) => {
				const [mc, mn, md] = m.group;
				return gc === mc && gn === mn && gd === md;
			})
			.map(({ ts, values }) => {
				return {
					timestamp: typeof ts === 'number' ? ts : new Date(ts).getTime(),
					endTimestamp: typeof metricsTo === 'number' ? metricsTo : new Date(metricsTo).getTime(),
					values: {
						replicas_desired_count: values.replicas_desired_count,
						replicas_ready_count: values.replicas_ready_count,
						replicas_current_count: values.replicas_current_count,
					},
				};
			})
			.filter((d) => d.timestamp <= metricsToNumber)
			.sort((a, b) => a.timestamp - b.timestamp);

		// we only have start timestamps, so we need to cut the slices properly by hand
		dataPoints = dataPoints.map((dataPoint, i) => {
			const { endTimestamp } = dataPoint;
			return {
				...dataPoint,
				endTimestamp: dataPoints[i + 1]?.timestamp ?? endTimestamp,
			};
		});

		return {
			label: group[2],
			descriptor: `${stats.lastValues.replicas_ready_count}/${stats.lastValues.replicas_desired_count}`,
			dataPoints,
		};
	});

	const numStripFills = groupLimit - strips.length;

	const stripFills = [];
	for (let i = 0; i < numStripFills; i++) {
		stripFills.push({
			label: '',
			descriptor: '',
			dataPoints: [],
		});
	}

	strips = [...stripFills, ...strips];

	const addCheckedGroup = (key: string, isChecked: boolean): void => {
		const checkedKeys = {
			...checked,
		};

		// There is some magic which automatically adds violating groups to the checked list.
		// This magic stops, as soon as the user has interacted with the chart.
		if (Object.keys(checked).length === 0) {
			for (let i = 0; i < renderedGroups.length; i++) {
				const [, { key }] = renderedGroups[i];
				checkedKeys[key] = true;
			}
		}
		setChecked({ ...checkedKeys, [key]: isChecked });
	};

	return (
		<Container p="small" pt="xSmall" height="100%">
			<Stack size="xSmall" height="100%" justifyContent="space-between">
				<Container>
					<Text as="span" variant="smallStrong" color="neutral800" mx="xxSmall">
						{groupsWithDeviations.length}
					</Text>
					<Text as="span" variant="small" color="neutral600">
						out of
					</Text>
					<Text as="span" variant="smallStrong" color="neutral800" mx="xxSmall">
						{groups.length}
					</Text>
					<Text as="span" variant="small" color="neutral600">
						deployments have deviating pods ready
					</Text>
				</Container>

				<presets.dropdown.MultiChoiceButton
					items={groupsAsValues.map((item) => ({ id: item.value, label: item.label }))}
					selectedIds={renderedGroupsAsValues.map((g) => g.value)}
					maxSelections={groupLimit}
					placeholder=""
					onUncheck={(id) => addCheckedGroup(id, false)}
					onCheck={(id) => addCheckedGroup(id, true)}
					style={{ width: '100%' }}
				/>

				<ChartWithTimeAxis position={position} start={start} duration={duration}>
					<StripChart
						strips={strips}
						fromTimestamp={start}
						toTimestamp={start + duration}
						getColor={(dataPoint): string => {
							return dataPoint.values.replicas_ready_count < dataPoint.values.replicas_desired_count
								? colors.warn
								: dataPoint.values.replicas_current_count > dataPoint.values.replicas_desired_count
									? colors.info
									: colors.success;
						}}
						getTooltipContent={(strip, dataPoint) => <TooltipContent strip={strip} dataPoint={dataPoint} />}
					/>
				</ChartWithTimeAxis>
			</Stack>
		</Container>
	);
}

interface TooltipContentProps {
	strip: StripType;
	dataPoint: DataPoint;
}

function TooltipContent({ strip, dataPoint }: TooltipContentProps): ReactElement {
	return (
		<Stack size="xSmall" px="small" py="xSmall">
			<Pill color="neutral800" backgroundColor="neutral200" sx={{ borderRadius: 4 }}>
				<IconDeployment mr="xxSmall" />
				{strip.label}
			</Pill>

			<Container display="flex" alignItems="center">
				<IconAttackTime variant="small" />
				<Text ml="xSmall" color="neutral000">
					{new Date(dataPoint.timestamp).toLocaleTimeString()}
				</Text>
				<IconArrowRight variant="small" mx="xSmall" />
				<Text color="neutral000">
					{dataPoint.endTimestamp ? new Date(dataPoint.endTimestamp).toLocaleTimeString() : '-'}
				</Text>
			</Container>

			{dataPoint.values.replicas_desired_count > dataPoint.values.replicas_ready_count ? (
				<Container display="flex" alignItems="center" justifyContent="space-between">
					<Text ml="xSmall" color="neutral000">
						Missing pods:
					</Text>
					<Pill
						backgroundColor={theme.colors.yellow200}
						color={theme.colors.experimentWarning}
						sx={{
							height: 32,
							borderRadius: 20,
							border: '1px solid #B42318',
						}}
					>
						<IconWarning variant="small" color="experimentWarning" />
						<Text variant="smallStrong" ml="xxSmall" mr="xxSmall">
							{dataPoint.values.replicas_desired_count - dataPoint.values.replicas_ready_count}
						</Text>
					</Pill>
				</Container>
			) : (
				<Container display="block" alignItems="center" justifyContent="space-between">
					<Text ml="xSmall" color="neutral000">
						Ready: {dataPoint.values.replicas_ready_count}/{dataPoint.values.replicas_current_count}
					</Text>
					<Text ml="xSmall" color="neutral000">
						Desired: {dataPoint.values.replicas_desired_count}
					</Text>
				</Container>
			)}
		</Stack>
	);
}
