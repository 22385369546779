/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { presets } from '@steadybit/ui-components-lib';
import { startCase, toLower } from 'lodash';
import { ReactElement } from 'react';

interface StatesProps {
	selectedStates: string[];
	states: string[];
	selectItems: (envIds: string[]) => void;
}

export default function States({ selectedStates, states, selectItems }: StatesProps): ReactElement {
	return (
		<presets.dropdown.MultiChoiceButton
			placement="bottom-end"
			items={states.map((state) => ({
				id: state,
				label: startCase(toLower(state)),
			}))}
			maxContentHeight="400px"
			selectedIds={selectedStates}
			onUncheck={(id) => selectItems(selectedStates.filter((i) => i !== id))}
			onCheck={(id) => selectItems([...selectedStates, id])}
			placeholder="Any state"
		/>
	);
}
