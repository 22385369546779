/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import CreateTemplateModal from 'templates/components/CreateTemplateModal/CreateTemplateModal';
import TemplateEditorLoader from 'pages/templates/TemplateEditor/TemplateEditorLoader';
import TemplateEditor from 'pages/templates/TemplateEditor/TemplateEditor';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import useGlobalPermissions from 'services/useGlobalPermissions';
import React, { lazy, ReactNode, Suspense } from 'react';
import { Container, ModalOverlay } from 'components';
import { Redirect, Route, Switch } from 'url/router';
import { useHistory } from 'url/hooks';
import { Helmet } from 'react-helmet';

import useShouldBeCareful from '../../App/components/SteadybitUserOnProd/useShouldBeCareful';
import EditEnvironmentVariables from './environments/config/EditEnvironmentVariables';
import EditEnvironment from './environments/config/EditEnvironment';
import Troubleshooting from './Troubleshooting/Troubleshooting';
import AccessTokensList from './api-tokens/accessTokensList';
import Customization from './Customization/Customization';
import ExtensionList from './ExtensionList/ExtensionList';
import ChangeAgentLogLevel from './agents/changeLogLevel';
import AddAccessToken from './api-tokens/addAccessToken';
import CreateTeamWizard from './teams/createTeamWizard';
import { useLicense } from '../../services/licenseApi';
import Environments from './environments/environments';
import Integrations from './integrations/integrations';
import Templates from './Templates/Templates';
import AgentSetup from './agents/agentSetup';
import InviteUser from './users/inviteUser';
import EditTeam from './teams/editTeam';
import Agents from './agents/agents';
import { ampli } from '../../ampli';
import AgentLog from './agents/log';
import Teams from './teams/teams';
import Users from './users/users';
import Hubs from './Hubs/Hubs';

const License = lazy(() => import('./Licenses/License'));

interface SettingsProps {
	someAgentHasReported: boolean;
	isAdmin: boolean;
}

export default function Settings({ isAdmin, someAgentHasReported }: SettingsProps): React.ReactElement {
	const license = useLicense();
	const showCustomLabeling = !!license?.features.find((f) => f.name === 'CUSTOM_LABELING');

	const permissions = useGlobalPermissions();
	const canCreateTemplates = permissions.templates.canCreate;

	const location = useHistory().location;

	const isTemplateEditor = location.pathname.includes('/settings/templates/design');

	return (
		<Container
			as="main"
			sx={{
				gridArea: 'main',
				position: 'relative',
				overflowY: isTemplateEditor ? 'hidden' : 'auto',
				overflowX: 'hidden',
			}}
		>
			<Switch>
				{canCreateTemplates && (
					<Route path="/settings/templates/design/fromExperiment/:experimentKey">
						{({ match }) => {
							const experimentKey = match?.params.experimentKey || '';
							return (
								<>
									<Helmet title={`Experiment Templates / ${experimentKey}>`} />
									<TemplateEditorLoader experimentKey={experimentKey}>
										<TemplateEditor />
									</TemplateEditorLoader>
								</>
							);
						}}
					</Route>
				)}
				{canCreateTemplates && (
					<Route path="/settings/templates/design/<new>">
						{() => (
							<>
								<Helmet title={'Experiment Templates / <new>'} />
								<TemplateEditorLoader>
									<TemplateEditor />
								</TemplateEditorLoader>
							</>
						)}
					</Route>
				)}
				{canCreateTemplates && (
					<Route path="/settings/templates/design/:templateId">
						{({ match }) => {
							const templateId = match?.params.templateId || '';
							return (
								<>
									<Helmet title={`Experiment Templates / ${templateId}>`} />
									<TemplateEditorLoader templateId={templateId}>
										<TemplateEditor />
									</TemplateEditorLoader>
								</>
							);
						}}
					</Route>
				)}
			</Switch>

			<Container px={'xxLarge'} flexGrow={1} sx={{ overflowY: 'auto' }}>
				<Switch>
					<Route path={'/settings/environments/:id/variables'} exact>
						{({ match }) =>
							match ? (
								<>
									<Helmet>
										<title>Settings / Environments / Variables</title>
									</Helmet>
									<EditEnvironmentVariables environmentId={match.params.id} />
								</>
							) : null
						}
					</Route>

					<Route path={'/settings/environments/:id'} exact>
						{({ match }) =>
							match ? (
								<FulllscreenWrapper>
									<Helmet>
										<title>Settings / Environments</title>
									</Helmet>
									<EditEnvironment environmentId={match.params.id} />
								</FulllscreenWrapper>
							) : null
						}
					</Route>

					<Route path={'/settings'} exact>
						<Redirect to={'/settings/teams'} />
					</Route>
					<Route path={['/settings/environments/:id']}>
						<AccessControl />
					</Route>
					<Route
						path={[
							'/settings/access',
							'/settings/environments',
							'/settings/teams',
							'/settings/users',
							'/settings/api-tokens',
						]}
					>
						<AccessControl />
					</Route>
					<Route>
						<ApplicationSettings
							someAgentHasReported={someAgentHasReported}
							isAdmin={isAdmin}
							showCustomLabeling={showCustomLabeling}
						/>
					</Route>
				</Switch>
			</Container>
		</Container>
	);
}

const ApplicationSettings: React.VFC<{
	isAdmin: boolean;
	someAgentHasReported: boolean;
	showCustomLabeling: boolean;
}> = ({ isAdmin, someAgentHasReported, showCustomLabeling }) => {
	const history = useHistory();
	const shouldBeCareful = useShouldBeCareful();
	return (
		<Container>
			<Helmet>
				<title>Settings</title>
			</Helmet>
			<Switch>
				<Route path={'/settings/agents'}>
					<Helmet>
						<title>Settings / Agents</title>
					</Helmet>
					<Agents />

					<Switch>
						<Route path={'/settings/agents/setup'}>
							{() => (
								<ModalOverlay open onClose={() => history.goBack()} centerContent>
									{({ close }) => {
										if (!someAgentHasReported) {
											ampli.agentSetupResumed();
											return <Redirect to={'/onboarding'} />;
										}
										return <AgentSetup close={close} />;
									}}
								</ModalOverlay>
							)}
						</Route>

						<Route path={'/settings/agents'}>
							<Route path={'/settings/agents/installed/loglevel'}>
								{({ match }) => (
									<ModalOverlay open={Boolean(match)} onClose={() => history.goBack()} centerContent>
										{match ? ({ close }) => <ChangeAgentLogLevel close={close} /> : null}
									</ModalOverlay>
								)}
							</Route>
							<Route path={'/settings/agents/installed/:id/loglevel'}>
								{({ match }) => (
									<ModalOverlay open={Boolean(match)} onClose={() => history.goBack()} centerContent>
										{match ? ({ close }) => <ChangeAgentLogLevel close={close} agentId={match.params.id} /> : null}
									</ModalOverlay>
								)}
							</Route>
							<Route path={'/settings/agents/installed/:id/log'}>
								{({ match }) => (
									<ModalOverlay open={Boolean(match)} onClose={() => history.goBack()}>
										{match ? ({ close }) => <AgentLog close={close} agentId={match.params.id} /> : null}
									</ModalOverlay>
								)}
							</Route>
						</Route>
						<Route path={'/settings/agents'}>
							<Redirect to={'/settings/agents/installed'} />
						</Route>
					</Switch>
				</Route>

				<Route path={'/settings/extensions'}>
					<>
						<Helmet>
							<title>Settings / Extensions</title>
						</Helmet>
						<ExtensionList />
					</>
				</Route>

				<Route path={['/settings/templates', '/settings/templates/<new>']} exact>
					{({ match }) => {
						return (
							<>
								<Helmet>
									<title>Settings / Templates</title>
								</Helmet>
								{match?.path === '/settings/templates/<new>' && (
									<CreateTemplateModal onClose={() => history.push('/settings/templates')} />
								)}

								<Templates />
							</>
						);
					}}
				</Route>

				<Route path="/settings/hubs/<new>">
					<>
						<Helmet>
							<title>Settings / Hubs</title>
						</Helmet>
						<Hubs isNewHub />
					</>
				</Route>

				<Route path="/settings/hubs/:id?">
					{({ match }) => {
						return (
							<>
								<Helmet>
									<title>Settings / Hubs</title>
								</Helmet>
								<Hubs selectedHubId={match?.params.id} />
							</>
						);
					}}
				</Route>

				{showCustomLabeling && (
					<Route path={'/settings/customization'}>
						<>
							<Helmet>
								<title>Settings / Customization</title>
							</Helmet>
							<Customization />
						</>
					</Route>
				)}

				<Route path={'/settings/troubleshooting'}>
					<>
						<Helmet>
							<title>Settings / Troubleshooting</title>
						</Helmet>
						<Troubleshooting />
					</>
				</Route>

				{(isAdmin || shouldBeCareful) && (
					<Route path={'/settings/license'}>
						<>
							<Helmet>
								<title>Settings / License</title>
							</Helmet>
							<Suspense fallback={<div />}>
								<License />
							</Suspense>
						</>
					</Route>
				)}

				<Route exact path={'/settings/integrations'}>
					<Redirect to={'/settings/integrations/slack'} />
				</Route>

				<Route path={'/settings/integrations/:active?'}>
					{({ match }) => (
						<>
							<Helmet>
								<title>Settings / Integrations</title>
							</Helmet>
							<Integrations active={match?.params.active} />
						</>
					)}
				</Route>
			</Switch>
		</Container>
	);
};

const AccessControl: React.VFC = () => {
	const history = useHistory();
	return (
		<>
			<Helmet>
				<title>Settings </title>
			</Helmet>
			<Route path={'/settings/environments'}>
				<Helmet>
					<title>Settings / Environments</title>
				</Helmet>
				<Switch>
					<Route>
						<Environments />
					</Route>
				</Switch>
			</Route>
			<Route path={'/settings/users'}>
				<Helmet>
					<title>Settings / Users</title>
				</Helmet>
				<Users />
				<Route path={'/settings/users/invite'}>
					{({ match }) => (
						<ModalOverlay open={Boolean(match)} onClose={() => history.push('/settings/users')}>
							<InviteUser />
						</ModalOverlay>
					)}
				</Route>
			</Route>
			<Route path={'/settings/teams'}>
				<Helmet>
					<title>Settings / Teams</title>
				</Helmet>
				<Teams />
				<Route path={'/settings/teams/create'}>
					{({ match }) => (
						<ModalOverlay open={Boolean(match)} onClose={() => history.push('/settings/teams')}>
							<CreateTeamWizard />
						</ModalOverlay>
					)}
				</Route>
				<Route exact path={'/settings/teams/:id'}>
					{({ match }) => (
						<ModalOverlay
							open={!!match && match.params.id !== 'create' && match.params.id !== 'archived'}
							onClose={() => history.push('/settings/teams')}
						>
							{match ? ({ close }) => <EditTeam close={close} id={match.params.id} /> : null}
						</ModalOverlay>
					)}
				</Route>
			</Route>
			<Route path={'/settings/api-tokens'}>
				<Helmet>
					<title>Settings / API Access Tokens</title>
				</Helmet>
				<AccessTokensList onCreate={() => '/settings/api-tokens/create'} />
				<Route path={'/settings/api-tokens/create'}>
					{({ match }) => (
						<ModalOverlay open={Boolean(match)} onClose={() => history.push('/settings/api-tokens')}>
							{({ close }) => <AddAccessToken onClose={close} />}
						</ModalOverlay>
					)}
				</Route>
			</Route>
		</>
	);
};

function FulllscreenWrapper({ children }: { children: ReactNode }): ReactElement {
	return (
		<Container
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				display: 'flex',
				flexDirection: 'column',
				bg: 'neutral000',
			}}
		>
			{children}
		</Container>
	);
}
