/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import EnvironmentSelectorMultiSelect from 'hocs/EnvironmentSelectorMultiSelect';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Services } from 'services/services';
import { debounce } from 'lodash';

export const EnvironmentPermissions: React.VFC<{
	addEnvironmentEnabled?: boolean;
	disabledExplanation?: ReactNode;
	disabled?: boolean;
}> = ({ addEnvironmentEnabled, disabled, disabledExplanation }) => {
	const [environments, fetch] = useAsyncState(
		async () => (await Services.environments.fetchEnvironments()).content,
		[],
	);

	const debouncedFetch = useMemo(() => debounce(fetch, 100), [fetch]);
	useEventEffect(
		useCallback(
			(event) => {
				if ('environment.created' === event.type) {
					debouncedFetch();
				}
			},
			[debouncedFetch],
		),
		[],
		debouncedFetch.cancel,
	);

	return (
		<EnvironmentSelectorMultiSelect
			mt="xSmall"
			environments={environments.value || []}
			fieldName="allowedEnvironments"
			addEnvironmentEnabled={addEnvironmentEnabled}
			disabled={disabled}
			disabledExplanation={disabledExplanation}
		/>
	);
};
