/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Box, presets, SingleChoiceListItem } from '@steadybit/ui-components-lib';
import ExperimentRunCard from 'pages/experiments/components/ExperimentRunCard';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import Markdown from 'components/Markdown/Markdown';
import { groupBy } from 'lodash';

import { ExperimentPlayerTimeStamp } from '../../../../../components/ExperimentPlayer/types';
import { ExperimentStepExecutionActionVO, MarkdownWidgetVO } from '../../../../../ui-api';
import { useExecutionLogs } from '../../../../../services/useExecutionLogs';
import { Link, Stack, Text } from '../../../../../components';
import { IconClose } from '../../../../../components/icons';
import { formatTime } from '../../../../../utils/dateFns';
import LoadingContent from '../../metrics/LoadingContent';
import EmptyContent from '../../metrics/EmptyContent';
import { WidgetProps } from '../types';

export default function MarkdownWidget({
	experimentExecution,
	widget,
	position,
	onPositionSelect,
}: WidgetProps): ReactElement {
	const markdownWidget = widget as MarkdownWidgetVO;
	const liveUpdate = !experimentExecution.ended;
	const { result: messages } = useExecutionLogs({
		executionId: experimentExecution.id,
		type: markdownWidget.messageType,
		id: undefined,
		liveUpdate,
	});

	const [selectedTarget, setSelectedTarget] = useState<string | null>(null);

	const content = useMemo(() => {
		if (!messages.value) {
			return '';
		}
		const filteredMessages = messages.value
			.filter((event) => selectedTarget == null || event.id === selectedTarget)
			.filter((event) => !position || event.timestamp.getTime() < position);
		if (markdownWidget.append) {
			return filteredMessages.map((event) => event.message).join('\n');
		} else {
			const groupedByTimestamp = groupBy(filteredMessages, ({ timestamp }) =>
				timestamp ? timestamp.getTime().toString() : '0',
			);
			const timestamps = Object.keys(groupedByTimestamp).sort();
			const maxTimestamp = timestamps[timestamps.length - 1];
			const lastBatch = groupedByTimestamp[maxTimestamp];
			return (lastBatch || []).map((event) => event.message).join('\n');
		}
	}, [markdownWidget, position, messages.value, selectedTarget]);

	const targets = useMemo(() => {
		if (!messages.value) {
			return [];
		}
		const targetIds = new Set(messages.value.map((event) => event.id));
		return Array.from(targetIds).map((id) => {
			const targetExecution = experimentExecution.lanes
				.flatMap((lane) => lane.steps)
				.filter((step) => step.type === 'action')
				.map((step) => step as ExperimentStepExecutionActionVO)
				.flatMap((step) => step.targetExecutions)
				.find((target) => target.id === id);
			return { id, label: targetExecution?.targetName || id };
		});
	}, [messages.value, experimentExecution]);

	useEffect(() => {
		if (selectedTarget == null && messages.value && messages.value.length > 0) {
			setSelectedTarget(messages.value[0].id);
		}
	}, [selectedTarget, setSelectedTarget, messages.value]);

	return (
		<ExperimentRunCard
			title={markdownWidget.title}
			additionalHeaderItems={
				<TargetSwitcher targets={targets} selectedTarget={selectedTarget} setSelectedTarget={setSelectedTarget} />
			}
		>
			<Box
				style={{
					p: 'small',
					overflowY: 'auto',
				}}
			>
				{!messages.value?.length ? (
					<Stack size={0} sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
						{liveUpdate || messages.loading ? (
							<LoadingContent loading={messages.loading}>Waiting for events...</LoadingContent>
						) : (
							<EmptyContent>No Events recorded.</EmptyContent>
						)}
					</Stack>
				) : (
					<>
						<PositionJumpBack position={position} onPositionSelect={onPositionSelect} append={markdownWidget.append} />
						<Markdown content={content} />
					</>
				)}
			</Box>
		</ExperimentRunCard>
	);
}

interface TargetSwitcherProps {
	targets: SingleChoiceListItem[];
	selectedTarget: string | null;
	setSelectedTarget: (target: string | null) => void;
}

function TargetSwitcher({ selectedTarget, targets, setSelectedTarget }: TargetSwitcherProps): ReactElement {
	if (!targets || targets.length <= 1) {
		return <></>;
	}

	return (
		<presets.dropdown.SingleChoiceButton
			selectedId={selectedTarget || undefined}
			maxCotentWidth="500px"
			placement="bottom-end"
			items={targets}
			size="small"
			onSelect={setSelectedTarget}
			style={{ maxWidth: '220px' }}
		>
			{targets.find((option) => option.id === selectedTarget)?.label || 'Select target'}
		</presets.dropdown.SingleChoiceButton>
	);
}

type PositionJumpBackProps = {
	position: ExperimentPlayerTimeStamp | null;
	onPositionSelect: (position: ExperimentPlayerTimeStamp | null) => void;
	append: boolean;
};

function PositionJumpBack({ position, onPositionSelect, append }: PositionJumpBackProps): React.ReactElement {
	if (position) {
		return (
			<Stack direction="horizontal" justifyContent="space-between" backgroundColor="neutral100" p="xSmall">
				<Stack direction="horizontal" size={'xSmall'}>
					<Text variant="mediumStrong">Showing content {append ? 'till' : 'at'}:</Text>
					<Text>{formatTime(new Date(position))}</Text>
				</Stack>
				<Link sx={{ ml: 'xSmall' }} onClick={() => onPositionSelect(null)}>
					<Text>
						Reset timestamp <IconClose variant={'xSmall'} />
					</Text>
				</Link>
			</Stack>
		);
	} else {
		return <></>;
	}
}
