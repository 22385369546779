/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { useInitGlobalPermissions } from 'services/useGlobalPermissions';
import { usePlatformAvailability } from 'utils/platformAvailability';
import { ModalContentV2, ModalOverlay, ModalV2 } from 'components';
import { useActiveTeam } from 'services/team/useActiveTeam';
import SplashScreen from 'pages/splashScreen/splashScreen';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { useActiveTenant } from 'tenancy/useActiveTenant';
import { loadingResult } from 'utils/hooks/stream/result';
import { LicensesContext } from 'services/licenseApi';
import { UnauthorizedError } from 'services/authApi';
import { usePromise } from 'utils/hooks/usePromise';
import { TenantContext } from 'tenancy/useTenant';
import { UserContext } from 'services/usersApi';
import { ReactElement, useEffect } from 'react';
import { OnboardingVO, TenantVO } from 'ui-api';
import { TeamContext } from 'services/useTeam';
import { Services } from 'services/services';
import { setTimeZone } from 'utils/dateFns';
import { Redirect } from 'url/router';
import App from 'App/App';

import Ops from './Ops';

export default function Init(): ReactElement {
	return <TenantHandler />;
}

function TenantHandler(): ReactElement {
	const tenant = useActiveTenant();

	if (tenant.error?.statusCode === 404 || tenant.value?.initialized === false) {
		return <Redirect to={'/preparation'} />;
	}

	const error = tenant.error;
	const loading = tenant.loading;

	// TS doesn't know that user.value is not nullable when loading & error are false, so we have to eliminate potential nullable values
	if (loading || error || !tenant.value) {
		return (
			<SplashScreen>
				{error && !(error instanceof UnauthorizedError) && error.statusCode != 401 && (
					<div>{error.message ?? error.toString()}</div>
				)}
			</SplashScreen>
		);
	}
	return <TeamAndLicenseHandler key={tenant.value.key} tenant={tenant.value} />;
}

interface TeamHandlerProps {
	tenant: TenantVO;
}

function TeamAndLicenseHandler({ tenant }: TeamHandlerProps): ReactElement {
	const team = useActiveTeam(tenant);

	const isPlatformAvailable = usePlatformAvailability();
	const [user] = useAsyncState(() => Services.users.getCurrentUser());

	const [onboarding, fetchOnboarding] = useAsyncState(
		async () =>
			tenant.initialized && user.value
				? Services.dashboard.fetchOnboardingStatus()
				: tenant.type === 'ONPREM'
					? { onboardingCompleted: true }
					: undefined,
		[tenant, user.value],
	);

	useEffect(() => {
		if (user.value?.timeZone) {
			setTimeZone(user.value?.timeZone);
		}
	}, [user.value?.timeZone]);

	const licensesResult = usePromise(() => Services.licenseApi.getActiveLicense(), []);

	const error = user.error || team.error || onboarding.error || licensesResult.error;
	const loading = user.loading || team.loading || onboarding.loading || licensesResult.loading;

	if (loading || error || !team.value || !user.value || !onboarding.value || licensesResult.value == null) {
		return (
			<SplashScreen>
				{error && !(error instanceof UnauthorizedError) && <div>{error.message ?? error.toString()}</div>}
			</SplashScreen>
		);
	}

	return (
		<TenantContext.Provider value={{ tenant: tenant }}>
			<UserContext.Provider value={{ user: user.value }}>
				<TeamContext.Provider value={{ team: team.value }}>
					<LicensesContext.Provider
						value={
							licensesResult.value.license
								? {
										...licensesResult.value.license,
										expires: licensesResult.value.expires,
										hasExpired: !licensesResult.value.expires || licensesResult.value.expires.getTime() < Date.now(),
									}
								: null
						}
					>
						<AppPermissionWrapper fetchOnboarding={fetchOnboarding} onboarding={onboarding.value} />
						{!isPlatformAvailable && (
							<ModalOverlay open centerContent>
								{() => (
									<ModalV2>
										<ModalContentV2>
											<Ops />
										</ModalContentV2>
									</ModalV2>
								)}
							</ModalOverlay>
						)}
					</LicensesContext.Provider>
				</TeamContext.Provider>
			</UserContext.Provider>
		</TenantContext.Provider>
	);
}

function AppPermissionWrapper({
	onboarding,
	fetchOnboarding,
}: {
	fetchOnboarding: () => void;
	onboarding: OnboardingVO;
}): ReactElement {
	const permissions = useInitGlobalPermissions();
	if (Object.is(permissions, loadingResult)) {
		return <SplashScreen />;
	}
	return <App key="app" fetchOnboarding={fetchOnboarding} onboarding={onboarding} />;
}
