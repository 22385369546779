/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { TextInput } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

import VariableHighlightingOrElse from './VariableHighlightingOrElse';

interface TextFieldWithVariableHighlightingProps {
	placeholder?: string;
	isEditMode: boolean;
	hasErrors?: boolean;
	withUnit?: boolean;
	disabled: boolean;
	value: string;
	onChange: (value: string) => void;
	onClick: () => void;
}

export default function TextFieldWithVariableHighlighting({
	placeholder,
	isEditMode,
	hasErrors,
	disabled,
	withUnit,
	value,
	onChange,
	onClick,
}: TextFieldWithVariableHighlightingProps): ReactElement {
	return (
		<VariableHighlightingOrElse
			isEditMode={isEditMode}
			hasErrors={hasErrors}
			disabled={disabled}
			withUnit={withUnit}
			value={value}
			onClick={onClick}
		>
			<TextInput
				placeholder={placeholder}
				highlighted={isEditMode}
				errored={hasErrors}
				disabled={disabled}
				value={value}
				onChangeOnBlur={onChange}
				data-cy="text-field-with-highlighting"
			/>
		</VariableHighlightingOrElse>
	);
}
