/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { TeamSummaryVO } from 'ui-api';

interface Props {
	myTeamsOnly?: boolean;
	includeAny?: boolean;
}

export default function useTeams({
	includeAny,
	myTeamsOnly,
}: Props): [TeamSummaryVO[], TeamSummaryVO[], TeamSummaryVO[]] {
	const teamsResult = usePromise(() => Services.teams.findTeams('', myTeamsOnly), [myTeamsOnly]);
	const allTeams = teamsResult.value ?? [];
	const myTeams = allTeams.filter((t) => t.userIsTeamMember);
	const otherTeams = allTeams.filter((t) => !t.userIsTeamMember);
	if (includeAny) {
		myTeams.unshift({
			id: '',
			name: 'Any',
			key: '',
			logoId: '',
			created: '',
			logoColor: '',
			userIsTeamMember: false,
		});
	}

	return [allTeams, myTeams, otherTeams];
}
