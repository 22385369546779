/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { wrapWithEnvironmentVariableMarkers, wrapWithPlaceholderMarkers } from 'pages/templates/utils';
import { Dropdown, Flex, presets, TextWithHighlighting } from '@steadybit/ui-components-lib';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { TemplatePlaceholderVO, VariableVO } from 'ui-api';
import { CSSProperties, ReactElement, Ref } from 'react';
import useFeatureFlag from 'services/useFeatureFlag';
import { mergeAndSort } from 'utils/envVars';
import { useField } from 'formik';

import TextFieldWithVariableHighlighting from './TextFieldWithVariableHighlighting';
import VariablesAndPlaceholders from './VariablesAndPlaceholders';
import FocussableWrapper from './FocussableWrapper';

interface TextProps {
	placeholder?: string;
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function Text({ value = '', setValue, ...props }: TextProps): ReactElement {
	const isExperimentalInput = useFeatureFlag('experiment_input_syntax_highlighting');
	const { mode: editorMode } = useEditorSettings();

	const [{ value: placeholders = [] }] = useField<TemplatePlaceholderVO[]>('placeholders');
	const [{ value: environmentVariables = [] }] = useField<VariableVO[]>('variables');
	const [{ value: experimentVariables = [] }] = useField<VariableVO[]>('experimentVariables');

	if (isExperimentalInput) {
		const environmentAndExperimentVariables = mergeAndSort(environmentVariables, experimentVariables);
		const chunkLUT: Record<string, string> = {};
		environmentAndExperimentVariables.forEach((variable) => {
			if (variable.value) {
				chunkLUT[wrapWithEnvironmentVariableMarkers(variable.key)] = variable.value;
			}
		});
		placeholders.forEach((placeholder) => {
			if (placeholder.name) {
				chunkLUT[wrapWithPlaceholderMarkers(placeholder.key)] = placeholder.name;
			}
		});

		return (
			<Flex spacing="xxSmall">
				<Input value={value} onChange={setValue} {...props} />

				<Dropdown<HTMLInputElement>
					renderDropdownContent={({ width, close }) => (
						<presets.dropdown.DropdownContentFrame width={width}>
							<VariablesAndPlaceholders
								width={width}
								selectItem={(v) => {
									setValue(v);
									close();
								}}
							/>
						</presets.dropdown.DropdownContentFrame>
					)}
				>
					{({ setRefElement, isOpen, setOpen }) => {
						return (
							<TextWithHighlighting
								ref={setRefElement}
								mode={editorMode === 'experiment' ? 'variables' : 'variables-and-placeholders'}
								placeholder={props.placeholder}
								errored={props.hasErrors}
								highlighted={isOpen}
								chunkLUT={chunkLUT}
								value={value}
								onChange={setValue}
								onClick={() => setOpen(true)}
								onFocus={() => {
									setOpen(true);
								}}
							/>
						);
					}}
				</Dropdown>
			</Flex>
		);
	}

	return <Input value={value} onChange={setValue} {...props} />;
}

interface InputProps {
	placeholder?: string;
	hasErrors?: boolean;
	sx?: CSSProperties;
	disabled: boolean;
	value: string;
	onChange: (value: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
}

export function Input({
	placeholder,
	hasErrors,
	disabled,
	value,
	onChange,
	onFocus,
	onBlur,
}: InputProps): ReactElement {
	return (
		<Dropdown<HTMLDivElement>
			renderDropdownContent={({ width, close }) => (
				<presets.dropdown.DropdownContentFrame width={width}>
					<VariablesAndPlaceholders
						width={width}
						selectItem={(v) => {
							onChange(v);
							close();
						}}
					/>
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<FocussableWrapper
						ref={setRefElement as Ref<HTMLDivElement>}
						disabled={disabled}
						onFocus={() => {
							setOpen(true);
							onFocus?.();
						}}
						onBlur={() => onBlur?.()}
					>
						<TextFieldWithVariableHighlighting
							placeholder={placeholder}
							value={String(value)}
							hasErrors={hasErrors}
							isEditMode={isOpen}
							disabled={disabled}
							onClick={() => setOpen(true)}
							onChange={onChange}
						/>
					</FocussableWrapper>
				);
			}}
		</Dropdown>
	);
}
