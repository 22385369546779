/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	DockerInstallationContent,
	KubernetesInstallationContent,
	LinuxInstallationContent,
} from 'pages/settings/agents/agentSetup';
import { IconComponent, IconDocker, IconKubernetes, IconLinux } from 'components/icons';
import Collapsible from 'components/Collapsible.v2/Collapsible.v2';
import { Container, Heading, Stack, Text } from 'components';
import { ReactElement, ReactNode, useEffect } from 'react';
import { ampli } from 'ampli';

import InfoBox, { ExternalLink } from './InfoBox';

export default function PageAgentInstallation({ agentsAvailable }: { agentsAvailable: boolean }): ReactElement {
	useEffect(() => {
		ampli.agentSetupInstallationViewed();
	}, []);

	return (
		<Stack pt="xLarge" px="xxLarge">
			<Heading variant="xLarge">Which Technology do you want to use?</Heading>

			<InfoBox>
				<Text variant="small" color="purple800">
					Install at least one agent coming with default extensions to kick start your Chaos Engineering.
				</Text>
				<Text variant="small" color="purple800">
					Watch our guide about why you need to install an agent and extensions, or learn more about it in our
					documentation.
				</Text>

				<Stack direction="horizontal" alignItems="center" size="medium" mt="xSmall">
					<ExternalLink label="Watch our Video" href="https://www.youtube.com/watch?v=z8DigsT3fR0&feature=youtu.be" />
					<ExternalLink
						label="Learn more in our Documentation"
						href="https://docs.steadybit.com/install-and-configure/"
					/>
				</Stack>
			</InfoBox>

			<AgentInstallCollapsibled agentsAvailable={agentsAvailable} />
			<Container height={72} />
		</Stack>
	);
}

export function AgentInstallCollapsibled({ agentsAvailable }: { agentsAvailable: boolean }): ReactElement {
	return (
		<>
			<Collapse Icon={IconKubernetes} title="Kubernetes">
				<WithTechTracking technology="kubernetes_helm">
					<KubernetesInstallationContent makeCopyButtonPrimary={!agentsAvailable} />
				</WithTechTracking>
			</Collapse>
			<Collapse Icon={IconDocker} title="Docker">
				<WithTechTracking technology="docker">
					<DockerInstallationContent makeCopyButtonPrimary={!agentsAvailable} />
				</WithTechTracking>
			</Collapse>
			<Collapse Icon={IconLinux} title="Linux Host">
				<WithTechTracking technology="linux">
					<LinuxInstallationContent makeCopyButtonPrimary={!agentsAvailable} />
				</WithTechTracking>
			</Collapse>
		</>
	);
}

interface CollapseProps {
	Icon: IconComponent;
	children: ReactNode;
	title: ReactNode;
}

function Collapse({ Icon, title, children }: CollapseProps): ReactElement {
	return (
		<Collapsible
			title={
				<Stack direction="horizontal" alignItems="center">
					<Icon mr="xSmall" />
					{title}
				</Stack>
			}
			backgroundColor="neutral000"
		>
			{children}
		</Collapsible>
	);
}

interface WithTechTrackingProps {
	children: ReactNode;
	technology: 'kubernetes_helm' | 'docker' | 'linux';
}

function WithTechTracking({ children, technology }: WithTechTrackingProps): ReactElement {
	useEffect(() => {
		ampli.agentSetupInstallationTechnologyChosen({
			agent_installation_technology: technology,
		});
	}, []);
	return <>{children}</>;
}
