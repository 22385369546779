/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { ReactElement, ReactNode } from 'react';
import { Container } from 'components';

interface ModalContentProps {
	children: ReactNode | ReactNode[];
}

export default function ModalContent({ children }: ModalContentProps): ReactElement {
	return (
		<Container className="modalContent" px="xxxLarge" overflowX="auto">
			<ErrorBoundary>{children}</ErrorBoundary>
		</Container>
	);
}
