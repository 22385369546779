/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Button, Container, FormikTextField, ModalContentV2, ModalV2, Stack, Text } from 'components';
import { Flex } from '@steadybit/ui-components-lib';
import { Services } from 'services/services';
import { Form, Formik } from 'formik';
import React from 'react';

import { Brand } from '../../images';

const Login: React.VFC = () => {
	const [error, setError] = React.useState<Error | null>();

	const handleSubmit = async (values: { username: string; password: string }): Promise<void> => {
		try {
			setError(null);
			await Services.auth.login(values.username, values.password);
		} catch (e) {
			setError(e);
		}
	};

	const initialValue = process.env.REACT_APP_TEST_LOGIN_AUTOFILL
		? { username: 'admin', password: 'admin' }
		: { username: '', password: '' };

	return (
		<Container
			display={'flex'}
			sx={{
				position: 'fixed',
				top: 0,
				right: 0,
				left: 0,
				bottom: 0,
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Formik initialValues={initialValue} onSubmit={handleSubmit}>
				{({ isSubmitting }) => (
					<ModalV2 width={480}>
						<Form>
							<ModalContentV2>
								<Stack my={'medium'}>
									<Container m={'medium'}>
										<Brand color="#0F054C" />
									</Container>
									<Flex align="spread" spacing="xSmall">
										<FormikTextField
											size={'large'}
											placeholder={'Username'}
											autoComplete={'username'}
											name={'username'}
											data-private
										/>
										<FormikTextField
											size={'large'}
											placeholder={'Password'}
											autoComplete={'current-password'}
											name={'password'}
											password
										/>
										<Text color={'coral'}>{error ? error.toString() : <>&nbsp;</>}</Text>
									</Flex>
									<Button width={'100%'} variant={'primaryLarge'} type={'submit'} loading={isSubmitting}>
										Log in
									</Button>
								</Stack>
							</ModalContentV2>
						</Form>
					</ModalV2>
				)}
			</Formik>
		</Container>
	);
};

export default Login;
