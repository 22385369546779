/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { presets } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';
import { OptionVO } from 'ui-api';

interface MultiSelectProps {
	options: OptionVO[];
	disabled: boolean;
	value: string[];
	setValue: (value: string[]) => void;
}

export default function MultiSelect({ value = [], disabled, options, setValue }: MultiSelectProps): ReactElement {
	// there seem to be cases where the experiment contains a string instead of an array or even undefined, so correct the value here
	const selectedIds = !value ? [] : typeof value === 'string' ? [value] : value;

	return (
		<presets.dropdown.MultiChoiceButton
			items={options.map((o) => ({
				label: o.label,
				id: o.value,
			}))}
			selectedIds={selectedIds}
			disabled={disabled}
			placeholder="No selection"
			onCheck={(id) => setValue([...selectedIds, id])}
			onUncheck={(id) => setValue(selectedIds.filter((i) => i !== id))}
		/>
	);
}
