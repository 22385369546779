/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import VariablesAndPlaceholders from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Controls/VariablesAndPlaceholders';
import GroupedLabels from 'components/Select/Dropdown/presets/GroupedLabels';
import { Dropdown, TextInput, presets } from '@steadybit/ui-components-lib';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Group } from 'components/Select/Dropdown/presets/types';
import { Divider, LoadingIndicator } from 'components';
import { getCategoryLabel } from 'services/targetsApi';
import { groupBy } from 'lodash';

interface DropdownInputFilterableProps {
	attributeKeysLoading: boolean;
	attributeKeys: string[];
	autoFocus?: boolean;
	disabled: boolean;
	hasError: boolean;
	small: boolean;
	value: string;
	onValueChanged: (value: string) => void;
	onItemSelected?: () => void;
}

export default function DropdownInputFilterable({
	attributeKeysLoading,
	attributeKeys,
	autoFocus,
	hasError,
	disabled,
	small,
	value,
	onValueChanged,
	onItemSelected,
}: DropdownInputFilterableProps): ReactElement {
	const groups = useMemo(() => getGroups(attributeKeys), [attributeKeys]);

	// This logic avoids that one clicks on a prefilled input field and is facing an already filtered list
	const [queryHasChanged, setQueryHasChanged] = useState(false);
	const [queryString, setQueryString] = useState('');
	useEffect(() => {
		if (queryHasChanged) {
			setQueryString(value);
		}
		if (value !== queryString && !queryHasChanged) {
			setQueryHasChanged(true);
		}
	}, [value]);

	return (
		<Dropdown<HTMLInputElement>
			placement="bottom-start"
			renderDropdownContent={({ close }) => {
				if (attributeKeysLoading) {
					return (
						<presets.dropdown.DropdownContentFrame width="340px">
							<LoadingIndicator variant="medium" color="slate" sx={{ ml: 'xSmall', my: 'xxSmall' }} />
						</presets.dropdown.DropdownContentFrame>
					);
				}
				return (
					<presets.dropdown.DropdownContentFrame maxHeight="240px">
						<GroupedLabels
							onSelect={({ label }) => {
								onValueChanged(label);
								onItemSelected?.();
								close();
							}}
							groups={groups}
							queryString={queryString}
							width={340}
						/>
						<Divider />
						<VariablesAndPlaceholders
							width={340}
							selectItem={(v) => {
								onValueChanged(v);
								onItemSelected?.();
								close();
							}}
						/>
					</presets.dropdown.DropdownContentFrame>
				);
			}}
		>
			{({ setRefElement, setOpen }) => {
				return (
					<TextInput
						ref={setRefElement}
						size={small ? 'small' : 'medium'}
						placeholder="Target Attribute"
						autoFocus={autoFocus}
						disabled={disabled}
						autoComplete="off"
						errored={hasError}
						value={value}
						onClick={() => setOpen(true)}
						onFocus={() => setOpen(true)}
						onChange={setQueryString}
						onKeyDown={(e) => {
							if (e.key === 'Tab') {
								setOpen(false);
							}
						}}
					/>
				);
			}}
		</Dropdown>
	);
}

function getGroups(attributeKeys: string[]): Group[] {
	const byCategory = Object.entries(groupBy(attributeKeys, getCategory));
	return byCategory.map(([category, keys]) => ({
		label: getCategoryLabel(category),
		options: keys.map((key) => ({
			label: key,
			value: key,
		})),
	}));
}

function getCategory(key: string): string {
	if (key.startsWith('k8s.pod.label')) {
		return 'k8s.pod.label';
	}
	const [category] = key.split('.', 1);
	return category ?? key;
}
