/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useGlobalNotifications } from 'services/useGlobalNotifications';
import { Flex, Grid, Icon, Text } from '@steadybit/ui-components-lib';
import { NotificationKeyVO } from 'ui-api';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import getContent, { Content } from './notificationContentMap';

interface NotificationsProps {
	types: NotificationKeyVO[];
}

interface NotificationProps {
	content: Content;
}

export default function Notifications({ types }: NotificationsProps): ReactElement | null {
	const notifications = useGlobalNotifications(types);
	if (!notifications || notifications.length === 0) {
		return null;
	}

	if (notifications.length === 1) {
		return <Notification content={getContent(notifications[0])} />;
	}

	return (
		<Flex spacing="xSmall">
			{notifications.map((notification, index) => (
				<Notification key={index} content={getContent(notification)} />
			))}
		</Flex>
	);
}

export function Notification({ content }: NotificationProps): ReactElement {
	const primaryColor =
		content.level === 'error'
			? theme.colors.coral
			: content.level === 'warning'
				? theme.colors.experimentWarning
				: theme.colors.neutral600;

	const secondaryColor =
		content.level === 'error'
			? theme.colors.coral100
			: content.level === 'warning'
				? theme.colors.yellowLight
				: theme.colors.neutral200;

	return (
		<Grid
			cols="32px 1fr"
			style={{
				width: '100%',
				padding: '16px',
				borderLeft: `4px solid ${primaryColor}`,
				borderRadius: 'xxSmall',
				backgroundColor: secondaryColor,
			}}
		>
			<Icon
				size="medium"
				type={content.level === 'error' ? 'error' : content.level === 'warning' ? 'warning' : 'info'}
				style={{
					color: primaryColor,
				}}
			/>

			<Flex spacing="small" style={{ pt: 'xxxSmall' }}>
				<Flex spacing="none">
					{content.title && (
						<Text
							type="smallStrong"
							style={{
								color: primaryColor,
							}}
						>
							{content.title}
						</Text>
					)}

					<Text
						type="small"
						style={{
							color: theme.colors.neutral700,
						}}
					>
						{content.message}
					</Text>
				</Flex>
			</Flex>
		</Grid>
	);
}
