/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconChevronDoubleLeft, IconChevronDoubleRight, IconClose, IconExperiment } from 'components/icons';
import { LandscapeConfig, isLandscapeGroupWithTargets } from 'targets/Explore/types';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { emit, event$ } from 'targets/Explore/ServiceLocator';
import { getSharedAttributes } from 'targets/Explore/utils';
import { Button, Container, Stack } from 'components';
import { LandscapeViewGroupConfigVO } from 'ui-api';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { ampli } from 'ampli';

import {
	LayoutedGroup,
	LayoutedTarget,
	isLayoutedGroupWithSubgroups,
	isLayoutedTarget,
	isSelectEntity,
} from '../types';
import { UseAsExperimentModal } from './UseAsExperimentModal';
import { TabSelector } from './TabSelector';
import Breadcrumbs from './Breadcrumbs';
import Attributes from './Attributes';
import GroupTable from './GroupTable';
import Targets from './Targets';
import { Tab } from './types';

export default function SelectedEntitySidebar(): ReactElement | null {
	const [expanded, setExpanded] = useState(false);
	const [selectedEntity, setSelectedEntity] = useState<LayoutedGroup | LayoutedTarget | null>(null);
	const isTarget = isLayoutedTarget(selectedEntity);
	const [showModal, setShowModal] = useState(false);

	const formik = useFormikContext<LandscapeConfig>();
	const { filterQuery, groupBy } = formik.values;

	useEffect(() => {
		const subscription = event$().subscribe((event) => {
			if (isSelectEntity(event)) {
				setSelectedEntity(event.entity);
			}
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [event$]);

	if (!selectedEntity) {
		return null;
	}

	const group = isTarget ? selectedEntity.parent : selectedEntity;
	const query = getGroups([], isTarget ? selectedEntity.parent : selectedEntity)
		.map(({ group }, i) => getGroupPredicate(groupBy[i], group.label, formik.values.groupConfigs[group.depth]))
		.filter(Boolean)
		.join(' AND ');

	return (
		<>
			{showModal && (
				<UseAsExperimentModal onClose={() => setShowModal(false)} filterQuery={filterQuery} query={query} />
			)}

			<Stack
				size="none"
				sx={{
					position: 'absolute',
					top: 12,
					right: 12,
					bottom: 12,
					width: expanded ? 'calc( 100vw - 566px )' : '478px',

					bg: 'neutral000',
					borderRadius: 8,
					overflow: 'hidden',
				}}
			>
				<SidebarContent
					onUseAsExperimentClicked={() => setShowModal(true)}
					target={isTarget ? selectedEntity : undefined}
					selectedEntity={selectedEntity}
					filterQuery={filterQuery}
					setExpanded={setExpanded}
					expanded={expanded}
					group={group}
					query={query}
				/>
			</Stack>
		</>
	);
}

interface SidebarContentProps {
	selectedEntity: LayoutedGroup | LayoutedTarget;
	setExpanded: (expanded: boolean) => void;
	onUseAsExperimentClicked: () => void;
	target?: LayoutedTarget;
	group: LayoutedGroup;
	filterQuery: string;
	expanded: boolean;
	query: string;
}

function SidebarContent({
	onUseAsExperimentClicked,
	selectedEntity,
	setExpanded,
	filterQuery,
	expanded,
	target,
	group,
	query,
}: SidebarContentProps): ReactElement {
	const isTarget = isLayoutedTarget(selectedEntity);
	const [selectedTab, setSelectedTab] = useState<Tab>('targets');

	useEffect(() => {
		setSelectedTab('targets');
	}, [target]);

	const sharedAttributes = useMemo(
		() => getSharedAttributes(group.group).sort((a, b) => a.key.localeCompare(b.key)),
		[group.group.id],
	);

	useEffect(() => {
		if (isTarget) {
			ampli.landscapeExplorerTargetDetailsViewed();
		} else {
			ampli.landscapeExplorerGroupDetailsViewed({ landscape_explorer_subview: selectedTab });
		}
	}, [isTarget ? selectedEntity.target.id : selectedEntity.group.id, selectedTab]);

	return (
		<>
			<Container display="flex" justifyContent="space-between" alignItems="center" mt="small" px="small">
				<SidebarButton onClick={() => setExpanded(!expanded)}>
					{expanded ? <IconChevronDoubleRight /> : <IconChevronDoubleLeft />}
				</SidebarButton>

				<SidebarButton onClick={() => emit({ type: 'selectEntity', entity: null })}>
					<IconClose />
				</SidebarButton>
			</Container>

			<Breadcrumbs
				entity={target ?? group}
				onBreadcrumbClicked={(entity) => {
					emit({ type: 'selectEntity', entity });
				}}
				expanded={expanded}
			/>

			{isLayoutedGroupWithSubgroups(group) ? (
				<Container
					sx={{
						mx: 'small',
						mb: 'small',
						p: 'xSmall',
						border: '1px solid ' + theme.colors.neutral300,
						overflowX: 'hidden',
						overflowY: 'auto',
					}}
				>
					<GroupTable
						onGroupSelected={(g) => emit({ type: 'selectEntity', entity: g })}
						groups={group.layoutedGroups}
						expanded={expanded}
					/>
				</Container>
			) : (
				<Stack
					sx={{
						mx: 'small',
						pb: 'small',
						mb: 'small',
						borderRadius: '4px',
						border: '1px solid ' + theme.colors.neutral300,
						overflowY: 'auto',
					}}
				>
					<TabSelector
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
						numSharedAttributes={sharedAttributes.length}
						numTargets={isLandscapeGroupWithTargets(group.group) ? group.group.totalUniqueTargets || 0 : 0}
					/>

					<Stack px="xSmall">
						{selectedTab === 'shared_attributes' ? (
							<Attributes attributes={sharedAttributes} attributeType="target_group_shared_attributes" />
						) : (
							<Targets
								key={group.group.id}
								expanded={expanded}
								targets={group.layoutedTargets}
								selectedTargetId={target?.target.id}
							/>
						)}

						{(filterQuery || query) && (
							<Button
								variant="primarySmall"
								width="fit-content"
								onClick={onUseAsExperimentClicked}
								sx={{ alignSelf: 'center' }}
							>
								<IconExperiment mr="xSmall" ml="-xxSmall" />
								Use Targets in an Experiment
							</Button>
						)}
					</Stack>
				</Stack>
			)}
		</>
	);
}

function SidebarButton({ children, onClick }: { children: ReactElement; onClick: () => void }): ReactElement {
	return (
		<Button
			variant="chromeless"
			onClick={(e) => {
				e.stopPropagation();
				onClick();
			}}
		>
			{children}
		</Button>
	);
}

function getGroups(groups: LayoutedGroup[], group: LayoutedGroup): LayoutedGroup[] {
	if (!group) {
		return groups;
	}
	if (group.parent) {
		return getGroups(groups, group.parent).concat(group);
	}
	return groups.concat(group);
}

function getGroupPredicate(key: string, value: string, groupingConfig?: LandscapeViewGroupConfigVO): string | null {
	if (groupingConfig) {
		const matchedMapping = groupingConfig.mappings.find((mapping) => mapping.groupName === value);
		if (matchedMapping && matchedMapping.attributeValues.length > 0) {
			return `(${matchedMapping.attributeValues.map((a) => `"${key}"="${a}"`).join(' OR ')})`;
		}
	}

	if (key) {
		return value === 'unknown' ? `"${key}"!~""` : `"${key}"="${value}"`;
	}
	return null;
}
