/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { forwardRef, ReactElement } from 'react';

interface FocussableWrapperProps {
	children: ReactElement;
	disabled: boolean;
	onFocus: () => void;
	onBlur: () => void;
}

const FocussableWrapper = forwardRef<HTMLDivElement, FocussableWrapperProps>(
	({ children, disabled, onFocus, onBlur }, ref) => {
		return (
			<div
				ref={ref}
				onFocus={() => {
					if (!disabled) {
						onFocus();
					}
				}}
				onBlur={() => {
					if (!disabled) {
						onBlur();
					}
				}}
				style={{ width: '100%' }}
			>
				{children}
			</div>
		);
	},
);

FocussableWrapper.displayName = 'FocussableWrapper';
export default FocussableWrapper;
