/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { TemplatePlaceholderOrVariableRegexKeepingBrackets } from 'utils/templates';
import { Pill, smellsLikeEnvVar, smellsLikeTemplatePlaceholder } from 'components';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { EnvVarRegexKeepingBrackets, mergeAndSort } from 'utils/envVars';
import { Text, Tooltip } from '@steadybit/ui-components-lib';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { TemplatePlaceholderVO, VariableVO } from 'ui-api';
import { ReactElement } from 'react';
import { useField } from 'formik';

interface ChunkProps {
	chunks?: Chunk[];
	value: string;
}

export default function Chunks({ chunks: externalChunks, value = '' }: ChunkProps): ReactElement {
	const { mode } = useEditorSettings();
	const chunks = (externalChunks ?? mode === 'experiment') ? getVariableChunks(value) : getTemplateChunks(value);

	return (
		<div style={{ ...textEllipsis }}>
			{chunks.map((chunk, index) => (
				<Chunk key={index} chunk={chunk} />
			))}
		</div>
	);
}

function Chunk({ chunk }: { chunk: Chunk }): ReactElement {
	const { mode } = useEditorSettings();
	if (mode !== 'experiment') {
		if (chunk.type === 'variable') {
			return (
				<HighlightedChunk tooltip={chunk.chunk} background="purple200" color="purple800">
					{chunk.chunk}
				</HighlightedChunk>
			);
		}
		if (chunk.type === 'placeholder') {
			return <Placeholder placeholder={chunk.chunk} />;
		}
		return <TextChunk chunk={chunk.chunk} />;
	}

	if (chunk.type === 'variable') {
		return <Variable variableKey={chunk.chunk} />;
	}
	return <TextChunk chunk={chunk.chunk} />;
}

function Variable({ variableKey }: { variableKey: string }): ReactElement {
	const [{ value: environmentVariables = [] }] = useField<VariableVO[]>({ name: 'variables' });
	const [{ value: experimentVariables = [] }] = useField<VariableVO[]>('experimentVariables');
	const environmentAndExperimentVariables = mergeAndSort(environmentVariables, experimentVariables);

	const resolvedVariable = environmentAndExperimentVariables.find((variable) => `{{${variable.key}}}` === variableKey);
	const isResolved = resolvedVariable && resolvedVariable.value ? true : false;

	return (
		<HighlightedChunk
			tooltip={variableKey}
			background={isResolved ? 'slateMidLight40p' : 'coral200'}
			color={isResolved ? 'neutral800' : 'coral'}
		>
			{resolvedVariable && resolvedVariable.value ? resolvedVariable.value : variableKey}
		</HighlightedChunk>
	);
}

function Placeholder({ placeholder }: { placeholder: string }): ReactElement {
	const { mode: editorMode } = useEditorSettings();

	const cleanedPlaceholder = placeholder.substring(2, placeholder.length - 2);
	const [{ value: placeholderValuesMap = new Map() }] = useField<Map<string, string>>({ name: 'placeholderValuesMap' });
	const [{ value: placeholders = [] }] = useField<TemplatePlaceholderVO[]>({ name: 'placeholders' });

	const content =
		editorMode === 'templateUsage'
			? placeholderValuesMap.get(cleanedPlaceholder) ||
				placeholders.find((p) => p.key === cleanedPlaceholder)?.name ||
				placeholder
			: placeholder;

	return (
		<HighlightedChunk background="aqua300" tooltip={content} color="aqua800">
			{content}
		</HighlightedChunk>
	);
}

function HighlightedChunk({
	background,
	children,
	tooltip,
	color,
}: {
	background: string;
	children: string;
	tooltip: string;
	color: string;
}): ReactElement {
	return (
		<div style={{ display: 'inline-flex', width: 'fit-content' }}>
			<Pill
				backgroundColor={background}
				color={color}
				sx={{
					py: '4px',
					width: 'fit-content',
					fontSize: '13px',
					fontWeight: '500',
				}}
			>
				<TextChunk chunk={children} tooltip={tooltip} />
			</Pill>
		</div>
	);
}

type ChunkType = 'variable' | 'placeholder' | 'text';

interface Chunk {
	type: ChunkType;
	chunk: string;
}

// exported for testing
export function getVariableChunks(value: string): Chunk[] {
	return getChunks(value, EnvVarRegexKeepingBrackets);
}

// exported for testing
export function getTemplateChunks(value: string): Chunk[] {
	return getChunks(value, TemplatePlaceholderOrVariableRegexKeepingBrackets);
}

function getChunks(value: string, regex: RegExp): Chunk[] {
	const matches = value.match(regex);
	if (!matches || matches.length === 0) {
		return [{ chunk: value, type: 'text' }];
	}

	const chunks = [];
	let lastMatchIndex = 0;
	for (let i = 0; i < matches.length; i++) {
		const match = matches[i];
		const matchIndex = value.indexOf(match, lastMatchIndex);
		const chunk = value.substring(lastMatchIndex, matchIndex);
		chunks.push(chunk);
		chunks.push(match);
		lastMatchIndex = matchIndex + match.length;
	}
	const lastChunk = value.substring(lastMatchIndex);
	if (lastChunk) {
		chunks.push(lastChunk);
	}
	return chunks.filter(Boolean).map((chunk) => ({ chunk, type: getChunkType(chunk) }));
}

function getChunkType(chunk: string): ChunkType {
	if (smellsLikeEnvVar(chunk)) {
		return 'variable';
	}
	if (smellsLikeTemplatePlaceholder(chunk)) {
		return 'placeholder';
	}
	return 'text';
}

function TextChunk({ chunk, tooltip }: { chunk: string; tooltip?: string }): ReactElement {
	return (
		<Tooltip content={tooltip || chunk} maxWidth="500px">
			{({ setRefElement }) => (
				<Text ref={setRefElement} type="small" textEllipsis style={{ lineHeight: '16px' }}>
					{chunk}
				</Text>
			)}
		</Tooltip>
	);
}
