/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	ButtonIcon,
	Container,
	LoadingIndicator,
	RouterButton,
	RouterPagination,
	Snackbar,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	Text,
	Tooltip,
} from 'components';
import { Colors, IconType, Pill, presets, TextInput } from '@steadybit/ui-components-lib';
import { IconArrowRight, IconEdit, IconLogfile } from 'components/icons';
import React, { ReactElement, ReactNode, useState } from 'react';
import { hasExpired, useLicense } from 'services/licenseApi';
import { AgentVO, GetAgentsPageResponse } from 'ui-api';
import useAgentReport from 'utils/hooks/useAgentReport';
import { AsyncState } from 'utils/hooks/useAsyncState';
import { PageLocation } from 'utils/hooks/usePage';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { setParamValue } from 'url/utils';
import { isUserAdmin } from 'utils/user';
import { useDebounce } from 'react-use';
import { useHistory } from 'url/hooks';
import { ampli } from 'ampli';

import { isSteadybitOrPartnerUser } from '../../../tracking/ignoredEmailAddresses';
import invokePromise from '../../../utils/ignorePromise';
import { useUser } from '../../../services/usersApi';

const AgentList: React.VFC<{ agents: AsyncState<GetAgentsPageResponse | undefined>; page: PageLocation }> = ({
	agents,
	page,
}) => {
	const { createHref } = useHistory();
	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);
	const user = useUser();
	const showDeregister = isSteadybitOrPartnerUser(user) || process.env.NODE_ENV === 'development';

	const error = agents.error;
	const isLoading = agents.loading && !agents.value;
	const numAgents = agents.value?.totalElements ?? 0;

	const { someAgentHasReportedInThePast } = useAgentReport();
	const license = useLicense();
	const hasLicenseExpired = hasExpired(license);

	return (
		<Container>
			{error && <Text>Error loading Agents: {error.message}</Text>}
			{isLoading && (
				<Stack alignItems="center" justifyContent="center">
					<LoadingIndicator variant="xLarge" color="slate" />
				</Stack>
			)}
			{agents.value && numAgents > 0 && (
				<Stack minHeight={'100%'} size={'large'}>
					<AgentTable showDeregister={showDeregister}>
						{agents.value.content.map((agent) => (
							<AgentRow showDeregister={showDeregister} key={agent.id} value={agent} actions={agents.value?._actions} />
						))}
						{!agents.value.content.length && (
							<TableRow>
								<TableDataCell colSpan={6}>
									<Text muted>No Agents found.</Text>
								</TableDataCell>
							</TableRow>
						)}
					</AgentTable>
					<RouterPagination
						activePage={page.pageParams.page}
						totalPages={agents.value?.totalPages}
						to={(i) => page.withPage(i).toString()}
					/>
				</Stack>
			)}
			{agents.value && numAgents === 0 && (
				<Stack size="large" alignItems="center" py="xLarge">
					<Stack
						size="none"
						display="flex"
						alignItems="center"
						justifyContent="center"
						textAlign="center"
						color="neutral700"
					>
						<Text>There are no Agents installed.</Text>
						<Text>{getAgentContinueText(someAgentHasReportedInThePast, isAdmin)}</Text>
					</Stack>
					{isAdmin && !someAgentHasReportedInThePast && (
						<Tooltip
							content={
								hasLicenseExpired
									? 'You cannot resume the agent setup because your Steadybit license has expired.'
									: undefined
							}
						>
							<RouterButton
								color="neutral000"
								disabled={hasLicenseExpired}
								to={createHref((location) => {
									location.pathname = '/onboarding';
									setParamValue(
										location,
										{
											pathSegment: '/onboarding',
											name: 'page',
										},
										1,
									);
								})}
								onClick={() => {
									ampli.agentSetupResumed();
								}}
							>
								Resume Agent Setup
								<IconArrowRight ml="xSmall" />
							</RouterButton>
						</Tooltip>
					)}
				</Stack>
			)}
		</Container>
	);
};

const getAgentContinueText = (agentSetupCompleted: boolean, isAdmin: boolean): string => {
	if (agentSetupCompleted) {
		return isAdmin ? 'Please add an agent.' : 'Please reach out to your admin to install an agent.';
	} else {
		return isAdmin
			? 'Please continue by resuming the Agent Setup.'
			: 'Please reach out to your admin and ask to resume the Agent Setup.';
	}
};

export const AgentFilters: React.VFC<{ page: PageLocation }> = ({ page }) => {
	const history = useHistory();
	const [query, setQuery] = useState<string>(page.criteria.get('query') || '');
	useDebounce(
		() => {
			if (query) {
				ampli.agentsListFiltered({ filter_text: query });
			}
			history.replace(page.withCriterion('query', query).toString());
		},
		350,
		[query],
	);

	return (
		<TextInput
			placeholder={'Search agent'}
			withLeftIcon="search"
			value={query}
			onChange={setQuery}
			style={{ maxWidth: '300px' }}
		/>
	);
};

function AgentTable({ showDeregister, children }: { showDeregister: boolean; children: ReactNode }): ReactElement {
	return (
		<Table width={'100%'} data-cy="agent-table">
			<TableHead>
				<TableRow>
					<TableHeadCell width={'auto'}>Identifier</TableHeadCell>
					<TableHeadCell width={150}>Type</TableHeadCell>
					<TableHeadCell width={150}>Version</TableHeadCell>
					<TableHeadCell width={'auto'}>Log Level</TableHeadCell>
					<TableHeadCell width={50} />
					{showDeregister ? <TableHeadCell width={180} /> : null}
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
}

function TypeIcon({ type }: { type: string }): ReactElement {
	const iconType: IconType | undefined =
		type === 'container' ? 'container' : type === 'kubernetes' ? 'kubernetes' : type === 'host' ? 'host' : undefined;
	return (
		<Pill
			withLeftIcon={iconType}
			style={{
				height: 20,
				padding: '0 6px',
				borderRadius: 'xxSmall',
			}}
		>
			{type}
		</Pill>
	);
}

const AgentRow: React.VFC<{
	showDeregister: boolean;
	value: AgentVO;
	actions?: string[];
}> = ({ showDeregister, value, actions = [] }) => {
	const { push, createHref } = useHistory();
	const clusterName = value.attributes.find((a) => a.key === 'k8s.cluster-name')?.value;
	const mode = value.attributes.find((a) => a.key === 'agent-mode')?.value;
	const buildVersion = value.attributes.find((a) => a.key === 'build.version')?.value ?? '';
	const buildTime = value.attributes.find((a) => a.key === 'build.time')?.value ?? '';
	const [isDeregistering, setIsDeregistering] = useState<boolean>(false);
	const deregisterAgent = (agentId: string): void => {
		invokePromise(async () => {
			try {
				setIsDeregistering(true);
				await Services.agents.deregisterAgent(agentId);
				Snackbar.dark('Triggered Deregister of Agent: ' + agentId);
				setIsDeregistering(false);
			} catch {
				Snackbar.error('Failed to trigger Deregister of Agent: ' + agentId);
			}
		});
	};

	return (
		<TableRow hoverable={true}>
			<TableDataCell>
				<Stack direction={'horizontal'} size={'xSmall'} alignItems={'center'}>
					<Text variant={'medium'}>{value.hostname}</Text>
					{clusterName && value.type === 'kubernetes' ? (
						<Tooltip content={`cluster ${clusterName}`}>
							<div>
								<presets.pill.Tag small>{clusterName}</presets.pill.Tag>
							</div>
						</Tooltip>
					) : null}
					{mode === 'default' ? (
						<Pill
							withLeftIcon="warning"
							style={{
								backgroundColor: Colors.feedbackWarningLightPill,
								color: Colors.feedbackWarningDark,
							}}
						>
							Unsupported Legacy Agent - please update
						</Pill>
					) : null}
				</Stack>
			</TableDataCell>
			<TableDataCell>
				<TypeIcon type={value.type} />
			</TableDataCell>
			<TableDataCell>
				<Tooltip content={buildTime}>
					<Text variant={'medium'}>{buildVersion}</Text>
				</Tooltip>
			</TableDataCell>
			<TableDataCell>
				<Stack direction={'horizontal'} size={'xSmall'} alignItems={'center'}>
					<Container>
						{value.logLevel
							? value.logLevel.split('\n')?.map((value, index) => (
									<React.Fragment key={index}>
										{value}
										<br />
									</React.Fragment>
								))
							: null}
					</Container>
					{actions.includes('changeLogLevel') ? (
						<ButtonIcon
							onClick={() =>
								push(
									createHref((location) => {
										location.pathname = `/settings/agents/installed/${value.id}/loglevel`;
									}),
								)
							}
							tooltip={'Change Log-Level'}
						>
							<IconEdit />
						</ButtonIcon>
					) : null}
				</Stack>
			</TableDataCell>
			<TableDataCell justifyContent={'flex-end'}>
				{actions.includes('readLog') ? (
					<ButtonIcon
						tooltip={'View Agent Log'}
						onClick={() =>
							push(
								createHref((location) => {
									location.pathname = `/settings/agents/installed/${value.id}/log`;
								}),
							)
						}
					>
						<IconLogfile />
					</ButtonIcon>
				) : null}
			</TableDataCell>
			{showDeregister ? (
				<TableDataCell justifyContent={'flex-end'}>
					<Tooltip content={'Steadybit-Internal Only!!!'}>
						<Button variant={'primaryAttention'} onClick={() => deregisterAgent(value.id)} disabled={isDeregistering}>
							Deregister Agent
						</Button>
					</Tooltip>
				</TableDataCell>
			) : null}
		</TableRow>
	);
};

export default AgentList;
