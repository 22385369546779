/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import TeamsSelection from 'pages/settings/teams/components/TeamsSelection';
import { Text } from '@steadybit/ui-components-lib';
import { ReactElement, useMemo } from 'react';
import { IconTeam } from 'components/icons';
import { TeamSummaryVO } from 'ui-api';
import { Stack } from 'components';
import { useField } from 'formik';

import { DataStreamResult } from '../../../../utils/hooks/stream/result';

interface SidebarTeamsModuleProps {
	addFakeAdminTeam?: boolean;
	teams: DataStreamResult<TeamSummaryVO[]>;
}

export default function SidebarTeamsModule({ addFakeAdminTeam, teams }: SidebarTeamsModuleProps): ReactElement {
	const [field, , { setValue, setTouched }] = useField<string[]>('teamIds');

	return (
		<SidebarTeamsModuleRenderer
			addFakeAdminTeam={addFakeAdminTeam}
			teams={teams.value ?? []}
			removeTeam={(teamId) => {
				setValue(field.value.filter((t) => t !== teamId));
				setTouched(true);
			}}
			setTeams={(teamIds) => {
				setValue(teamIds);
				setTouched(true);
			}}
			addedTeamIds={field.value}
			isLoading={teams.loading}
		/>
	);
}

interface SidebarTeamsModuleRendererProps {
	addFakeAdminTeam?: boolean;
	teams: TeamSummaryVO[];
	addedTeamIds: string[];
	isLoading?: boolean;
	disabled?: boolean;
	setTeams: (teamIds: string[]) => void;
	removeTeam: (teamId: string) => void;
}

function SidebarTeamsModuleRenderer({
	addFakeAdminTeam,
	addedTeamIds,
	isLoading,
	disabled,
	teams,
	setTeams,
}: SidebarTeamsModuleRendererProps): ReactElement {
	const adminTeamId = useMemo(() => {
		if (!isLoading && teams) {
			const admTeam = teams.find((t) => t.key === 'ADM');
			if (admTeam) {
				return admTeam.id;
			}
		}
		return undefined;
	}, [teams]);

	if (addFakeAdminTeam && adminTeamId) {
		addedTeamIds = [...addedTeamIds, adminTeamId];
	}

	return (
		<Stack maxWidth="100%">
			<Stack size="xxSmall">
				<Stack direction="horizontal" size="xxSmall">
					<IconTeam />
					<Text type="mediumStrong">Add Teams</Text>
				</Stack>
				<Text type="small">
					Add the Teams that will be able to do Experiments within the scope of this Environment.
				</Text>
			</Stack>

			<TeamsSelection
				disabledTeams={adminTeamId ? [adminTeamId] : []}
				value={addedTeamIds}
				disabled={disabled}
				onChange={(teamIds) => {
					if (adminTeamId && !teamIds.includes(adminTeamId)) {
						setTeams([...teamIds, adminTeamId]);
					} else {
						setTeams(teamIds);
					}
				}}
			/>
		</Stack>
	);
}
