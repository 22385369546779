/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { createFilterParams, UrlState } from 'pages/templates/FromTemplateModal/urlParams';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import useRefreshingTemplates from 'services/useRefreshingTemplates';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { Flex, Spacings } from '@steadybit/ui-components-lib';
import { ReactElement, useEffect, useState } from 'react';
import { createResult } from 'utils/hooks/stream/result';
import { createStableId, includes } from 'utils/string';
import { useLicenseFeature } from 'services/licenseApi';
import useAgentReport from 'utils/hooks/useAgentReport';
import { useUrlState } from 'url/useUrlState';
import { useTeam } from 'services/useTeam';
import { theme } from 'styles.v2/theme';
import Sidebar from 'targets/Sidebar';
import { ActionVO } from 'ui-api';
import { ampli } from 'ampli';

import SelectedEntityPopup from './SelectedEntityPopup';
import { NoActionsNotification } from './Notifications';
import TemplatesContent from './TemplatesContent';
import Sections, { Section } from './Sections';
import ActionsContent from './ActionsContent';
import { ActionCategoryItem } from './types';
import SearchInput from './SearchInput';
import useActions from '../useActions';

interface StepsSidebarProps {
	height: number;
}

interface SelectedEntity {
	action?: ActionCategoryItem;
	templateId?: string;
}

export default function StepsSidebar({ height }: StepsSidebarProps): ReactElement {
	const [selectedEntity, setSelectedEntity] = useState<SelectedEntity | null>(null);
	const [section, setSection] = useState<Section>('actions');
	const templatesEnabled = !!useLicenseFeature('TEMPLATES');
	const team = useTeam();

	const targetDefinitionsResult = useTargetDefinitions();
	const targetDefinitions = targetDefinitionsResult.value || [];

	const [{ tagsParam, actionsParam, kindsParam, targetTypesParam, freeTextPhrasesParam, includeNonPermittedParam }] =
		useState(() => createFilterParams('/design'));
	const [{ freeTextPhrases, actions: actionsFromUrl, kinds, targetTypes, tags, includeNonPermitted }] =
		useUrlState<UrlState>([
			includeNonPermittedParam,
			freeTextPhrasesParam,
			targetTypesParam,
			actionsParam,
			kindsParam,
			tagsParam,
		]);
	const isSearchDefined =
		freeTextPhrases.length > 0 ||
		targetTypes.length > 0 ||
		actionsFromUrl.length > 0 ||
		tags.length > 0 ||
		kinds.length > 0;

	// actions
	const result = useActions();
	const actions = useFilteredActions(result.actions);

	const { someAgentHasReportedInThePast, loading: agentReportLoading } = useAgentReport();
	const hadNeverAnAgent = !agentReportLoading && !someAgentHasReportedInThePast;

	// templates
	const templatesResult = templatesEnabled
		? useRefreshingTemplates({
				teamId: includeNonPermitted ? undefined : team.id,
				searchContext: 'NEW_EXPERIMENT',
				pageSize: 1_000_000,
				pathname: '/design',
			})
		: createResult(null);
	const templates = templatesResult.value?.content;

	useAmpliTracking(
		!templates,
		section === 'actions' ? actions.length : (templates?.length ?? 0),
		section === 'actions' ? 'action' : 'template',
		freeTextPhrases,
		targetTypes,
		actionsFromUrl,
		kinds,
		tags,
	);

	return (
		<Sidebar
			title="Steps"
			sx={{
				position: 'relative',
				height: `calc(100vh - ${height}px)`,
				backgroundColor: 'neutral000',
				overflowY: 'visible',
				overflow: 'visible',
				boxShadow: '5px 0px 6px rgba(0, 0, 0, 0.1)',
				paddingLeft: Spacings.small,
				paddingRight: Spacings.small,
				zIndex: 2,
			}}
		>
			{(collapsed, setCollapsed) => {
				return (
					<>
						<ErrorBoundary log={(err: string, info: string) => console.error(err, info)}>
							{selectedEntity && (
								<SelectedEntityPopup
									targetDefinitions={targetDefinitions}
									selectedEntity={selectedEntity}
									onClose={() => setSelectedEntity(null)}
								/>
							)}
							<Flex spacing={collapsed ? 'large' : 'small'} style={{ marginTop: Spacings.small }}>
								<SearchInput
									targetDefinitionsResult={targetDefinitionsResult}
									collapsed={collapsed}
									onExpand={() => setCollapsed(false)}
								/>

								<Flex
									spacing={collapsed ? 'large' : 'none'}
									style={{
										border: collapsed ? 'none' : '1px solid ' + theme.colors.neutral300,
										maxHeight: `calc(100vh - ${height + 160}px)`,
										width: '100%',
									}}
								>
									<Sections
										templatesEnabled={!!templatesEnabled}
										collapsed={collapsed}
										section={section}
										setSection={(section) => {
											setSection(section);
											setSelectedEntity(null);
										}}
										numTemplates={templates?.length ?? -1}
										numActions={hadNeverAnAgent ? 0 : (actions.length ?? -1)}
									/>

									{section === 'actions' &&
										(hadNeverAnAgent ? (
											<NoActionsNotification />
										) : (
											<ActionsContent
												selectedActionId={selectedEntity?.action?.id}
												isSearchDefined={isSearchDefined}
												collapsed={collapsed}
												actions={actions}
												onActionClick={(action) => setSelectedEntity(action ? { action } : null)}
											/>
										))}
									{section === 'templates' && (
										<TemplatesContent
											selectedTemplateId={selectedEntity?.templateId}
											targetDefinitions={targetDefinitions}
											isSearchDefined={isSearchDefined}
											hadNeverAnAgent={hadNeverAnAgent}
											collapsed={collapsed}
											templates={templates}
											onTemplateClick={(templateId) => setSelectedEntity({ templateId })}
										/>
									)}
								</Flex>
							</Flex>
						</ErrorBoundary>
					</>
				);
			}}
		</Sidebar>
	);
}

function useFilteredActions(actions: ActionVO[]): ActionVO[] {
	const [{ environmentIdParam, tagsParam, actionsParam, targetTypesParam, freeTextPhrasesParam, kindsParam }] =
		useState(() => createFilterParams('/design'));
	const [{ freeTextPhrases, tags, actions: actionsFromUrl, kinds, targetTypes }] = useUrlState<UrlState>([
		freeTextPhrasesParam,
		environmentIdParam,
		targetTypesParam,
		actionsParam,
		kindsParam,
		tagsParam,
	]);

	// actions
	actions = actions.filter((action) => {
		const matchesAnyFreeText =
			freeTextPhrases.length === 0
				? true
				: freeTextPhrases.some(
						(freeTextPhrase) =>
							includes(action.name, freeTextPhrase) && !includes(action.technology || '', freeTextPhrase),
					);

		const matchesAnyKind = kinds.length === 0 ? true : kinds.some((kind) => includes(action.kind, kind));

		const matchesAnyAction =
			actionsFromUrl.length === 0 ? true : actionsFromUrl.some((_action) => includes(_action, action.id));

		const matchesAnyTag =
			tags.length === 0 ? true : tags.some((tag) => !action.hubTags || action.hubTags.includes(tag));

		const matchesAnyTargetType =
			targetTypes.length === 0
				? true
				: targetTypes.some((targetType) => !('target' in action) || action.target.type === targetType);

		return matchesAnyFreeText && matchesAnyKind && matchesAnyAction && matchesAnyTag && matchesAnyTargetType;
	});

	return actions;
}

function useAmpliTracking(
	isLoading: boolean,
	numberElements: number,
	category: 'action' | 'template',
	freeTextPhrases: string[],
	targetTypes: string[],
	actions: string[],
	kinds: string[],
	tags: string[],
): void {
	useEffect(() => {
		if (isLoading) {
			return;
		}
		ampli.experimentSidebarFiltered({
			filter_actions: actions,
			filter_kind: kinds,
			filter_tags: tags,
			filter_target_types: targetTypes,
			filter_texts: freeTextPhrases,
			experiment_sidebar_category: category,
			elements_count: numberElements,
		});
	}, [
		isLoading,
		numberElements,
		category,
		createStableId(freeTextPhrases),
		createStableId(targetTypes),
		createStableId(actions),
		createStableId(kinds),
		createStableId(tags),
	]);

	useEffect(() => {
		if (isLoading) {
			return;
		}
		ampli.experimentSidebarViewed({
			experiment_sidebar_category: category,
			elements_count: numberElements,
		});
	}, [isLoading, numberElements, category]);
}
