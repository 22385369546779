/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useAttributeDefinitions } from 'attributes/useAttributeDefinitions';
import { ActionVO, ExperimentStepActionVO, TargetPredicateVO } from 'ui-api';
import { emptyTargetDefinition, TargetId, toTargetId } from 'targets/util';
import TargetDetailsModal from 'targets/TargetDetails/TargetDetailsModal';
import { useBlastRadiusCount } from 'pages/experiments/components/utils';
import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import { EmptyListContentProps } from 'targets/TableView/TargetsTable';
import AttackedTargetIndicator from 'targets/AttackedTargetIndicator';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Flex, TextInput } from '@steadybit/ui-components-lib';
import { TargetViewTable } from 'hocs/targets/TargetViewTable';
import { Container, Stack, Text, Tooltip } from 'components';
import { formatPercentage } from 'utils/percentageFn';
import { IconInformation } from 'components/icons';
import { useUrlState } from 'url/useUrlState';
import { toTitleCase } from 'utils/string';
import { useFormikContext } from 'formik';
import { getLabel } from 'i18n/label';
import { get } from 'lodash';

import { ExperimentError, ExperimentFormValues } from '../types';
import EmptyTableNoPredicate from './EmptyTableNoPredicate';
import { selectedTargetIdParam } from '../urlParams';

interface ActionTargetTableProps {
	actionStep: ExperimentStepActionVO;
	action: ActionVO;
	stepPath: string;
}

export default function ActionTargetTable(props: ActionTargetTableProps): ReactElement | null {
	const { action } = props;
	const targetType = action.target?.type;
	if (!targetType) {
		return null;
	}

	return <ActionTargetTableContent targetType={targetType} {...props} />;
}

interface UrlState {
	targetId: TargetId;
}

interface ActionTargetTableContentProps extends ActionTargetTableProps {
	targetType: string;
}

function ActionTargetTableContent({
	actionStep,
	action,
	targetType,
	stepPath,
}: ActionTargetTableContentProps): ReactElement | null {
	const [searchQuery, setSearchQuery] = useState('');
	useEffect(() => {
		if (!actionStep.blastRadius?.predicate) {
			setSearchQuery('');
		}
	}, [actionStep.blastRadius?.predicate]);

	const formik = useFormikContext<ExperimentFormValues>();
	const { environmentId } = formik.values;

	const attributeDefinitions = useAttributeDefinitions();
	const targetDefinitions = useTargetDefinitions();
	const targetDefinition =
		(targetDefinitions.value || []).find((target) => target.id === targetType) || emptyTargetDefinition(targetType);

	const [{ targetId }, getUrlWithState, updateUrlWithState] = useUrlState<UrlState>([selectedTargetIdParam]);

	const experimentVariables = useFormikContext<ExperimentFormValues>().values.experimentVariables || [];
	const blastRadiusCount = useBlastRadiusCount(
		actionStep.blastRadius || {},
		environmentId,
		experimentVariables,
		action.missingQuerySelection,
	);
	const blastRadiusTargetLabel = useMemo((): string => {
		if (targetDefinition) {
			return toTitleCase(getLabel(targetDefinition.label));
		}

		return toTitleCase(targetType) + 's';
	}, [targetType, targetDefinition]);

	if (!targetDefinition || !actionStep.blastRadius || !attributeDefinitions.value) {
		return null;
	}

	const totalTargets = blastRadiusCount.total || 0;
	const impactedTargets = blastRadiusCount.impacted || actionStep.blastRadius.maximum || 0;

	const predicateError = get(formik.errors, `${stepPath}.blastRadius.predicate`);
	const predicatePartsErrors = get(formik.errors, `${stepPath}.blastRadius.predicateparts`);

	const predicate =
		actionStep.blastRadius.predicate && !predicatePartsErrors
			? actionStep.blastRadius.predicate
			: action.missingQuerySelection === 'INCLUDE_NONE'
				? {
						type: 'no-targets',
					}
				: undefined;

	return (
		<Stack
			size="none"
			sx={{
				width: '100%',
				pb: 'medium',
				pt: 'small',
				px: 'small',
				overflowY: 'auto',
				boxShadow: 'inset 2px 0px 7px rgba(0, 0, 0, 0.1)',
			}}
		>
			<>
				{targetId != null && (
					<TargetDetailsModal
						onClose={() => updateUrlWithState({ targetId: undefined })}
						environmentId={environmentId}
						targetId={targetId}
						withAdvice={false}
					/>
				)}

				<Container
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						gap: '12px',
						height: '100px',
						width: '100%',
					}}
				>
					<Flex direction="horizontal" align="center" spacing="xxSmall">
						{impactedTargets > 0 && (
							<Tooltip
								content={
									<>
										Targets will be randomly selected from the list below for
										<br />
										inclusion in this action to match the configured blast radius.
									</>
								}
							>
								<Flex align="center">
									<IconInformation variant="small" />
								</Flex>
							</Tooltip>
						)}
						<Flex direction="horizontal" align="center" spacing="xxSmall" wrap>
							<Text variant="medium" color="neutral800" sx={{ whiteSpace: 'nowrap' }}>
								{action.kind === 'ATTACK' ? 'Attacked' : ''} {blastRadiusTargetLabel}:
							</Text>
							<Text variant="mediumStrong" color="neutral800" sx={{ whiteSpace: 'nowrap' }}>
								{impactedTargets}{' '}
								{actionStep.blastRadius?.percentage
									? `(${actionStep.blastRadius.percentage}%)`
									: totalTargets === 0 || impactedTargets === 0
										? ''
										: `(${formatPercentage(impactedTargets / totalTargets)})`}
							</Text>
							{impactedTargets > 0 && (
								<Text variant="medium" color="neutral800" sx={{ whiteSpace: 'nowrap' }}>
									out of {totalTargets} selected by query
								</Text>
							)}
						</Flex>
					</Flex>

					<TextInput
						withLeftIcon="search"
						placeholder="Search"
						value={searchQuery}
						onChange={setSearchQuery}
						style={{
							marginLeft: 'auto',
							width: 300,
						}}
					/>
				</Container>

				<TargetViewTable
					key={predicateError}
					EmptyResult={(props) => (
						<EmptyTargetListContent
							{...props}
							predicate={actionStep.blastRadius?.predicate}
							containsValueErrors={containsValueErrors(predicatePartsErrors)}
							predicateRequired={action.missingQuerySelection === 'INCLUDE_NONE'}
						/>
					)}
					getTargetDetailHref={(target) => getUrlWithState({ targetId: toTargetId(target) })}
					attributeDefinitions={attributeDefinitions.value}
					targetDefinition={targetDefinition}
					environmentId={environmentId}
					predicate={predicate}
					query={searchQuery}
					borderless
				/>
			</>
		</Stack>
	);
}

interface EmptyTargetListContentProps extends EmptyListContentProps {
	predicate: TargetPredicateVO | undefined;
	containsValueErrors: boolean;
	predicateRequired: boolean;
}

function EmptyTargetListContent({
	query,
	predicate,
	targetDefinition,
	containsValueErrors,
	predicateRequired,
}: EmptyTargetListContentProps): ReactElement {
	if (containsValueErrors) {
		return (
			<EmptyTableNoPredicate
				title="Provide a value"
				message="You can select targets for this action based on the values of their attributes."
				targetDefinitionId={targetDefinition.id}
			/>
		);
	}

	const isPredicateDefined = !!predicate;

	if (!isPredicateDefined && predicateRequired) {
		return (
			<EmptyTableNoPredicate
				title="Specify a query in order to see targets"
				message="Complete the query to select some targets."
				targetDefinitionId={targetDefinition.id}
			/>
		);
	}

	return (
		<AstroidScreen
			title={
				<Text variant="largeStrong">
					{query
						? `No ${getLabel(targetDefinition.label, 0)} matching your filter found`
						: `No ${getLabel(targetDefinition.label, 0)} matching your query`}
				</Text>
			}
			icon={<AttackedTargetIndicator targetType={targetDefinition.id} variant="xxLarge" color="purple700" />}
			description={
				<Text variant="medium" color="neutral600" textAlign="center" maxWidth={520}>
					Try selecting a different combination of attributes and values.
				</Text>
			}
		/>
	);
}

function containsValueErrors(predicatePartsErrors: ExperimentError[] | undefined): boolean {
	if (!predicatePartsErrors) {
		return false;
	}

	try {
		return predicatePartsErrors.some((error) => 'values' in error);
	} catch (error) {
		console.error('Error checking for value errors', error);
		return false;
	}
}
