/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { TextInput, TextArea, Flex, Colors } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { FieldHookConfig, useField } from 'formik';
import { Text } from 'components';

import { Label } from '../Label';

interface FormikFieldProps {
	size?: 'small' | 'medium' | 'large';
	as?: 'textarea' | 'input';
	defaultValue?: string;
	autoComplete?: string;
	placeholder?: string;
	autoFocus?: boolean;
	disabled?: boolean;
	password?: boolean;
	maxLength?: number;
	label?: string;
	name: string;
}

export default function FormikTextField({ name, defaultValue, ...props }: FormikFieldProps): ReactElement {
	const [{ value }, meta, { setValue }] = useField({ type: 'text', name, defaultValue } as FieldHookConfig<string>);
	return <FormTextField name={name} {...meta} {...props} value={value || ''} onChange={setValue} />;
}

interface FormTextFieldProps extends FormikFieldProps {
	maxLength?: number;
	touched?: boolean;
	error?: string;
	value: string;
	onChange: (v: string) => void;
}

function FormTextField({
	autoComplete,
	placeholder,
	value = '',
	autoFocus,
	maxLength,
	disabled,
	password,
	touched,
	label,
	error,
	size,
	as,
	onChange,
	...props
}: FormTextFieldProps): ReactElement {
	const errorStyle = error
		? {
				border: '1px solid ' + Colors.coral,
				outline: '1px solid ' + Colors.coral,
			}
		: {};
	const textFieldEl =
		as === 'textarea' ? (
			<TextArea
				placeholder={placeholder}
				autoFocus={autoFocus}
				maxLength={maxLength}
				disabled={disabled}
				value={value}
				onChange={onChange}
				style={{ width: '100%', ...errorStyle }}
				{...props}
			/>
		) : (
			<TextInput
				autoComplete={autoComplete}
				placeholder={placeholder}
				maxLength={maxLength}
				autoFocus={autoFocus}
				password={password}
				disabled={disabled}
				value={value}
				size={size}
				onChange={onChange}
				style={errorStyle}
				{...props}
			/>
		);
	const hasError = Boolean(touched && error);

	return (
		<Flex spacing="xSmall" style={{ width: '100%' }} align="spread">
			{label && <Label variant="mediumStrong">{label}</Label>}
			{textFieldEl}
			{hasError && (
				<Text mt="xxSmall" variant="smallStrong" color="coral" data-formik-error>
					{error}
				</Text>
			)}
		</Flex>
	);
}
