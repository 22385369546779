/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, Text, Link, ModalContentV2, ModalHeaderV2, ModalV2, Spinner, Stack, Message } from 'components';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { AxiosError } from 'axios';
import AnsiUp from 'ansi_up';
import React from 'react';

import { IconNewTab } from '../../../components/icons';

const AgentLog: React.VFC<{ agentId: string; close: () => void }> = ({ agentId, close }) => {
	const [agent, fetch] = useAsyncState(() => Services.agents.fetchAgent(agentId), [agentId]);
	const tenant = useTenant();
	const [{ value: logUrl }] = useAsyncState(
		() => Services.agents.getAgentLogUrl(agentId, tenant.key),
		[agentId, tenant.key],
	);
	useEventEffect(
		React.useCallback(
			(event) => {
				if (agentId === event.agentId) {
					fetch();
				}
			},
			[fetch, agentId],
		),
	);

	const isNotFound = (agent.error as AxiosError)?.response?.status === 404;

	return (
		<ModalV2 width={'calc(100vw - 4rem)'} withFooter>
			<ModalHeaderV2
				title={
					<Container display={'flex'} justifyContent={'space-between'}>
						<div>
							Agent Log {agent.value?.hostname}
							{logUrl ? (
								<Text variant={'small'} as={'span'} ml={'xSmall'}>
									<Link href={logUrl} target={'_blank'}>
										Raw Log <IconNewTab variant={'xSmall'} />
									</Link>
								</Text>
							) : null}
						</div>
					</Container>
				}
				onClose={close}
			/>
			<ModalContentV2>
				{agent.loading ? (
					<Stack>
						<Spinner variant={'xxLarge'} color={'neutral200'} />
					</Stack>
				) : null}
				{!agent.loading && agent.value ? <AgentLogViewer agentId={agentId} /> : null}
				{agent.error && (
					<Container px={'medium'}>
						<Message variant={'warning'} title={<>Couldn&apos;t Load Agent Log</>}>
							{isNotFound ? (
								<>The agent wasn&apos;t found anymore. Please check if the agent has disconnected.</>
							) : (
								<>
									Unexpected error occurred when loading the agent log.
									<br />
									Details: {String(agent.error)}
								</>
							)}
						</Message>
					</Container>
				)}
			</ModalContentV2>
		</ModalV2>
	);
};

const AgentLogViewer: React.VFC<{ agentId: string }> = ({ agentId }) => {
	const [{ isRunning, error }, setState] = React.useState({ isRunning: true, error: null });
	const logViewer = React.useRef<HTMLDivElement>(null);
	const tenant = useTenant();

	React.useEffect(() => {
		const ansiUp = new AnsiUp();
		const subscription = Services.agents.streamAgentLog(agentId, tenant.key).subscribe(
			(line) => {
				const child = document.createElement('pre');
				// eslint-disable-next-line no-unsanitized/property
				child.innerHTML = ansiUp.ansi_to_html(line);
				logViewer.current?.appendChild(child);
			},
			(error) => {
				setState({ error, isRunning: false });
			},
			() => setState({ error: null, isRunning: false }),
		);
		return () => subscription.unsubscribe();
	}, [agentId, tenant.key]);

	return (
		<>
			<Container
				ref={logViewer}
				sx={{
					'& > pre': { m: 0, fontFamily: 'monospace', px: 'medium' },
					'& :nth-child(even)': { backgroundColor: 'neutral100' },
				}}
			>
				{error ? (
					<pre>
						Failed to fetch the agent log:
						<br />
						{String(error)}
					</pre>
				) : null}
			</Container>
			{isRunning ? (
				<Stack>
					<Spinner variant={'xxLarge'} color={'neutral200'} />
				</Stack>
			) : null}
		</>
	);
};

export default AgentLog;
