/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { hasExpired, LicenseContextType } from 'services/licenseApi';
import { ChromaticIgnore } from 'utils/storybook/ChromaticIgnore';
import { Banner, BannerAccent, Link } from 'components';
import { formatDateWithTime } from 'utils/dateFns';
import { ReactElement, useEffect } from 'react';
import { days } from 'utils/time';
import { ampli } from 'ampli';

interface LicenseWarningProps {
	license: LicenseContextType | undefined;
	admin: boolean;
}

export default function LicenseWarning({ license, admin }: LicenseWarningProps): ReactElement | null {
	const expired = hasExpired(license);
	const expiry: Date | undefined = license?.expires;
	const day = days(1).getMillis();

	useEffect(() => {
		if (expired) {
			ampli.licenseExpiredViewed({
				url: window.location.href,
			});
		}
	}, [expired]);

	if (!expired && expiry) {
		const daysTillExpire = (expiry.getTime() - Date.now()) / day;
		if (daysTillExpire > 14) {
			return null;
		}
	}

	const contactSection = admin ? (
		<>
			Please{' '}
			<Link href="https://steadybit.com/contact-us/" external>
				contact us
			</Link>{' '}
			to renew your license.
		</>
	) : (
		'Please contact your administrator.'
	);

	return (
		<Banner
			id="license-banner"
			variant={expired ? 'danger' : 'warning'}
			title={expired ? 'Your license is expired' : 'Your license will expire'}
			description={
				expired ? (
					<span>
						Your Steadybit license <BannerAccent>expired</BannerAccent>. All paid features are disabled.&nbsp;
						{contactSection}
					</span>
				) : (
					<span>
						Your Steadybit license will expire on{' '}
						<BannerAccent>
							<ChromaticIgnore display="inline">{formatDateWithTime(expiry)}</ChromaticIgnore>
						</BannerAccent>
						.&nbsp;
						{contactSection}
					</span>
				)
			}
			snooze={
				expired || !expiry
					? undefined
					: {
							id: 'license-expiry',
							end: new Date(expiry.getTime() - day * 3),
						}
			}
		/>
	);
}
