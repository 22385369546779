/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { useTenant } from '../tenancy/useTenant';
import { useUser } from './usersApi';

/**
 * Need to set a feature flag for development only? Update .env.development and restart the dev server!
 */
type FeatureFlag = 'disable_analytics' | 'experiment_input_syntax_highlighting';

export default function useFeatureFlag(featureFlag: FeatureFlag): boolean {
	if (typeof process.env.REACT_APP_FEATURE_FLAGS === 'string') {
		if (process.env.REACT_APP_FEATURE_FLAGS.split(',').indexOf(featureFlag) >= 0) {
			return true;
		}
	}
	const user = useUser();
	const tenant = useTenant();
	return user?.features?.includes(featureFlag) || tenant?.enabledFeatures?.includes(featureFlag);
}
