/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { ReactElement, useMemo } from 'react';
import { Container } from 'components';

import Chunks, { getTemplateChunks, getVariableChunks } from './Chunks';

interface VariableHighlightingOrElseProps {
	hasErrors?: boolean;
	isEditMode: boolean;
	withUnit?: boolean;
	disabled: boolean;
	value: string;
	onClick: () => void;
	children: ReactElement;
}

export default function VariableHighlightingOrElse({
	isEditMode,
	hasErrors,
	disabled,
	withUnit,
	children,
	value,
	onClick,
}: VariableHighlightingOrElseProps): ReactElement {
	const { mode } = useEditorSettings();
	const chunks = useMemo(
		() => (mode === 'experiment' ? getVariableChunks(value) : getTemplateChunks(value)),
		[mode, value],
	);

	if (isEditMode || chunks.every((chunk) => chunk.type === 'text')) {
		return children;
	}

	return (
		<Container
			disabled={disabled}
			onClick={() => {
				if (!disabled) {
					onClick();
				}
			}}
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '3px',

				width: '100%',
				height: '40px',

				p: 'xSmall',
				backgroundColor: 'neutral000',
				borderRadius: withUnit ? '4px 0 0 4px' : '4px',
				border: '1px solid',
				borderRight: !hasErrors && withUnit ? 'none' : '1px solid',
				borderColor: hasErrors ? 'coral' : 'neutral300',
				overflow: 'hidden',

				cursor: disabled ? 'default' : 'text',
			}}
			data-cy="text-field-with-highlighting"
		>
			<Chunks value={value} chunks={chunks} />
		</Container>
	);
}
