/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Code,
	Container,
	Label,
	Link,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Stack,
	Text,
	Tooltip,
} from 'components';
import { IconInformationCircle, IconWarning } from 'components/icons';
import { Flex, TextInput } from '@steadybit/ui-components-lib';
import { Formik, useFormikContext } from 'formik';
import { getBaseHref } from 'utils/getBaseHref';
import { useTenant } from 'tenancy/useTenant';
import { ReactElement } from 'react';

import CodeTypeSelection, { codeTypeOptions } from './CodeTypeSelection';
import ExperimentTags from '../../experimentsV2/ExperimentTags';
import ScaleSelection, { scaleOptions } from './ScaleSelection';
import { useTeam } from '../../../services/useTeam';

type TagBadgeModalProps = {
	onClose: () => void;
};

type TagBadgeValues = {
	tags: string[];
	teamId: string;
	codeType: string;
	scale: string;
	caption: string;
};

export default function TagBadgeModal({ onClose }: TagBadgeModalProps): ReactElement {
	const team = useTeam();
	return (
		<ModalOverlay open onClose={onClose}>
			{({ close }) => (
				<ModalV2>
					<ModalHeaderV2 title={'Create a Tag Badge'} onClose={close} />
					<ModalContentV2>
						<Formik
							initialValues={{
								tags: [],
								teamId: team.id,
								codeType: codeTypeOptions[0].id,
								scale: scaleOptions[0].id,
								caption: 'Create Experiment for Tag',
							}}
							onSubmit={() => {}}
						>
							<TagBadgeForm />
						</Formik>
					</ModalContentV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}

function TagBadgeForm(): ReactElement {
	const formik = useFormikContext<TagBadgeValues>();
	const { tags, codeType, caption, scale } = formik.values;
	const tag = tags.length > 0 ? tags[0] : undefined;
	const tenant = useTenant();

	const imageUrl = `${getBaseHref()}api/badges/linked-badge.svg?tenantKey=${tenant.key}&scale=${scale}&tag=${tag}&createCaption=${encodeURI(`${caption} ${tag}`)}`;
	const linkUrl = `${getBaseHref()}api/badges/link?tenantKey=${tenant.key}&tag=${tag}`;
	const html = `<a href='${linkUrl}' target='_blank'><img alt='${tag}' src='${imageUrl}'></a>`;
	const markdown = `[![${tag}](${imageUrl})](${linkUrl})`;

	return (
		<Stack size={'medium'}>
			<Text muted>
				You can create a badge for any experiment tag. It will automatically update to reflect the current state. Refer
				to our{' '}
				<Link href={'https://docs.steadybit.com/integrate-with-steadybit/badges#tag-badges'} external>
					documentation
				</Link>{' '}
				for the details.
				<br />
				<br />
				Provide the tag that will be automatically added to new experiments created via the badge and be used to
				identify any existing experiment the badge should refer to.
			</Text>

			<Flex direction="horizontal" spacing="medium">
				<Label>
					<Stack size={'small'}>
						Tag
						<ExperimentTags maxTags={1} />
					</Stack>
				</Label>

				<Label>
					Format
					<CodeTypeSelection
						selectedCodeType={codeType}
						selectCodeType={(codeType) => formik.setFieldValue('codeType', codeType)}
					/>
				</Label>

				<Label>
					Scale
					<ScaleSelection selectedScale={scale} setSelectScale={(scale) => formik.setFieldValue('scale', scale)} />
				</Label>

				<Label>
					<Flex direction="horizontal" spacing="xSmall">
						Call to action
						<Tooltip
							color="light"
							placement="bottom"
							content={
								<Text variant="small" maxWidth="25em">
									The call to action will be shown when no experiment with the tag exists
								</Text>
							}
						>
							<div style={{ lineHeight: '16px' }}>
								<IconInformationCircle variant="small" color="neutral400" mt={2} />
							</div>
						</Tooltip>
					</Flex>

					<TextInput size="small" value={caption} onChange={(v) => formik.setFieldValue('caption', v)} />
				</Label>
			</Flex>

			<Stack variant={'xSmall'} sx={{ gridColumn: 'span 2' }}>
				<Label>Result</Label>
				{tag ? (
					<Stack variant={'xSmall'}>
						<Container>
							<Link href={linkUrl} target={'_blank'}>
								<img src={imageUrl} key={`example-badge-${codeType}`} alt={'Experiment Tag Badge'} />
							</Link>
						</Container>
						<Code
							lang={codeType === 'HTML' || codeType === 'MARKDOWN' ? 'markdown' : 'jsx'}
							sx={{ whiteSpace: 'normal' }}
							withCopyToClipboard
						>
							{codeType === 'HTML' ? html : codeType === 'MARKDOWN' ? markdown : imageUrl}
						</Code>
					</Stack>
				) : (
					<Stack
						direction={'horizontal'}
						size={'xxSmall'}
						alignItems={'center'}
						color={formik.getFieldMeta('tags').touched ? 'coral' : 'neutral500'}
					>
						<IconWarning variant={'small'} />
						<Text>Please provide a new tag or choose an existing one</Text>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
}
