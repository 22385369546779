/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, Dropdown, Flex, presets, SingleChoiceListItem, Text } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

import TeamIcon from './TeamIcon';
import useTeams from './useTeams';

interface TeamSelectionProps {
	myTeamsOnly?: boolean;
	includeAny?: boolean;
	disabled?: boolean;
	small?: boolean;
	value?: string;
	onChange: (teamId: string) => void;
}

type PrefixContentRenderer = (props: { item: SingleChoiceListItem }) => ReactElement | null;

export default function TeamSelection({
	myTeamsOnly = false,
	includeAny = false,
	disabled = false,
	value,
	small,
	onChange,
}: TeamSelectionProps): ReactElement {
	const [allTeams, myTeams, otherTeams] = useTeams({ includeAny, myTeamsOnly });
	const selectedTeam = allTeams.find((t) => t.id === value);

	const PrefixContent: PrefixContentRenderer = ({ item }): ReactElement | null => {
		const team = allTeams.find((t) => t.id === item.id);
		if (!team) {
			return null;
		}
		return (
			<TeamIcon userIsMember={team.userIsTeamMember} color={team.logoColor} logoId={team.logoId} variant="small" />
		);
	};

	return (
		<Dropdown<HTMLButtonElement>
			renderDropdownContent={({ width, close }) => (
				<presets.dropdown.DropdownContentFrame minWidth={width} maxHeight="400px">
					{myTeams.length > 0 && (
						<>
							<Heading>{`YOUR TEAM${myTeams.length > 1 ? 'S' : ''}`}</Heading>
							<presets.dropdown.SingleChoiceList
								PrefixContent={PrefixContent}
								items={myTeams.map((t) => ({ id: t.id, label: t.name, isSelected: t.id === value }))}
								onSelect={(id) => {
									onChange(id);
									close();
								}}
							/>
						</>
					)}
					{otherTeams.length > 0 && (
						<>
							<Heading>{`OTHER TEAM${otherTeams.length > 1 ? 'S' : ''}`}</Heading>
							<presets.dropdown.SingleChoiceList
								PrefixContent={PrefixContent}
								items={otherTeams.map((t) => ({ id: t.id, label: t.name, isSelected: t.id === value }))}
								onSelect={(id) => {
									onChange(id);
									close();
								}}
							/>
						</>
					)}
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => (
				<presets.dropdown.ChoiceButton
					setRefElement={setRefElement}
					placeholder="Any team"
					disabled={disabled}
					size={small ? 'small' : 'medium'}
					isOpen={isOpen}
					style={{ width: '100%' }}
					onClick={() => setOpen(!isOpen)}
					data-cy="team-select"
				>
					{selectedTeam?.name}
				</presets.dropdown.ChoiceButton>
			)}
		</Dropdown>
	);
}

function Heading({ children }: { children: string }): ReactElement {
	return (
		<Flex direction="horizontal" style={{ pt: 'small', pb: 'xxSmall', px: 'small' }}>
			<Text type="small" style={{ color: Colors.neutral600 }}>
				{children}
			</Text>
		</Flex>
	);
}
