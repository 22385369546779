/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Li, Pill, RouterLink, Stack, Text, Tooltip } from 'components';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { createIconFromDataUri } from 'components/icons';
import { useUrlState } from 'url/useUrlState';
import { AdviceListItemVO } from 'ui-api';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { UrlState, selectedAdviceIdParam, selectedTargetIdParam } from '../urlParams';

interface AdviceListProps {
	advice: AdviceListItemVO;
	isSelected: boolean;
}

export default function AdviceListItem({ advice, isSelected }: AdviceListProps): ReactElement {
	const [, getHrefWithUrlState] = useUrlState<UrlState>([selectedAdviceIdParam, selectedTargetIdParam]);
	const Icon = createIconFromDataUri(advice.icon);

	return (
		<Li key={advice.type} mb="small">
			<RouterLink to={getHrefWithUrlState({ selectedAdviceId: advice.type, selectedTargetId: null })} dontResolve>
				<Stack
					direction="horizontal"
					size="xSmall"
					sx={{
						alignItems: 'center',

						px: 'small',
						py: 'small',
						borderRadius: 4,
						border: '1px solid ' + (isSelected ? theme.colors.slate : theme.colors.neutral300),
						outline: '1px solid ' + (isSelected ? theme.colors.slate : 'transparent'),
						bg: isSelected ? 'neutral100' : 'white',

						color: isSelected ? theme.colors.slate : theme.colors.neutral800,

						cursor: 'pointer',
						':hover': {
							bg: 'neutral100',
							border: '1px solid ' + theme.colors.slate,
							outline: '1px solid ' + theme.colors.slate,
							color: theme.colors.slate,
						},
					}}
				>
					<Icon minWidth={20} maxWidth={20} minHeight={20} maxHeight={20} />

					<Tooltip content={advice.label} onlyShowOnEllipsis>
						<Text variant="medium" sx={{ ...textEllipsis }}>
							{advice.label}
						</Text>
					</Tooltip>

					<Pill backgroundColor="cyan200" backgroundColorOnHover="cyan800" color="cyan800">
						{advice.numberOfTargets}
					</Pill>
				</Stack>
			</RouterLink>
		</Li>
	);
}
