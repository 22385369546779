/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { TeamLogoColors, TeamLogoIcon } from 'components/icons/TeamLogoIcon';
import { Container, FormikTextField, Label, Stack } from 'components';
import { Grid } from '@steadybit/ui-components-lib';
import { useFormikContext } from 'formik';
import React from 'react';

import { TeamFormValues } from './teamTypes';

const TeamInformation: React.VFC<{ keyDisabled?: boolean; disabled?: boolean; isAdminTeam?: boolean }> = ({
	isAdminTeam = false,
	keyDisabled = false,
	disabled = false,
}) => {
	const formik = useFormikContext<TeamFormValues>();
	const { setFieldValue, setFieldTouched } = formik;
	const { logoId, logoColor } = formik.values;
	return (
		<Stack>
			<Grid cols="1fr 160px" spacing="small">
				<FormikTextField
					label="Team Name"
					name="name"
					maxLength={255}
					autoComplete="off"
					autoFocus
					disabled={disabled}
				/>
				<FormikTextField label="Key" name="key" maxLength={16} autoComplete="off" disabled={keyDisabled || disabled} />
			</Grid>
			{!isAdminTeam && (
				<>
					<FormikTextField
						as="textarea"
						label="Description (optional)"
						name="description"
						maxLength={1000}
						autoComplete="off"
						disabled={disabled}
					/>
					<Label>Team Logo</Label>
					<Stack direction={'horizontal'} size="none">
						{Array.from({ length: 13 }, (_, i) => (i + 1).toString()).map((nextLogoId) => (
							<Container
								display={'flex'}
								width={36}
								height={36}
								justifyContent={'center'}
								alignItems={'center'}
								sx={{
									opacity: disabled && logoId !== nextLogoId ? 0.5 : 1,
									backgroundColor: logoColor,
									borderRadius: '8px',
									cursor: disabled ? 'unset' : 'pointer',
									...(logoId === nextLogoId
										? { border: '2px solid', borderColor: 'neutral000', boxShadow: '0px 0px 0px 2px #5B48CA;' }
										: {}),
								}}
								mr={'xSmall'}
								flex={'0 0 auto'}
								key={nextLogoId}
								onClick={() => {
									if (!disabled) {
										setFieldValue('logoId', nextLogoId);
										setFieldTouched('logoId', true, false);
									}
								}}
							>
								<TeamLogoIcon logoId={nextLogoId} color={'neutral000'} disabled={disabled} />
							</Container>
						))}
					</Stack>
					<Label>Team Color</Label>
					<Stack direction={'horizontal'} size="none">
						{TeamLogoColors.map((nextLogoColor) => (
							<Container
								display={'flex'}
								width={36}
								height={36}
								justifyContent={'center'}
								alignItems={'center'}
								sx={{
									backgroundColor: nextLogoColor,
									opacity: disabled ? 0.5 : 1,
									borderRadius: '8px',
									cursor: disabled ? 'unset' : 'pointer',
									...(logoColor === nextLogoColor
										? { border: '2px solid', borderColor: 'neutral000', boxShadow: '0px 0px 0px 2px #5B48CA;' }
										: {}),
								}}
								mr={'xSmall'}
								flex={'0 0 auto'}
								key={nextLogoColor}
								onClick={() => {
									if (!disabled) {
										setFieldValue('logoColor', nextLogoColor);
										setFieldTouched('logoColor', true, false);
									}
								}}
							/>
						))}
					</Stack>
				</>
			)}
		</Stack>
	);
};

export default TeamInformation;
