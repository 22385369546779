/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react-markdown/lib/react-markdown';
import { Button, presets } from '@steadybit/ui-components-lib';
import { Container } from 'components';

export interface UnitFieldUnit {
	label: string;
	id: string;
}

interface UnitFieldProps {
	unitSelectorDisabled?: boolean;
	units?: UnitFieldUnit[];
	unit: UnitFieldUnit;
	disabled?: boolean;
	hasError?: boolean;
	width?: string;
	value?: string;
	min?: number;
	max?: number;
	onUnitChange?: (unit: string) => void;
	onChange: (value: number) => void;
}

export default function UnitField({
	unitSelectorDisabled,
	hasError,
	disabled,
	value,
	width,
	min,
	max,
	units,
	unit,
	onUnitChange,
	onChange,
}: UnitFieldProps): ReactElement {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Container
				as="input"
				type="number"
				min={min}
				max={max}
				value={value}
				onChange={(e) => {
					onChange(e.target.valueAsNumber || 0);
				}}
				disabled={disabled}
				sx={{
					height: '40px',
					px: 'small',
					py: 'xSmall',
					color: 'neutral800',
					fontSize: '15px',
					borderRadius: '4px 0 0 4px',
					border: '1px solid',
					borderRight: 'none',
					borderColor: hasError ? 'coral' : 'neutral300',
					width,

					':focus': {
						outline: 'none',
						borderColor: 'slate',
					},
				}}
			/>

			{units && units.length > 1 && onUnitChange ? (
				<presets.dropdown.SingleChoiceButton
					disabled={disabled || unitSelectorDisabled}
					withKeyboardNavigation
					placement="bottom-end"
					selectedId={unit.id}
					items={units}
					style={{
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
					}}
					onSelect={onUnitChange}
				>
					{unit.label}
				</presets.dropdown.SingleChoiceButton>
			) : (
				<Button
					type="secondary"
					style={{
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
					}}
				>
					{unit.label}
				</Button>
			)}
		</div>
	);
}
