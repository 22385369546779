/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, Dropdown, presets, MultipleChoiceListItem, Text, Flex } from '@steadybit/ui-components-lib';
import { ReactElement, useCallback } from 'react';
import { TeamSummaryVO } from 'ui-api';

import useTeams from './useTeams';
import TeamIcon from './TeamIcon';

interface TeamsSelectionProps {
	placement?: 'bottom-start';
	disabledTeams?: string[];
	myTeamsOnly?: boolean;
	disabled?: boolean;
	value: string[];
	onChange: (teamIds: string[]) => void;
}

export default function TeamsSelection({
	myTeamsOnly = false,
	disabledTeams = [],
	disabled = false,
	placement,
	value,
	onChange,
}: TeamsSelectionProps): ReactElement {
	const [allTeams, myTeams, otherTeams] = useTeams({ myTeamsOnly });
	const selectedTeams = allTeams.filter((t) => value.includes(t.id));

	return (
		<Dropdown<HTMLButtonElement>
			placement={placement}
			renderDropdownContent={({ width }) => (
				<presets.dropdown.DropdownContentFrame minWidth={width} maxHeight="400px">
					{myTeams.length > 0 && (
						<Teams
							title="YOUR TEAM"
							disabledTeams={disabledTeams}
							selectedTeams={value}
							allTeams={allTeams}
							teams={myTeams}
							onChange={onChange}
						/>
					)}
					{otherTeams.length > 0 && (
						<Teams
							title="OTHER TEAM"
							disabledTeams={disabledTeams}
							selectedTeams={value}
							allTeams={allTeams}
							teams={otherTeams}
							onChange={onChange}
						/>
					)}
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => (
				<presets.dropdown.ChoiceButton
					setRefElement={setRefElement}
					placeholder="Any team"
					disabled={disabled}
					isOpen={isOpen}
					style={{ width: '100%' }}
					onClick={() => setOpen(!isOpen)}
				>
					{selectedTeams.map((item) => item.name).join(', ')}
				</presets.dropdown.ChoiceButton>
			)}
		</Dropdown>
	);
}

type PrefixContentRenderer = (props: { item: MultipleChoiceListItem }) => ReactElement | null;
interface TeamsProps {
	allTeams: TeamSummaryVO[];
	disabledTeams: string[];
	selectedTeams: string[];
	teams: TeamSummaryVO[];
	title: string;
	onChange: (teamIds: string[]) => void;
}
function Teams({ title, allTeams, teams, selectedTeams, disabledTeams, onChange }: TeamsProps): ReactElement {
	const PrefixContent: PrefixContentRenderer = useCallback(
		({ item }): ReactElement | null => {
			const team = allTeams.find((t) => t.id === item.id);
			if (!team) {
				return null;
			}
			return (
				<TeamIcon userIsMember={team.userIsTeamMember} color={team.logoColor} logoId={team.logoId} variant="small" />
			);
		},
		[allTeams],
	);

	return (
		<>
			<Heading
				allSelected={teams.every((t) => selectedTeams.includes(t.id))}
				onSelectAll={() => onChange(Array.from(new Set([...selectedTeams, ...teams.map((t) => t.id)])))}
				onClearAll={() => onChange(selectedTeams.filter((v) => !teams.map((t) => t.id).includes(v)))}
			>{`${title}${teams.length > 1 ? 'S' : ''}`}</Heading>
			<presets.dropdown.MultipleChoiceList
				PrefixContent={PrefixContent}
				items={teams.map((t) => ({
					id: t.id,
					label: t.name,
					isSelected: selectedTeams.includes(t.id),
					disabled: disabledTeams.includes(t.id),
				}))}
				onUncheck={(id) => onChange(selectedTeams.filter((v) => v !== id))}
				onCheck={(id) => onChange([...selectedTeams, id])}
			/>
		</>
	);
}

interface HeadingProps {
	allSelected: boolean;
	children: string;
	onSelectAll: () => void;
	onClearAll: () => void;
}

function Heading({ allSelected, children, onSelectAll, onClearAll }: HeadingProps): ReactElement {
	return (
		<Flex direction="horizontal" align="center" justify="spread" style={{ pt: 'small', pb: 'xxSmall', px: 'small' }}>
			<Text type="small" style={{ color: Colors.neutral600 }}>
				{children}
			</Text>
			<Button
				type="chromeless"
				size="small"
				style={{
					color: Colors.slate,
					onHover: {
						textDecoration: 'underline',
					},
				}}
				onClick={() => (allSelected ? onClearAll() : onSelectAll())}
			>
				{allSelected ? 'Clear all' : 'Select all'}
			</Button>
		</Flex>
	);
}
