/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Code,
	Container,
	CopyToClipboard,
	Heading,
	Help,
	Li,
	Link,
	ModalContentV2,
	ModalHeaderV2,
	ModalV2,
	Stack,
	Tab,
	TabList,
	TabPanel,
	Tabs,
	Text,
	Ul,
} from 'components';
import { AgentInstallCollapsibled } from 'pages/onboarding/components/pages/PageAgentInstallation';
import { IconContainerD, IconCrio, IconDocker, IconHide, IconView } from 'components/icons';
import { Flex, TextInput } from '@steadybit/ui-components-lib';
import React, { ReactElement, useEffect } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { useToggle } from 'react-use';
import { ampli } from 'ampli';

import {
	getAgentDockerSetup,
	getAgentK8sHelmSetup,
	getAgentK8sHelmSetupText,
	getAgentLinuxSetup,
	GetSetupArg,
} from './setupCommands';

interface AgentSetupProps {
	close: () => void;
}

export default function AgentSetup({ close }: AgentSetupProps): ReactElement {
	useEffect(() => {
		ampli.agentAddVisited();
	}, []);

	return (
		<ModalV2 slick>
			<ModalHeaderV2 title="Which Technology do you want to use?" onClose={close} />
			<ModalContentV2>
				<Flex spacing="small" align="stretch" style={{ pt: 'xxxSmall' }}>
					<AgentInstallCollapsibled agentsAvailable />
				</Flex>
			</ModalContentV2>
		</ModalV2>
	);
}

function useAgentCredentials(): { agentKey: string; ingressUri: string; loading: boolean } {
	const agentInstallationGuidance = usePromise(() => Services.agents.getAgentInstallationGuidance(), []);
	const agentKey = agentInstallationGuidance.value?.agentKey ?? '<agent-key>';
	const ingressUri = agentInstallationGuidance.value?.ingressUri ?? '<ingress-uri>';

	return { agentKey, ingressUri, loading: agentInstallationGuidance.loading };
}

export function KubernetesInstallationContent({
	makeCopyButtonPrimary,
}: {
	makeCopyButtonPrimary: boolean;
}): ReactElement {
	const tab1 = 'containerd';
	const tab2 = 'docker';
	const tab3 = 'crio';

	const [activeSubTab, setActiveSubTab] = React.useState<'containerd' | 'docker' | 'crio'>(tab1);
	const { agentKey, ingressUri } = useAgentCredentials();
	const [clusterName, setClusterName] = React.useState<string>('');

	useEffect(() => {
		ampli.agentSetupInstallationContainerRuntimeChosen({
			agent_container_runtime: activeSubTab,
		});
	}, [activeSubTab]);

	return (
		<Stack m={'large'} size={'xLarge'}>
			<Container display={'flex'} justifyContent={'space-between'}>
				<Stack size="large">
					<Stack size={'small'}>
						<Heading variant={'medium'}>Install on Kubernetes</Heading>
						<Text variant={'medium'}>
							You can use our{' '}
							<Link href={'https://github.com/steadybit/helm-charts'} target={'_blank'}>
								helm chart
							</Link>{' '}
							to install Steadybit in your Kubernetes cluster.
						</Text>
						<Text variant={'medium'}>
							This will install the{' '}
							<Text as={'span'} variant={'mediumStrong'}>
								agent
							</Text>{' '}
							and the{' '}
							<Text as={'span'} variant={'mediumStrong'}>
								extensions host
							</Text>
							{', '}
							<Text as={'span'} variant={'mediumStrong'}>
								container
							</Text>
							{', '}
							<Text as={'span'} variant={'mediumStrong'}>
								Kubernetes
							</Text>
							{' and '}
							<Text as={'span'} variant={'mediumStrong'}>
								HTTP
							</Text>{' '}
							to add necessary support for Kubernetes-based environments.
						</Text>
					</Stack>

					<Stack size={'small'}>
						<Text variant="largeStrong" color="slateDark">
							1. What is the name of your Kubernetes Cluster?{' '}
							<Help color="neutral500" variant={'small'}>
								The cluster name uniquely identifies your Kubernetes cluster and thus the discovered targets for
								experiments.
								<br />
								The name of your kubeconfig is most often a good candidate - but it doesn&apos;t need to match.
							</Help>
						</Text>
						<Container flex="0 0 auto" width={320}>
							<TextInput placeholder="my-cluster-name" value={clusterName} onChange={setClusterName} />
						</Container>
					</Stack>
				</Stack>
			</Container>

			<Stack size="small">
				<Text variant="largeStrong" color="slateDark">
					2. Which Container Runtime are you using?{' '}
					<Help
						color="neutral500"
						variant={'small'}
						onClick={() =>
							window.open(
								'https://docs.steadybit.com/install-and-configure/install-agent/install-on-kubernetes#determine-container-runtime-on-a-node',
							)
						}
					>
						The container runtime of your Kubernetes cluster. Click on the icon in case you are unsure which runtime you
						are using.
					</Help>
				</Text>
				<Tabs
					onChange={(str) => {
						if (str === tab1 || str === tab2 || str === tab3) {
							setActiveSubTab(str);
						}
					}}
					value={activeSubTab}
					sx={{ bg: 'neutral000' }}
				>
					<TabList>
						<Tab value={tab1}>
							<IconContainerD size={'small'} mr={'xSmall'} ml={-8} />
							Containerd
						</Tab>
						<Tab value={tab2}>
							<IconDocker size={'small'} mr={'xSmall'} ml={-8} />
							Docker
						</Tab>
						<Tab value={tab3}>
							<IconCrio size={'small'} mr={'xSmall'} ml={-8} />
							Crio
						</Tab>
					</TabList>
					<TabPanel value={tab1}>
						<HelmSnippet
							makeCopyButtonPrimary={makeCopyButtonPrimary}
							containerRuntime={'containerd'}
							agentKey={agentKey}
							ingressUri={ingressUri}
							clusterName={clusterName}
							onCopy={() => {
								ampli.agentSetupInstallationScriptCopied({
									agent_installation_technology: 'kubernetes_helm',
									agent_container_runtime: 'containerd',
									agent_kubernets_cluster_name: clusterName,
								});
							}}
						/>
					</TabPanel>
					<TabPanel value={tab2}>
						<HelmSnippet
							makeCopyButtonPrimary={makeCopyButtonPrimary}
							containerRuntime={'docker'}
							agentKey={agentKey}
							ingressUri={ingressUri}
							clusterName={clusterName}
							onCopy={() => {
								ampli.agentSetupInstallationScriptCopied({
									agent_installation_technology: 'kubernetes_helm',
									agent_container_runtime: 'docker',
									agent_kubernets_cluster_name: clusterName,
								});
							}}
						/>
					</TabPanel>
					<TabPanel value={tab3}>
						<HelmSnippet
							makeCopyButtonPrimary={makeCopyButtonPrimary}
							containerRuntime={'cri-o'}
							agentKey={agentKey}
							ingressUri={ingressUri}
							clusterName={clusterName}
							onCopy={() => {
								ampli.agentSetupInstallationScriptCopied({
									agent_installation_technology: 'kubernetes_helm',
									agent_container_runtime: 'crio',
									agent_kubernets_cluster_name: clusterName,
								});
							}}
						/>
					</TabPanel>
				</Tabs>
				<Text variant={'small'}>
					Please ensure your Kubernetes cluster has access to the following URLs:
					<ul>
						<li>
							<URLValue url={'https://platform.steadybit.com'} description={'Platform'} />
						</li>
						<li>
							<URLValue url={'https://steadybit.github.io'} description={'Kubernetes helm repository'} />
						</li>
						<li>
							<URLValue url={'https://ghcr.io'} />
							{', and '}
							<URLValue url={'https://github.com'} description={'Container Images'} />
						</li>
					</ul>
				</Text>
			</Stack>
		</Stack>
	);
}

export function DockerInstallationContent({ makeCopyButtonPrimary }: { makeCopyButtonPrimary: boolean }): ReactElement {
	const { agentKey, ingressUri } = useAgentCredentials();
	const [showAgentKey, setShowAgentKey] = useToggle(false);

	return (
		<Stack m={'large'} size={'xLarge'}>
			<Container display={'flex'} justifyContent={'space-between'}>
				<Stack size={'small'}>
					<Heading variant={'medium'}>Install using Docker</Heading>
					<Text variant={'medium'} mt={'medium'}>
						You can install Steadybit on your machine using Docker compose.
					</Text>
					<Text variant={'medium'}>
						This will install the{' '}
						<Text as={'span'} variant={'mediumStrong'}>
							agent
						</Text>{' '}
						and the{' '}
						<Text as={'span'} variant={'mediumStrong'}>
							extensions host
						</Text>
						{', '}
						<Text as={'span'} variant={'mediumStrong'}>
							container
						</Text>
						{', and '}
						<Text as={'span'} variant={'mediumStrong'}>
							HTTP
						</Text>{' '}
						to add necessary support for containerized environments.
					</Text>
					<Text variant={'mediumStrong'} as="span">
						Supported Platforms:
					</Text>
					<Ul
						sx={{
							listStyleType: 'disc',
							pl: 'large',
							fontSize: 15,
						}}
					>
						<Li>Linux (needs the Docker and Docker compose plugin installed)</Li>
						<Li>Windows (using Docker Desktop using the WSL2 engine)</Li>
						<Li>MacOS (using Docker Desktop)</Li>
					</Ul>
				</Stack>
			</Container>

			<Stack size={'small'}>
				<Text variant="largeStrong" color="slateDark">
					1. Agent Installation
				</Text>
				<Text variant={'medium'}>
					We do provide a simple wrapper script that you can use to deploy the agent to Docker:
				</Text>
				<Stack size="xSmall">
					<Stack direction="horizontal" size="none" justifyContent="flex-end">
						<ShowButon showAgentKey={showAgentKey} setShowAgentKey={setShowAgentKey} />
						<CopyToClipboard
							variant={makeCopyButtonPrimary ? 'primary' : 'secondary'}
							data-track={'copy-docker-one-liner'}
							text={getAgentDockerSetup({ agentKey, ingressUri })}
						/>
					</Stack>
					<Code sx={{ overflowX: 'scroll' }}>
						{getAgentDockerSetup(showAgentKey ? { ingressUri, agentKey } : { ingressUri, agentKey: null })}
					</Code>
				</Stack>
			</Stack>
			<Text variant={'small'}>
				Please ensure your Docker host has access to the following URLs:
				<ul>
					<li>
						<URLValue url={'https://platform.steadybit.com'} description={'Platform'} />
					</li>
					<li>
						<URLValue url={'https://get.steadybit.com'} description={'Setup Scripts'} />
					</li>
					<li>
						<URLValue url={'https://ghcr.io'} />
						{' and '}
						<URLValue url={'https://github.com'} description={'Container Images'} />
					</li>
				</ul>
			</Text>
		</Stack>
	);
}

export function LinuxInstallationContent({ makeCopyButtonPrimary }: { makeCopyButtonPrimary: boolean }): ReactElement {
	const { agentKey, ingressUri } = useAgentCredentials();
	const [showAgentKey, setShowAgentKey] = useToggle(false);

	return (
		<Stack m={'large'} size={'large'}>
			<Container display={'flex'} justifyContent={'space-between'}>
				<Stack size={'small'}>
					<Heading variant={'medium'}>Install on Linux Hosts</Heading>
					<Text variant={'medium'} mt={'medium'}>
						You can install Steadybit on your Linux machine using the respective package managers.
					</Text>
					<Text variant={'medium'}>
						This will install the{' '}
						<Text as={'span'} variant={'mediumStrong'}>
							agent
						</Text>{' '}
						and the{' '}
						<Text as={'span'} variant={'mediumStrong'}>
							extensions host
						</Text>
						{', '}
						<Text as={'span'} variant={'mediumStrong'}>
							container
						</Text>
						{', and '}
						<Text as={'span'} variant={'mediumStrong'}>
							HTTP
						</Text>{' '}
						to add necessary support for containerized environments.
					</Text>

					<Text variant={'mediumStrong'} as="span">
						Supported package managers:
					</Text>
					<Ul
						sx={{
							listStyleType: 'disc',
							pl: 'large',
							fontSize: 15,
						}}
					>
						<Li>apt</Li>
						<Li>yum / dnf</Li>
					</Ul>
				</Stack>
			</Container>

			<Stack size={'small'}>
				<Text variant="largeStrong" color="slateDark">
					1. Agent Installation
				</Text>
				<Text variant="medium">
					The following command will download and run the latest Steadybit agent package on your system:
				</Text>
				<Stack size="xSmall">
					<Stack direction="horizontal" size="none" justifyContent="flex-end">
						<ShowButon showAgentKey={showAgentKey} setShowAgentKey={setShowAgentKey} />
						<CopyToClipboard
							variant={makeCopyButtonPrimary ? 'primary' : 'secondary'}
							data-track={'copy-linux'}
							text={getAgentLinuxSetup({ ingressUri, agentKey })}
						/>
					</Stack>

					<Code sx={{ overflowX: 'scroll' }}>
						{getAgentLinuxSetup(showAgentKey ? { ingressUri, agentKey } : { ingressUri, agentKey: null })}
					</Code>
				</Stack>
			</Stack>
			<Text variant={'small'}>
				Please ensure your Linux host has access to the following URLs:
				<ul>
					<li>
						<URLValue url={'https://platform.steadybit.com'} description={'Platform'} />
					</li>
					<li>
						<URLValue url={'https://packages.steadybit.com'} description={'.deb and .rpm packages'} />
						<URLValue url={'https://europe-west1-yum.pkg.dev'} description={'.rpm packages'} />
						<URLValue url={'https://europe-west1-apt.pkg.dev'} description={'.deb packages'} />
					</li>
					<li>
						<URLValue url={'https://get.steadybit.com'} description={'Setup Scripts'} />
					</li>
				</ul>
			</Text>
		</Stack>
	);
}

function HelmSnippet(setupArg: GetSetupArg): ReactElement {
	const [showAgentKey, setShowAgentKey] = useToggle(false);
	return (
		<Stack size="small" p="xSmall">
			<Container display="flex" justifyContent="flex-end">
				<ShowButon showAgentKey={showAgentKey} setShowAgentKey={setShowAgentKey} />
				<CopyToClipboard
					variant={setupArg.makeCopyButtonPrimary ? 'primary' : 'secondary'}
					data-track="copy-kubernetes-helm"
					text={getAgentK8sHelmSetupText({ ...setupArg })}
				/>
			</Container>
			<Code sx={{ overflowX: 'scroll' }}>
				{getAgentK8sHelmSetup(showAgentKey ? setupArg : { ...setupArg, agentKey: null })}
			</Code>
		</Stack>
	);
}

function ShowButon({
	showAgentKey,
	setShowAgentKey,
}: {
	setShowAgentKey: (nextValue: boolean) => void;
	showAgentKey: boolean;
}): ReactElement {
	return (
		<Button variant="chromeless" mr="xSmall" onClick={() => setShowAgentKey(!showAgentKey)}>
			{showAgentKey ? 'Hide' : 'Show'} agent key
			{showAgentKey ? <IconHide mx="xSmall" /> : <IconView mx="xSmall" />}
		</Button>
	);
}

function URLValue({ url, description }: { url: string; description?: string }): ReactElement {
	return (
		<>
			<Code withCopyToClipboard inline getContentToCopyOrDownload={() => url}>
				{url}
			</Code>
			{description && ` (${description})`}
		</>
	);
}
