/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Dropdown, presets, TextInput } from '@steadybit/ui-components-lib';
import { ReactElement, ReactNode, useMemo, useState } from 'react';
import { Skeletons, Stack, Text } from 'components';
import { includes } from 'lodash';

import WithDescription from './WithDescription';

interface DropDownProps {
	onItemClick: (key: string | null) => void;
	heading?: string | ReactNode;
	placeholder?: string;
	readonly?: boolean;
	framed?: boolean;
	items: string[];
	label: string;
}

export default function DropDown({
	readonly = false,
	placeholder,
	heading,
	framed,
	label,
	items,
	onItemClick,
}: DropDownProps): ReactElement {
	const [groupFilter, setGroupFilter] = useState<string | null>(null);

	const labelIsSet = !!label;

	const dropDown = (
		<Dropdown<HTMLInputElement>
			onOpen={() => !groupFilter && setGroupFilter('')}
			onClose={() => {
				if (!groupFilter) {
					setGroupFilter(null);
				}
			}}
			placement="bottom-start"
			renderDropdownContent={({ width, close }) => (
				<Content
					onClick={(key) => {
						setGroupFilter(null);
						onItemClick(key);
						close();
					}}
					width={width}
					items={items}
					groupFilter={groupFilter}
					selectedId={labelIsSet ? label : ''}
				/>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<TextInput
						ref={setRefElement}
						value={groupFilter !== null ? groupFilter : label}
						placeholder={placeholder}
						highlighted={isOpen}
						disabled={readonly}
						onClear={() => {
							onItemClick(null);
							setGroupFilter(null);
						}}
						onChange={(v) => setGroupFilter(v)}
						onClick={() => setOpen(!isOpen)}
					/>
				);
			}}
		</Dropdown>
	);

	if (heading) {
		return (
			<WithDescription
				description={heading}
				sx={
					framed
						? {
								borderRadius: '8px',
								px: 'xSmall',
								py: 'small',
								bg: 'neutral100',
							}
						: {}
				}
			>
				{dropDown}
			</WithDescription>
		);
	}
	return dropDown;
}

interface ContentProps {
	onClick: (key: string | null) => void;
	groupFilter?: string | null;
	selectedId: string;
	items: string[];
	width: string;
}

function Content({ selectedId, onClick, groupFilter, items, width }: ContentProps): ReactElement | null {
	const filteredList = useMemo(() => {
		if (!groupFilter) {
			return items;
		}
		return items.filter((key) => includes(key, groupFilter));
	}, [items, groupFilter]);

	if (filteredList.length === 0) {
		return null;
	}

	return (
		<presets.dropdown.DropdownContentFrame width={width} maxHeight="400px">
			<presets.dropdown.SingleChoiceList
				batchSize={50}
				items={filteredList.map((key) => ({
					id: key,
					label: key,
					isSelected: key === selectedId,
				}))}
				onSelect={onClick}
			/>
		</presets.dropdown.DropdownContentFrame>
	);
}

interface LoadingDropDownProps {
	label: string;
}

export function LoadingDropDown({ label }: LoadingDropDownProps): ReactElement {
	return (
		<Stack size="xSmall">
			<Text variant="smallStrong" color="neutral600">
				{label}
			</Text>
			<Skeletons height={39} widths={['100%']} />
		</Stack>
	);
}
