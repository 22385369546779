/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { presets } from '@steadybit/ui-components-lib';
import { EnvironmentSummaryVO } from 'ui-api';
import { useFormikContext } from 'formik';
import { Stack, Text } from 'components';
import { ReactElement } from 'react';

import { UseTemplateFormData } from './UseTemplateForm';

interface EnvironmentSelectionProps {
	selectedEnvironment: EnvironmentSummaryVO | undefined;
	environments: EnvironmentSummaryVO[];
}

export default function EnvironmentSelection({
	selectedEnvironment,
	environments,
}: EnvironmentSelectionProps): ReactElement {
	const formik = useFormikContext<UseTemplateFormData>();

	return (
		<Stack size="xSmall" sx={{ borderRadius: '4px' }}>
			<Text variant="mediumStrong" color="neutral800">
				In which environment will you run the experiment?
			</Text>
			<Text variant="medium" color="neutral600">
				The environment determines which targets are available for the experiment. Any environment variables used in the
				experiment will resolve to the values defined in the chosen environment.
			</Text>

			<presets.dropdown.SingleChoiceButton
				items={environments.map((env) => ({
					id: env.id,
					label: env.name,
					icon: env.global ? 'environment-global' : 'environment',
				}))}
				withLeftIcon={selectedEnvironment?.global ? 'environment-global' : 'environment'}
				selectedId={selectedEnvironment?.id}
				maxContentHeight="420px"
				withKeyboardNavigation
				onSelect={(id) => {
					formik.setFieldValue('environmentId', id);
					formik.setFieldValue('variableValuesMap', new Map());
				}}
				style={{ width: '400px' }}
			>
				{selectedEnvironment?.name || 'Select Environment'}
			</presets.dropdown.SingleChoiceButton>
		</Stack>
	);
}
